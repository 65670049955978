import * as pinnedTypes from './pinned.types';

const initialState = {
  isLoading: true,
  content: []
};

export default function pinnedReducer(state = initialState, action) {
  switch (action.type) {
    case pinnedTypes.FETCH_PINNED_START:
      return {
        ...state,
        isLoading: true
      };
    case pinnedTypes.FETCH_PINNED_SUCCESS:
      return {
        ...state,
        content: action.payload,
        isLoading: false
      };
    case pinnedTypes.FETCH_PINNED_FAIL:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}

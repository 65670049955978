import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export default class Service {
  constructor(url, usingToken = false, usertype = 'user') {
    return (async () => {
      this.url = process.env.REACT_APP_HOST + '/' + url;
      this.config = {
        timeout: 30000,
        cancelToken: source.token,
        headers: {
          usertype: usertype
        }
      };
      if (usingToken) {
        const token = localStorage.getItem('token');
        if (!token) return this;
        const expirationDate = localStorage.getItem('expirationDate');
        if (new Date().getTime() < new Date(expirationDate).getTime()) {
          this.config.headers.Authorization = 'Bearer ' + token;
        } else {
          console.log('ServiceServiceService refresh');
          try {
            const refreshToken = localStorage.getItem('refreshToken');
            const userId = localStorage.getItem('userId');
            const response = await axios.post(
              process.env.REACT_APP_HOST + '/refresh-tokens',
              {
                id: userId,
                refreshToken: refreshToken
              },
              this.config
            );
            this.config.headers.Authorization =
              'Bearer ' + response.data.accessToken;
            const tokenDecode = jwtDecode(response.data.accessToken);
            const expirationDate = new Date(tokenDecode.exp * 1000);
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('expirationDate', expirationDate);
          } catch (error) {
            localStorage.clear();
            window.location.reload();
            console.log(error);
          }
        }
      }
      return this; // when done
    })();
  }

  generateErrorMessage(error) {
    if (error.response && error.response.status) {
      console.log('React Log Error service status', error.response.status);
      return {
        error: error.response.data.message,
        code: error.response.status
      };
    } else {
      // kalo gada statusnya gada koneksi
      return { error: 'no connection', response: error.response };
    }
  }

  generateDefaultResponse(response) {
    if (response.error) {
      return response;
    } else {
      return response.data;
    }
  }

  paramGenerator(params) {
    let tempParams = [];
    let queryParams = '';
    let querySearch = params.querySearch || '';
    let querySort = params.querySort || '';
    delete params.querySort;
    delete params.querySearch;
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (Array.isArray(params[key])) {
          for (const key2 in params[key]) {
            tempParams.push(`${key}[${key2}]=${params[key][key2]}`);
          }
        } else {
          const element = params[key];
          tempParams.push(key + '=' + element);
        }
      }
    }
    if (tempParams.length) {
      tempParams = tempParams.join('&');
      queryParams = '?' + tempParams;
      if (querySearch !== '') {
        queryParams += '&' + querySearch;
      }
      if (querySort !== '') {
        queryParams += '&' + querySort;
      }
    }
    return queryParams;
  }

  async get(id, params) {
    //gunakan ini jika menggunakan params
    try {
      if (params)
        await axios.get(
          this.url + '/' + id + this.paramGenerator(params),
          this.config
        );
      return await axios.get(this.url + '/' + id, this.config);
    } catch (error) {
      return this.generateErrorMessage(error);
    }
  }

  async get(id) {
    try {
      return await axios.get(this.url + '/' + id, this.config);
    } catch (error) {
      return this.generateErrorMessage(error);
    }
  }

  async find(params) {
    try {
      return await axios.get(
        this.url + this.paramGenerator(params),
        this.config
      );
    } catch (error) {
      return this.generateErrorMessage(error);
    }
  }

  async create(data) {
    try {
      return await axios.post(this.url, data, this.config);
    } catch (error) {
      return this.generateErrorMessage(error);
    }
  }

  async put(data) {
    try {
      return await axios.put(this.url + '/' + data.id, data, this.config);
    } catch (error) {
      return this.generateErrorMessage(error);
    }
  }

  async patch(data) {
    try {
      return await axios.patch(this.url + '/' + data.id, data, this.config);
    } catch (error) {
      return this.generateErrorMessage(error);
    }
  }

  async delete(id, customUrl, params) {
    try {
      const url = customUrl ? customUrl : this.url + '/' + id;
      console.log(this.config);
      return await axios.delete(url, { ...this.config, params });
    } catch (error) {
      return this.generateErrorMessage(error);
    }
  }
}

import { put, call } from 'redux-saga/effects';
import * as actions from '../../reducers/user/user.actions';
import uploadService from '../../../services/Upload';
import userService from '../../../services/Users';

/**
 * GET USER
 */
export function* getUserSaga(action) {
  yield put(actions.getUserStart());

  const service = yield new userService();
  const user = yield service.get(action.id);
  if (user.error) {
    yield put(actions.getUserFail(user.error));
    return;
  }

  yield put(actions.getUserSuccess(user));
}

/**
 * UPDATE USER
 */
export function* updateUserSaga(action) {
  yield put(actions.updateUserStart());
  // console.log(action);
  // Img Upload
  if (action.imgUpload) {
    const upService = yield new uploadService();
    const upload = yield upService.upload(action.imgUpload);
    if (upload.error) {
      yield put(actions.uploadFail(upload.error));
      return;
    }
    action.userData.image = upload.data.url;
  }

  // Add User
  const service = yield new userService();
  const user = yield service.patch(action.userData);

  if (user.error) {
    yield put(actions.updateUserFail(user.error));
    return;
  }

  yield put(actions.updateUserSuccess(user));
  yield call([localStorage, 'removeItem'], 'strategy');
  yield call([localStorage, 'removeItem'], 'action_client');
  yield localStorage.setItem('hasPassword', true);
  if (action.history) yield action.history;
  else window.location.reload();
}

import { takeEvery } from 'redux-saga/effects';
import * as userCategory from '../../reducers/user-category/user-category.types';
import { userTypes, physicalActivities } from './user-category';

export const usersCategorySagas = {
  watchGetUserTypes: takeEvery(userCategory.USER_TYPES_REQUEST, userTypes),
  watchGetUserPhysicalActivities: takeEvery(
    userCategory.PHYSICAL_ACTIVITIES_REQUEST,
    physicalActivities
  )
};

export const GET_SYNC_ELVASENSE = 'GET_SYNC_ELVASENSE';
export const GET_SYNC_ELVASENSE_START = 'GET_SYNC_ELVASENSE_START';
export const GET_SYNC_ELVASENSE_SUCCESS = 'GET_SYNC_ELVASENSE_SUCCESS';
export const GET_SYNC_ELVASENSE_FAIL = 'GET_SYNC_ELVASENSE_FAIL';

export const UPDATE_SYNC_ELVASENSE = 'UPDATE_SYNC_ELVASENSE';
export const UPDATE_SYNC_ELVASENSE_START = 'UPDATE_SYNC_ELVASENSE_START';
export const UPDATE_SYNC_ELVASENSE_SUCCESS = 'UPDATE_SYNC_ELVASENSE_SUCCESS';
export const UPDATE_SYNC_ELVASENSE_FAIL = 'UPDATE_SYNC_ELVASENSE_FAIL';

export const UPDATE_SYNC_ELVASENSE_EMAIL_PASSWORD =
  'UPDATE_SYNC_ELVASENSE_EMAIL_PASSWORD';
export const UPDATE_SYNC_ELVASENSE_START_EMAIL_PASSWORD =
  'UPDATE_SYNC_ELVASENSE_START_EMAIL_PASSWORD';
export const UPDATE_SYNC_ELVASENSE_SUCCESS_EMAIL_PASSWORD =
  'UPDATE_SYNC_ELVASENSE_SUCCESS_EMAIL_PASSWORD';
export const UPDATE_SYNC_ELVASENSE_FAIL_EMAIL_PASSWORD =
  'UPDATE_SYNC_ELVASENSE_FAIL_EMAIL_PASSWORD';

export const POST_SYNC_ELVASENSE = 'POST_SYNC_ELVASENSE';
export const POST_SYNC_ELVASENSE_START = 'POST_SYNC_ELVASENSE_START';
export const POST_SYNC_ELVASENSE_SUCCESS = 'POST_SYNC_ELVASENSE_SUCCESS';
export const POST_SYNC_ELVASENSE_FAIL = 'POST_SYNC_ELVASENSE_FAIL';

export const ELVASENSE_DISMISS_ERROR = 'ELVASENSE_DISMISS_ERROR';

import * as medicalRecordActionTypes from '../../reducers/medical-record/medical-record.types';
import { takeEvery } from 'redux-saga/effects';
import {
  getGlucoseLatestData,
  medicalRecordLatestData,
  getHBA1CLatestData,
  getWaterIntakeLatestData,
  getActivityLatestData
} from './medical-record';

export const medicalRecordSagas = {
  medicalRecordLatestData: takeEvery(
    medicalRecordActionTypes.GET_LATEST_DATA_MEDICAL_RECORD_REQUEST,
    medicalRecordLatestData
  ),
  getGlucoseLatestData: takeEvery(
    medicalRecordActionTypes.GET_LATEST_GLUCOSE_REQUEST,
    getGlucoseLatestData
  ),
  getHBA1CLatestData: takeEvery(
    medicalRecordActionTypes.GET_LATEST_HBA1C_REQUEST,
    getHBA1CLatestData
  ),
  getWaterIntakeLatestData: takeEvery(
    medicalRecordActionTypes.GET_LATEST_WATER_INTAKE_REQUEST,
    getWaterIntakeLatestData
  ),
  getActivityLatestData: takeEvery(
    medicalRecordActionTypes.GET_LATEST_ACTIVITY_REQUEST,
    getActivityLatestData
  )
};

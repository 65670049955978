import React from 'react';
import { Spinner } from 'reactstrap';

import './Spinner.css';

const spinner = () => (
  <div className="overlay">
    {/* <div className="spinner"></div>   */}
    <div className="spinner-klikdiabetes">
      <img src={require('../../../assets/img/brand/logo-small.jpg')} />
      <div className="d-flex justify-content-center">
        <Spinner type="grow" color="primary" />
      </div>
    </div>
  </div>
);

export default spinner;

import Service from './Service';

export default class GreetingService extends Service {
  constructor() {
    super('greeting', true);
  }

  async fetchAll() {
    const response = await this.find({
      $limit: -1
    });
    response.data = response.data?.map(
      ({ title, greetingType, greetingCondition, description }) => ({
        title,
        greetingType,
        greetingCondition,
        description
      })
    );

    return response;
  }
}

import * as infoTypes from './info.types';

const initialState = {
  isLoading: true,
  content: ''
};

export default function infoReducer(state = initialState, action) {
  switch (action.type) {
    case infoTypes.FETCH_INFORMATION_START:
      return {
        ...state,
        isLoading: true
      };
    case infoTypes.FETCH_INFORMATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    case infoTypes.FETCH_INFORMATION_FAIL:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    default:
      return state;
  }
}

import { put, apply } from 'redux-saga/effects';
import * as infoActions from '../../reducers/page-information/info.actions';
import PageInformationService from '../../../services/PageInformation';

export function* fetch({ page }) {
  yield put(infoActions.fetch.start());
  const service = yield new PageInformationService();
  const res = yield apply(service, service.fetch, [page]);
  if (res.data) {
    yield put(infoActions.fetch.success(res.data[0]));
  } else {
    yield put(infoActions.fetch.fail(res.data));
  }
}

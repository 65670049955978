import * as authTypes from './auth.types';

export const authStart = () => {
  return {
    type: authTypes.AUTH_START
  };
};

export const authSuccess = ({
  token,
  refreshToken,
  expirationDate,
  userId,
  email,
  phone,
  userName,
  isVerified,
  userType,
  hasPassword,
  user
}) => {
  return {
    type: authTypes.AUTH_SUCCESS,
    token,
    refreshToken,
    expirationDate,
    userId,
    email,
    phone,
    userName,
    isVerified,
    userType,
    hasPassword,
    user
  };
};

export const authFail = error => {
  return {
    type: authTypes.AUTH_FAIL,
    error: error
  };
};

export const authDismissError = error => {
  return {
    type: authTypes.AUTH_DISMISS_ERROR
  };
};

export const logout = history => {
  return {
    type: authTypes.AUTH_INITIATE_LOGOUT,
    history: history
  };
};

export const logoutSucceed = () => {
  return {
    type: authTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return {
    type: authTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expirationTime
  };
};

export const auth = (email, password, isSignup) => {
  return {
    type: authTypes.AUTH_USER,
    email: email,
    password: password
  };
};

export const authSocMed = ({
  fullName,
  email,
  password,
  image,
  socMed,
  access_token,
  action_client
}) => {
  return {
    type: authTypes.AUTH_SOCMED,
    fullName,
    email,
    password,
    image,
    socMed,
    access_token,
    action_client
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: authTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return {
    type: authTypes.AUTH_CHECK_STATE
  };
};

export const registerUser = userData => ({
  type: authTypes.AUTH_REGISTER,
  userData
});
export const registerUserStart = () => ({
  type: authTypes.AUTH_REGISTER_START
});
export const registerUserSuccess = user => ({
  type: authTypes.AUTH_REGISTER_SUCCESS,
  user
});
export const registerUserFail = error => ({
  type: authTypes.AUTH_REGISTER_FAIL,
  error
});

export const removeLocalStorage = userData => ({
  type: authTypes.AUTH_REMOVE_LOCAL_STORAGE,
  userData
});
export const removeLocalStorageStart = () => ({
  type: authTypes.AUTH_REMOVE_LOCAL_STORAGE_START
});
export const removeLocalStorageSuccess = user => ({
  type: authTypes.AUTH_REMOVE_LOCAL_STORAGE_SUCCESS,
  user
});
export const removeLocalStorageFail = error => ({
  type: authTypes.AUTH_REMOVE_LOCAL_STORAGE_FAIL,
  error
});

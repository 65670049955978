import Service from './Service';
import moment from 'moment';

export default class NotificationService extends Service {
  constructor() {
    super('notification-history', true);
  }

  // Post data to api
  async fetchHistory(userId) {
    const result = await this.find({
      userId,
      '$sort[date]': -1,
      $limit: 10,
      status: 'read',
      status: 'unread'
    });
    let datas = result?.data?.data || [];
    let dataToInsert = [];
    for (let i = 0; i < datas.length; i++) {
      dataToInsert.push({
        _id: datas[i]._id ? datas[i]._id : undefined,
        id: datas[i].id,
        userId: datas[i].userId,
        date: datas[i].date,
        type: datas[i].type,
        title: datas[i].title,
        description: datas[i].description,
        image: datas[i].image,
        status: datas[i].status,
        webUrl: datas[i].webUrl,
        appUrl: datas[i].appUrl,
        bannerId: datas[i].bannerId,
        linkId: datas[i].linkId,
        created: datas[i].created,
        creator: datas[i].creator,
        updated: datas[i].updated,
        updater: datas[i].updater,
        deleted: datas[i].deleted,
        deleter: datas[i].deleter
      });
    }
    return dataToInsert;
  }

  async fetchHistoryUnread(userId) {
    const result = await this.find({
      userId,
      '$sort[date]': -1,
      $limit: 10,
      status: 'read',
      status: 'unread'
    });
    let datas = result?.data?.data || [];
    let unread = 0;
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].status === 'unread') {
        unread += 1;
      }
    }
    return await unread;
  }

  // Post data to api
  async postData(data) {
    let response = await this.create(data);
    return response;
  }

  // Patch data to api
  async patchData(id, data) {
    const response = await this.patch({ id, ...data });
    return response;
  }

  // Delete data to api
  async delData(id, customUrl) {
    const res = await this.delete(id, customUrl);
    return res.data;
  }
}

import { takeEvery } from 'redux-saga/effects';
import * as types from '../../reducers/food-recipe/food-recipe.types';
import {
  fetchFoodsRecipe,
  fetchFoodsRecipeDetail,
  createFoodsRecipe
} from './food-recipe';

export const foodRecipeSagas = {
  watchFetchFoodsRecipe: takeEvery(types.FETCH_FOODS_RECIPE, fetchFoodsRecipe),
  watchFetchFoodsReceipeDetail: takeEvery(
    types.FETCH_FOODS_RECIPE_DETAIL,
    fetchFoodsRecipeDetail
  ),
  watchCeateFoodReceipt: takeEvery(types.CREATE_FOODS_RECIPE, createFoodsRecipe)
};

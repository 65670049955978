import Service from './Service';
import {
  getIndex,
  parseToChartCalories,
  toFilteredData
} from '../utils/chartFilterUtils';
export default class Users extends Service {
  constructor() {
    super('chart-food-history', true);
  }

  async fetchChart(params) {
    //mungkin dipindah saja nantinya
    const response = await this.find(params);
    return response;
  }

  async getDataFilter(data, filter) {
    let datas = [];
    datas = toFilteredData(data, filter);
    const { currentIndex, zeroIndex } = getIndex(data, filter);
    const dataChart = {
      result: parseToChartCalories(datas, filter),
      currentIndex,
      zeroIndex
    };
    return { success: true, dataChart };
  }
}

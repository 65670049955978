import Service from './Service';

export default class UserCategory extends Service {
  constructor() {
    super('user-types', false);
  }

  async getUserTypes() {
    this.url = `${process.env.REACT_APP_HOST}/user-types`;
    return await this.find({
      $limit: -1
    });
  }

  async getPhysicalActivities() {
    this.url = `${process.env.REACT_APP_HOST}/physical-activity`;
    return await this.find({
      $limit: 1000
    });
  }
}

import * as greetingActionTypes from './greeting.types';

export const getGreetingContents = () => ({
  call: () => ({ type: greetingActionTypes.GREETING_CONTENTS_REQUEST }),
  start: () => ({
    type: greetingActionTypes.GREETING_CONTENTS_REQUEST_START
  }),
  success: payload => ({
    type: greetingActionTypes.GREETING_CONTENTS_REQUEST_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: greetingActionTypes.GREETING_CONTENTS_REQUEST_FAIL,
    payload
  })
});
export const setGreetingLastAction = payload => ({
  type: greetingActionTypes.GREETING_SET_LAST_ACTION,
  payload
});

import * as medicalRecordActionTypes from './medical-record.types';

export const getLatestRequest = userId => ({
  type: medicalRecordActionTypes.GET_LATEST_DATA_MEDICAL_RECORD_REQUEST,
  userId
});

export const getLatestGlucose = userId => ({
  call: userId => ({
    type: medicalRecordActionTypes.GET_LATEST_GLUCOSE_REQUEST,
    userId
  }),
  start: () => ({
    type: medicalRecordActionTypes.GET_LATEST_GLUCOSE_REQUEST_START
  }),
  success: payload => ({
    type: medicalRecordActionTypes.GET_LATEST_GLUCOSE_REQUEST_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: medicalRecordActionTypes.GET_LATEST_GLUCOSE_REQUEST_FAIL,
    payload
  }),
  userId
});

export const getLatestHBA1C = userId => ({
  call: userId => ({
    type: medicalRecordActionTypes.GET_LATEST_HBA1C_REQUEST,
    userId
  }),
  start: () => ({
    type: medicalRecordActionTypes.GET_LATEST_HBA1C_REQUEST_START
  }),
  success: payload => ({
    type: medicalRecordActionTypes.GET_LATEST_HBA1C_REQUEST_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: medicalRecordActionTypes.GET_LATEST_HBA1C_REQUEST_FAIL,
    payload
  }),
  userId
});

export const getLatestWaterIntake = userId => ({
  call: userId => ({
    type: medicalRecordActionTypes.GET_LATEST_WATER_INTAKE_REQUEST,
    userId
  }),
  start: () => ({
    type: medicalRecordActionTypes.GET_LATEST_WATER_INTAKE_REQUEST_START
  }),
  success: payload => ({
    type: medicalRecordActionTypes.GET_LATEST_WATER_INTAKE_REQUEST_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: medicalRecordActionTypes.GET_LATEST_WATER_INTAKE_REQUEST_FAIL,
    payload
  }),
  userId
});

export const getLatestActivity = userId => ({
  call: userId => ({
    type: medicalRecordActionTypes.GET_LATEST_ACTIVITY_REQUEST,
    userId
  }),
  start: () => ({
    type: medicalRecordActionTypes.GET_LATEST_ACTIVITY_REQUEST_START
  }),
  success: payload => ({
    type: medicalRecordActionTypes.GET_LATEST_ACTIVITY_REQUEST_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: medicalRecordActionTypes.GET_LATEST_ACTIVITY_REQUEST_FAIL,
    payload
  })
});

export const updateLatestActivity = () => ({
  call: data => ({
    type: medicalRecordActionTypes.UPDATE_LATEST_ACTIVITY_REQUEST,
    data
  }),
  start: () => ({
    type: medicalRecordActionTypes.UPDATE_LATEST_ACTIVITY_REQUEST_START
  }),
  success: payload => ({
    type: medicalRecordActionTypes.UPDATE_LATEST_ACTIVITY_REQUEST_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: medicalRecordActionTypes.UPDATE_LATEST_ACTIVITY_REQUEST_FAIL,
    payload
  })
});

export const setMedicalLastAction = payload => ({
  type: medicalRecordActionTypes.SET_LAST_ACTION,
  payload
});

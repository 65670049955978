import * as greetingActionTypes from './greeting.types';

const initialState = {
  contents: [],
  errorContents: null,
  isLoadingContent: false,
  lastActionType: null,
  lastActionInputValue: null,
  lastActionCondition: null,
  lastActionIndex: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case greetingActionTypes.GREETING_CONTENTS_REQUEST_START:
      return {
        ...state,
        isLoadingContent: true,
        errorContents: null
      };
    case greetingActionTypes.GREETING_CONTENTS_REQUEST_SUCCESS:
      return {
        ...state,
        contents: action.payload,
        isLoadingContent: false
      };
    case greetingActionTypes.GREETING_CONTENTS_REQUEST_FAIL:
      return {
        ...state,
        errorContents: action.payload,
        isLoadingContent: false
      };
    case greetingActionTypes.GREETING_SET_LAST_ACTION:
      return {
        ...state,
        lastActionType: action.payload?.type || null,
        lastActionInputValue: action.payload?.value || null,
        lastActionCondition: action.payload?.condition || null,
        lastActionIndex: action.payload?.index || state.lastActionIndex
      };
    default:
      return state;
  }
}

import * as transisiTypes from './transisi.types';

/**
 * UPDATE USER
 */
export const updateTransisi = userData => ({
  type: transisiTypes.TRANSISI_USER,
  userData
});
export const updateTransisiStart = () => ({
  type: transisiTypes.TRANSISI_USER_START
});
export const updateTransisiSuccess = user => ({
  type: transisiTypes.TRANSISI_USER_SUCCESS,
  user
});
export const updateTransisiFail = error => ({
  type: transisiTypes.TRANSISI_USER_FAIL,
  error
});

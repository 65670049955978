import { put } from 'redux-saga/effects';
import * as actions from 'store/reducers/transisi/transisi.actions';
import userService from 'services/Users';

/**
 * UPDATE USER
 */
export function* updateTransisiSaga(action) {
  yield put(actions.updateTransisiStart());

  const service = yield new userService();
  const res = yield service.patch(action.userData);
  if (res.error) {
    yield put(actions.updateTransisiFail(res.error));
    return;
  }

  yield put(actions.updateTransisiSuccess(res));
  yield localStorage.setItem('userType', res.userType);
  window.location.replace('/home');
}

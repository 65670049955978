import Service from './Service';
import formatDate from '../utils/formatDate';
import { utcOffset } from '../utils/dateUtils';
import {
  getIndex,
  parseToChartSeriesObject,
  toFilteredData
} from '../utils/chartFilterUtils';
import moment from 'moment';

export default class GlucoseService extends Service {
  constructor() {
    super('glucose', true);
  }

  // mengikuti mobile cuman untuk SQLLitenya dihilangkan
  async fetchLatest(userId) {
    const utcString = formatDate(new Date());
    const result = await this.find({
      userId,
      'date[$lte]': utcString,
      '$sort[date]': -1,
      '$sort[created]': -1
    });
    console.log('RESULT >>>>>>', result);
    if (result.data && result.data.data.length > 0) {
      const data = result.data.data[0];
      console.log('hasil fetch api', data);
      return {
        value: data.value,
        date: data.date,
        updated: data.updated,
        type: data.type,
        elvasenseSynchronize: data.elvasenseSynchronize
      };
    } else {
      return { value: null, date: null, updated: null, type: null };
    }
  }

  // Fetch today data
  async fetchTodayData(userId, date) {
    let response = await this.find({
      date: date,
      userId: userId,
      $limit: -1
    });
    return response.data;
  }

  // Fetch filter data
  async fetchFilterData(userId, date, typeFilter, type) {
    const params = {
      userId,
      date,
      typeFilter,
      offsetDate: utcOffset,
      $limit: -1,
      type: [type]
    };
    let response = await this.find(params);
    return response.data;
  }

  // Fetch data detail
  async fetchSingleData(id) {
    let response = await this.get(id);
    return response.data;
  }

  // // Post data to api
  async postData(data) {
    let res = await this.create(data);
    return res;
  }

  // Patch data to api
  async updateData(id, params) {
    // console.log(params);
    delete params?.isVerified;
    const res = await this.patch({ id, ...params });
    return res;
  }

  // Delete data to api
  async deleteData({ id, customUrl }) {
    const res = await this.delete(id, customUrl);
    return res;
  }

  // get data history
  async getDataHistory(userId, date) {
    let res = await this.fetchTodayData(userId, date);
    const newDate = moment(date)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.000');
    const newDateString = newDate + 'Z';
    if (res) {
      return res;
    } else {
      return { value: null, date: null, updated: null, type: null };
    }
  }

  async getFilter(userId, date, filter, type) {
    let datas = [];
    const res = await this.fetchFilterData(userId, date, filter, type);
    if (res && res[type]?.length) {
      console.log('HASIL FETCH', res[type]);
      datas = toFilteredData(res[type], filter);
      const { currentIndex, zeroIndex } = getIndex(res[type], filter);
      return {
        result: parseToChartSeriesObject(datas, filter),
        currentIndex,
        zeroIndex
      };
    } else {
      const { currentIndex, zeroIndex } = getIndex([], filter);
      return {
        result: parseToChartSeriesObject([], filter),
        currentIndex,
        zeroIndex
      };
    }
  }

  // Patch data
  async editNote(id, note) {
    let data = {
      note
    };
    return await this.updateData(id, data).then(async result => {
      if (result.success) {
        return result;
      } else {
        return result;
      }
    });
  }
}

import Service from './Service';

export default class MedicalReminderService extends Service {
  constructor() {
    super('medicine-reminder', true);
  }

  async fetchAll(params) {
    const response = await this.find(params);
    return response;
  }

  async postData(data) {
    const response = await this.create(data);
    return response;
  }

  async patchData(id, data) {
    const response = await this.patch(
      {
        id: id,
        ...data
      },
      true,
      null,
      false
    );
    return response;
  }

  async deleteData(id, params) {
    const response = await this.delete(id, null, params);
    return response;
  }
}

import Service from './Service';

export default class authManagement extends Service {
  constructor() {
    super('authmanagement', true);
  }

  async create(data) {
    const response = await super.create(data);
    return super.generateDefaultResponse(response);
  }
}

import { combineReducers } from 'redux';
import responsive from './responsive/responsive.reducer';
import authManagement from './authManagement/authManagement.reducer';
import auth from './auth/auth.reducer';
import shared from './shared/shared.reducer';
import users from './user/user.reducer';
import transisi from './transisi/transisi.reducer';
import elvasense from './elvasense/elvasense.reducer';
import banner from './banner/banner.reducer';
import greeting from './greeting/greeting.reducer';
import article from './article/article.reducer';
import articleCategory from './article-category/article-category.reducer';
import food from './food/food.reducer';
import foodRecipe from './food-recipe/food-recipe.reducer';
import foodHistory from './food-history/food-history.reducer';
import question from './question/question.reducer';
import medicalRecord from './medical-record/medical-record.reducer';
import medicalReminder from './medical-reminder/medical-reminder.reducer';
import goals from './goals/goals.reducer';
import pinnedContent from './pinned-content/pinned.reducer';
import klikDokter from './klik-dokter/klik-dokter.reducer';
import pageInformation from './page-information/info.reducer';
import userCategory from './user-category/user-category.reducer';
import notification from './notification/notification.reducer';

const root = combineReducers({
  responsive,
  authManagement,
  auth,
  shared,
  users,
  transisi,
  elvasense,
  banner,
  greeting,
  article,
  food,
  foodRecipe,
  question,
  medicalRecord,
  medicalReminder,
  goals,
  foodHistory,
  pinnedContent,
  articleCategory,
  klikDokter,
  pageInformation,
  userCategory,
  notification
});

export default root;

import * as authManagementTypes from './authManagement.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  success: null,
  error: null,
  resetPasswordState: {}
};

/**
 * RESET PASSWORD
 */
const resetPasswordStart = (state, action) =>
  updateObject(state, { loading: true });
const resetPasswordSuccess = (state, action) =>
  updateObject(state, {
    error: null,
    loading: false
  });
const resetPasswordFail = (state, action) =>
  updateObject(state, {
    error: action.error,
    loading: false
  });

/**
 * CHANGE PASSWORD
 */
const changePasswordStart = (state, action) =>
  updateObject(state, { loading: true });
const changePasswordSuccess = (state, action) =>
  updateObject(state, {
    success: 'Password has been succesfully updated',
    error: null,
    loading: false,
    user: action.user
  });
const changePasswordFail = (state, action) =>
  updateObject(state, {
    error: action.error,
    loading: false
  });

/**
 * DISMISS MESSAGE
 */
const dismissError = (state, action) =>
  updateObject(state, {
    success: null,
    error: null,
    loading: false
  });

const verificationEmailStart = (state, action) =>
  updateObject(state, { loading: true });
const verificationEmailSuccess = (state, action) =>
  updateObject(state, {
    error: null,
    loading: false
  });
const verificationEmailFail = (state, action) =>
  updateObject(state, {
    error: action.error,
    loading: false
  });

//SWITCH REDUCER
const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authManagementTypes.RESET_PASSWORD:
      return resetPasswordStart(state, action);
    case authManagementTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case authManagementTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case authManagementTypes.DISMISS_ERROR:
      return dismissError(state, action);

    // CHANGE PASSWORD PROFILE
    case authManagementTypes.CHANGE_PASSWORD_START:
      return changePasswordStart(state, action);
    case authManagementTypes.CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action);
    case authManagementTypes.CHANGE_PASSWORD_FAIL:
      return changePasswordFail(state, action);

    case authManagementTypes.VERIFICATION_EMAIL_START:
      return verificationEmailStart(state, action);
    case authManagementTypes.VERIFICATION_EMAIL_SUCCESS:
      return verificationEmailSuccess(state, action);
    case authManagementTypes.VERIFICATION_EMAIL_FAIL:
      return verificationEmailFail(state, action);

    default:
      return state;
  }
};

export default userReducer;

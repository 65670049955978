import * as actionTypes from './medical-reminder.types';

const initialState = {
  isLoading: false,
  isLoadingDetail: false,
  isLoadingRemove: false,
  isLoadingConfirm: false,
  lists: [],
  listsOfTaken: [],
  listsByFilter: {},
  details: {},
  detailChart: [],
  selectedMedicineScheduleId: null,
  error: null
};

export default function reminderReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_MEDICAL_REMINDER_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.CREATE_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.CREATE_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.UPDATE_MEDICAL_REMINDER_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.UPDATE_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.UPDATE_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.REMOVE_MEDICAL_REMINDER_START:
      return {
        ...state,
        isLoadingRemove: true
      };
    case actionTypes.REMOVE_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoadingRemove: false
      };
    case actionTypes.REMOVE_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoadingRemove: false,
        error: action.payload
      };
    case actionTypes.FETCH_BY_FILTER_MEDICAL_REMINDER_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.FETCH_BY_FILTER_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listsByFilter: {
          ...state.listsByFilter,
          [action.date]: action.data
        }
      };
    case actionTypes.FETCH_BY_FILTER_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.FIND_BY_FILTER_MEDICAL_REMINDER_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.FIND_BY_FILTER_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listsByFilter: {
          ...state.listsByFilter,
          [action.date]: action.data
        }
      };
    case actionTypes.FIND_BY_FILTER_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.FETCH_DETAIL_MEDICAL_REMINDER_START:
      return {
        ...state,
        isLoadingDetail: true
      };
    case actionTypes.FETCH_DETAIL_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoadingDetail: false,
        details: {
          ...state.details,
          [action.id]: action.data
        }
      };
    case actionTypes.FETCH_DETAIL_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoadingDetail: false,
        error: action.payload
      };
    case actionTypes.FIND_DETAIL_MEDICAL_REMINDER_START:
      return {
        ...state,
        isLoadingDetail: true
      };
    case actionTypes.FIND_DETAIL_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoadingDetail: false,
        details: {
          ...state.details,
          [action.id]: action.data
        }
      };
    case actionTypes.FIND_DETAIL_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoadingDetail: false,
        error: action.payload
      };
    case actionTypes.FETCH_CHART_DETAIL_MEDICAL_REMINDER_START:
      return {
        ...state,
        isLoadingDetail: true
      };
    case actionTypes.FETCH_CHART_DETAIL_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoadingDetail: false,
        detailChart: action.data
      };
    case actionTypes.FETCH_CHART_DETAIL_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoadingDetail: false,
        error: action.payload
      };
    case actionTypes.CONFIRM_MEDICAL_REMINDER_START:
      return {
        ...state,
        isLoadingConfirm: true,
        selectedMedicineScheduleId: action.payload
      };
    case actionTypes.CONFIRM_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoadingConfirm: false,
        selectedMedicineScheduleId: null
      };
    case actionTypes.CONFIRM_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoadingConfirm: false,
        selectedMedicineScheduleId: null,
        error: action.payload
      };
    case actionTypes.SYNC_MEDICAL_REMINDER_TAKEN_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SYNC_MEDICAL_REMINDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lists: action.payload
      };
    case actionTypes.SYNC_MEDICAL_REMINDER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.SYNC_MEDICAL_TAKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listsOfTaken: action.payload
      };
    case actionTypes.SYNC_MEDICAL_TAKEN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export const ADD_BANNER = 'ADD_BANNER';
export const ADD_BANNER_START = 'ADD_BANNER_START';
export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS';
export const ADD_BANNER_FAIL = 'ADD_BANNER_FAIL';

export const DELETE_BANNER = 'DELETE_BANNER';
export const DELETE_BANNER_START = 'DELETE_BANNER_START';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_FAIL = 'DELETE_BANNER_FAIL';

export const UPDATE_BANNER = 'UPDATE_BANNER';
export const UPDATE_BANNER_START = 'UPDATE_BANNER_START';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAIL = 'UPDATE_BANNER_FAIL';

export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_START = 'GET_BANNER_START';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAIL = 'GET_BANNER_FAIL';

export const FETCH_BANNERS = 'FETCH_BANNERS';
export const FETCH_BANNERS_START = 'FETCH_BANNERS_START';
export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_BANNERS_FAIL = 'FETCH_BANNERS_FAIL';

// ====== UTILITY  ======= //
export const BANNER_DISMISS_ERROR = 'BANNER_DISMISS_ERROR';
export const BANNER_ADD_FILTER = 'BANNER_ADD_FILTER';
export const BANNER_CLEAR_FILTER = 'BANNER_CLEAR_FILTER';
export const BANNER_ADD_PAGINATION = 'BANNER_ADD_PAGINATION';
export const BANNER_CLEAR_PAGINATION = 'BANNER_CLEAR_PAGINATION';

import * as articleTypes from './article.types';

/**
 * FETCH ARTICLES
 */
export const fetchArticles = params => ({
  type: articleTypes.FETCH_ARTICLES,
  params
});
export const fetchArticlesStart = () => ({
  type: articleTypes.FETCH_ARTICLES_START
});
export const fetchArticlesSuccess = articles => ({
  type: articleTypes.FETCH_ARTICLES_SUCCESS,
  articles
});
export const fetchArticlesConcatSuccess = articles => ({
  type: articleTypes.FETCH_ARTICLES_CONCAT_SUCCESS,
  articles
});
export const fetchArticlesFail = error => ({
  type: articleTypes.FETCH_ARTICLES_FAIL,
  error
});

/**
 * GET ARTICLE
 */
export const getArticle = id => ({
  type: articleTypes.GET_ARTICLE,
  id
});
export const getArticleStart = () => ({
  type: articleTypes.GET_ARTICLE_START
});
export const getArticleSuccess = (id, article) => ({
  type: articleTypes.GET_ARTICLE_SUCCESS,
  id,
  article
});
export const getArticleFail = error => ({
  type: articleTypes.GET_ARTICLE_FAIL,
  error
});

export const addViewArticle = {
  call: id => ({ type: articleTypes.VIEW_ARTICLE, id }),
  start: () => ({
    type: articleTypes.VIEW_ARTICLE_START
  }),
  success: () => ({
    type: articleTypes.VIEW_ARTICLE_SUCCESS
  }),
  fail: () => ({
    type: articleTypes.VIEW_ARTICLE_FAIL
  })
};

/**
 * UTILS
 */
export const articleDismissError = () => ({
  type: articleTypes.ARTICLE_DISMISS_ERROR
});

import { put, select, apply } from 'redux-saga/effects';
import * as actions from '../../reducers/article/article.actions';
import articleService from '../../../services/Article';
import * as authSelector from '../../reducers/auth/auth.selector';
import PinnedContentService from '../../../services/PinnedContent';

/**
 * FETCH ARTICLES
 */
export function* fetchArticlesSaga(action) {
  yield put(actions.fetchArticlesStart());
  const user = yield select(authSelector.user);
  const tags = [
    user?.userTypes?.label === 'Diabetisi' ? 'Diabetes' : 'Non Diabetes',
    user?.gender === 'm' ? 'Male' : 'Female'
  ];
  const pinnedParams = {
    module: 'article',
    tags: String(tags)
  };
  const servicePin = yield new PinnedContentService();
  const resPin = yield apply(servicePin, servicePin.fetch, [pinnedParams]);
  let pinned = [];
  for (let i = 0; i < resPin.data?.data.length; i++) {
    if (resPin.data.data[i].title) {
      pinned.push(resPin.data.data[i].content_id);
    }
  }
  const service = yield new articleService();
  const articles = yield service.find(action.params);
  if (articles.error) {
    yield put(actions.fetchArticlesFail(articles.error));
  } else {
    let filtered = [];
    if (pinned.length) {
      filtered = {
        ...articles,
        data: articles.data.data.filter(content => !pinned.includes(content.id))
      };
    } else {
      filtered = articles;
    }
    if (action.params.$skip === 0) {
      yield put(actions.fetchArticlesSuccess(filtered));
    } else {
      yield put(actions.fetchArticlesConcatSuccess(filtered));
    }
  }
}

/**
 * GET ARTICLE
 */
export function* getArticleSaga(action) {
  yield put(actions.getArticleStart());
  const service = yield new articleService();
  const article = yield service.get(action.id, { isMobile: 1 });
  if (article.error) {
    yield put(actions.getArticleFail(article.error));
    return;
  }

  yield put(actions.getArticleSuccess(action.id, article));
}

/**
 * VIEW ARTICLE
 */
export function* addViewArticleSaga({ id }) {
  yield put(actions.addViewArticle.start());
  const userId = yield select(authSelector.idUser);
  const service = yield new articleService();
  const res = yield apply(service, service.viewArticle, [id, userId]);
  if (res.success) {
    yield put(actions.addViewArticle.success());
  } else {
    yield put(actions.addViewArticle.fail());
  }
}

export const FETCH_FOOD_HISTORY = 'FETCH_FOOD_HISTORY';
export const FETCH_FOOD_HISTORY_START = 'FETCH_FOOD_HISTORY_START';
export const FETCH_FOOD_HISTORY_SUCCESS = 'FETCH_FOOD_HISTORY_SUCCESS';
export const FETCH_FOOD_HISTORY_FAIL = 'FETCH_FOOD_HISTORY_FAIL';

export const FETCH_FOOD_HISTORY_DETAIL = 'FETCH_FOOD_HISTORY_DETAIL';
export const FETCH_FOOD_HISTORY_DETAIL_START =
  'FETCH_FOOD_HISTORY_DETAIL_START';
export const FETCH_FOOD_HISTORY_DETAIL_SUCCESS =
  'FETCH_FOOD_HISTORY_DETAIL_SUCCESS';
export const FETCH_FOOD_HISTORY_DETAIL_FAIL = 'FETCH_FOOD_HISTORY_DETAIL_FAIL';

export const FETCH_FOOD_HISTORY_CHART = 'FETCH_FOOD_HISTORY_CHART';
export const FETCH_FOOD_HISTORY_CHART_START = 'FETCH_FOOD_HISTORY_CHART_START';
export const FETCH_FOOD_HISTORY_CHART_SUCCESS =
  'FETCH_FOOD_HISTORY_CHART_SUCCESS';
export const FETCH_FOOD_HISTORY_CHART_FAIL = 'FETCH_FOOD_HISTORY_CHART_FAIL';

export const CREATE_FOOD_HISTORY = 'CREATE_FOOD_HISTORY';
export const CREATE_FOOD_HISTORY_START = 'CREATE_FOOD_HISTORY_START';
export const CREATE_FOOD_HISTORY_SUCCESS = 'CREATE_FOOD_HISTORY_SUCCESS';
export const CREATE_FOOD_HISTORY_FAIL = 'CREATE_FOOD_HISTORY_FAIL';

export const SET_GREETING_FOOD_HISTORY = 'SET_GREETING_FOOD_HISTORY';

export const UPDATE_FOOD_HISTORY = 'UPDATE_FOOD_HISTORY';
export const UPDATE_FOOD_HISTORY_START = 'UPDATE_FOOD_HISTORY_START';
export const UPDATE_FOOD_HISTORY_SUCCESS = 'UPDATE_FOOD_HISTORY_SUCCESS';
export const UPDATE_FOOD_HISTORY_FAIL = 'UPDATE_FOOD_HISTORY_FAIL';

export const REMOVE_FOOD_HISTORY = 'REMOVE_FOOD_HISTORY';
export const REMOVE_FOOD_HISTORY_START = 'REMOVE_FOOD_HISTORY_START';
export const REMOVE_FOOD_HISTORY_SUCCESS = 'REMOVE_FOOD_HISTORY_SUCCESS';
export const REMOVE_FOOD_HISTORY_FAIL = 'REMOVE_FOOD_HISTORY_FAIL';

export const SET_TARGET = 'SET_TARGET';
export const SET_TARGET_START = 'SET_TARGET_START';
export const SET_TARGET_SUCCESS = 'SET_TARGET_SUCCESS';
export const SET_TARGET_FAIL = 'SET_TARGET_FAIL';

export const RESET_FOOD_HISTORY = 'RESET_FOOD_HISTORY';

import Service from './Service';
export default class ArticlesCategory extends Service {
  constructor() {
    super('article-category', false);
  }

  async get(id) {
    const response = await super.get(id);
    return super.generateDefaultResponse(response);
  }
}

import { put } from 'redux-saga/effects';
import * as actions from '../../reducers/banner/banner.actions';
import bannerService from '../../../services/Banner';

/**
 * FETCH BANNERS
 */
export function* fetchBannersSaga(action) {
  yield put(actions.fetchBannersStart());
  const service = yield new bannerService();
  const banners = yield service.find(action.params);
  if (banners.error) {
    yield put(actions.fetchBannersFail(banners.error));
    return;
  }
  yield put(actions.fetchBannersSuccess(banners));
}

/**
 * GET BANNER
 */
export function* getBannerSaga(action) {
  yield put(actions.getBannerStart());
  const service = yield new bannerService();
  const banner = yield service.get(action.id);
  if (banner.error) {
    yield put(actions.getBannerFail(banner.error));
    return;
  }

  yield put(actions.getBannerSuccess(banner));
}

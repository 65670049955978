import * as foodHistoryTypes from './food-history.types';

export const fetchHistory = {
  call: ({ userId, date }) => ({
    type: foodHistoryTypes.FETCH_FOOD_HISTORY,
    date,
    userId
  }),
  start: () => ({ type: foodHistoryTypes.FETCH_FOOD_HISTORY_START }),
  success: payload => ({
    type: foodHistoryTypes.FETCH_FOOD_HISTORY_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: foodHistoryTypes.FETCH_FOOD_HISTORY_FAIL,
    payload
  })
};

export const setGreetingHistory = date => ({
  type: foodHistoryTypes.SET_GREETING_FOOD_HISTORY,
  date
});

export const fetchHistoryDetail = {
  call: id => ({ type: foodHistoryTypes.FETCH_FOOD_HISTORY_DETAIL, id }),
  start: () => ({ type: foodHistoryTypes.FETCH_FOOD_HISTORY_DETAIL_START }),
  success: (id, payload) => ({
    type: foodHistoryTypes.FETCH_FOOD_HISTORY_DETAIL_SUCCESS,
    id,
    payload
  }),
  fail: payload => ({
    type: foodHistoryTypes.FETCH_FOOD_HISTORY_DETAIL_FAIL,
    payload
  })
};

export const fetchHistoryChart = {
  call: (date, typeFilter, userId) => ({
    type: foodHistoryTypes.FETCH_FOOD_HISTORY_CHART,
    date,
    typeFilter,
    userId
  }),
  start: () => ({ type: foodHistoryTypes.FETCH_FOOD_HISTORY_CHART_START }),
  success: payload => ({
    type: foodHistoryTypes.FETCH_FOOD_HISTORY_CHART_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: foodHistoryTypes.FETCH_FOOD_HISTORY_CHART_FAIL,
    payload
  })
};

export const createHistory = {
  call: (data, userId) => ({
    type: foodHistoryTypes.CREATE_FOOD_HISTORY,
    data,
    userId
  }),
  start: () => ({ type: foodHistoryTypes.CREATE_FOOD_HISTORY_START }),
  success: ({ message }) => ({
    type: foodHistoryTypes.CREATE_FOOD_HISTORY_SUCCESS,
    message
  }),
  fail: ({ error }) => ({
    type: foodHistoryTypes.CREATE_FOOD_HISTORY_FAIL,
    error
  })
};

export const updateHistory = {
  call: (id, data, userId) => ({
    type: foodHistoryTypes.UPDATE_FOOD_HISTORY,
    id,
    data,
    userId
  }),
  start: () => ({ type: foodHistoryTypes.UPDATE_FOOD_HISTORY_START }),
  success: ({ message }) => ({
    type: foodHistoryTypes.UPDATE_FOOD_HISTORY_SUCCESS,
    message
  }),
  fail: payload => ({
    type: foodHistoryTypes.UPDATE_FOOD_HISTORY_FAIL,
    payload
  })
};

export const removeHistory = {
  call: (id, userId) => ({
    type: foodHistoryTypes.REMOVE_FOOD_HISTORY,
    id,
    userId
  }),
  start: () => ({ type: foodHistoryTypes.REMOVE_FOOD_HISTORY_START }),
  success: ({ message }) => ({
    type: foodHistoryTypes.REMOVE_FOOD_HISTORY_SUCCESS,
    message
  }),
  fail: payload => ({
    type: foodHistoryTypes.REMOVE_FOOD_HISTORY_FAIL,
    payload
  })
};

export const resetFoodHistory = () => ({
  type: foodHistoryTypes.RESET_FOOD_HISTORY
});

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const CREATE_NOTIFICATION_START = 'CREATE_NOTIFICATION_START';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAIL = 'CREATE_NOTIFICATION_FAIL';

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_START = 'UPDATE_NOTIFICATION_START';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL';

export const SYNC_NOTIFICATION = 'SYNC_NOTIFICATION';
export const SYNC_NOTIFICATION_START = 'SYNC_NOTIFICATION_START';
export const SYNC_NOTIFICATION_SUCCESS = 'SYNC_NOTIFICATION_SUCCESS';
export const SYNC_NOTIFICATION_FAIL = 'SYNC_NOTIFICATION_FAIL';

export const SYNC_UNREAD_NOTIFICATION = 'SYNC_UNREAD_NOTIFICATION';
export const SYNC_UNREAD_NOTIFICATION_START = 'SYNC_UNREAD_NOTIFICATION_START';
export const SYNC_UNREAD_NOTIFICATION_SUCCESS =
  'SYNC_UNREAD_NOTIFICATION_SUCCESS';
export const SYNC_UNREAD_NOTIFICATION_FAIL = 'SYNC_UNREAD_NOTIFICATION_FAIL';

export const UNREARD_NOTIFICATION = 'UNREARD_NOTIFICATION';
export const UNREARD_NOTIFICATION_START = 'UNREARD_NOTIFICATION_START';
export const UNREARD_NOTIFICATION_SUCCESS = 'UNREARD_NOTIFICATION_SUCCESS';
export const UNREARD_NOTIFICATION_FAIL = 'UNREARD_NOTIFICATION_FAIL';

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_NOTIFICATION_START = 'REMOVE_NOTIFICATION_START';
export const REMOVE_NOTIFICATION_SUCCESS = 'REMOVE_NOTIFICATION_SUCCESS';
export const REMOVE_NOTIFICATION_FAIL = 'REMOVE_NOTIFICATION_FAIL';

import Service from './Service';
import {
  getIndex,
  parseToChartCalories,
  toFilteredData
} from '../utils/chartFilterUtils';
export default class Users extends Service {
  constructor() {
    super('food-history', true);
  }

  async find(params) {
    const response = await super.find(params);
    return super.generateDefaultResponse(response);
  }

  async get(id) {
    const response = await super.get(id);
    return super.generateDefaultResponse(response);
  }

  async create(data) {
    const response = await super.create(data);
    return super.generateDefaultResponse(response);
  }

  async patch(data) {
    // console.log(data);
    delete data?.isVerified;
    const response = await super.patch(data);
    return super.generateDefaultResponse(response);
  }

  //#region dari mobile diconver ke web
  async fetch(params) {
    const response = await super.find(params);
    return response;
  }

  async fetchOne(id) {
    const response = await super.get(id);
    return response;
  }

  async postData(data) {
    const response = await super.create(data);
    return response;
  }

  async patchData(id, data) {
    data.id = id;
    const response = await super.patch(data);
    return response;
  }

  async deleteData(id) {
    const response = await super.delete(id);
    return response;
  }

  async getDataFilter(data, filter) {
    let datas = [];
    datas = toFilteredData(data, filter);
    const { currentIndex, zeroIndex } = getIndex(data, filter);
    const dataChart = {
      result: parseToChartCalories(datas, filter),
      currentIndex,
      zeroIndex
    };
    return { success: true, dataChart };
  }
  //#endregion
}

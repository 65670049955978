import * as actionTypes from './food-recipe.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  loading: false,
  loadingDetail: false,
  lists: [],
  details: {},
  error: null,
  message: null
};

/**
 * FETCH FOODS
 */
const fetchFoodsRecipeStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchFoodsRecipeSuccess = (state, action) =>
  updateObject(state, { loading: false, error: null, ...action });
const fetchFoodsRecipeConcat = (state, action) =>
  updateObject(state, {
    loading: false,
    error: null,
    ...action,
    lists: state.lists.concat(action.lists)
  });
const fetchFoodsRecipeFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * FETCH FOODS DETAILS
 */
const fetchFoodsRecipeDetailStart = (state, action) =>
  updateObject(state, { loadingDetail: true });
const fetchFoodsRecipeDetailSuccess = (state, action) =>
  updateObject(state, {
    loadingDetail: false,
    error: null,
    ...action
  });
const fetchFoodsRecipeDetailFail = (state, action) =>
  updateObject(state, { loadingDetail: false, error: action.error });

/**
 * CREATE FOODS
 */
const createFoodsRecipeStart = (state, action) =>
  updateObject(state, { loading: true });
const createFoodsRecipeSuccess = (state, action) =>
  updateObject(state, { loading: false, error: null });
const createFoodsRecipeFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UTILITY
 */
const foodsReset = state => {
  return updateObject(state, {
    lists: [],
    error: null,
    message: null
  });
};
const foodsDismissError = state => {
  return updateObject(state, {
    error: null,
    message: null
  });
};

export default function foodsRecipeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.FETCH_FOODS_RECIPE_START:
      return fetchFoodsRecipeStart(state, action);
    case actionTypes.FETCH_FOODS_RECIPE_SUCCESS:
      return fetchFoodsRecipeSuccess(state, action);
    case actionTypes.FETCH_FOODS_RECIPE_SUCCESS:
      return fetchFoodsRecipeConcat(state, action);
    case actionTypes.FETCH_FOODS_RECIPE_FAIL:
      return fetchFoodsRecipeFail(state, action);

    case actionTypes.FETCH_FOODS_RECIPE_DETAIL_START:
      return fetchFoodsRecipeDetailStart(state, action);
    case actionTypes.FETCH_FOODS_RECIPE_DETAIL_SUCCESS:
      return fetchFoodsRecipeDetailSuccess(state, action);
    case actionTypes.FETCH_FOODS_RECIPE_DETAIL_FAIL:
      return fetchFoodsRecipeDetailFail(state, action);

    case actionTypes.CREATE_FOODS_RECIPE_START:
      return createFoodsRecipeStart(state, action);
    case actionTypes.CREATE_FOODS_RECIPE_SUCCESS:
      return createFoodsRecipeSuccess(state, action);
    case actionTypes.CREATE_FOODS_RECIPE_FAIL:
      return createFoodsRecipeFail(state, action);

    case actionTypes.RESET_FOODS_RECIPE:
      return foodsReset(state, action);
    case actionTypes.FOODS_RECIPE_DISMISS_ERROR:
      return foodsDismissError(state, action);

    default:
      return state;
  }
}

import { put } from 'redux-saga/effects';
import * as actions from '../../reducers/food/food.actions';
import foodsServices from '../../../services/Food';

/**
 * FETCH FOODS
 */
export function* fetchFoods({ params, userId }) {
  yield put(actions.fetchFoodsStart());
  // console.log(userId, '====<<');
  const services = yield new foodsServices();
  params = {
    ...params,
    'exactSearchOr[userId]': userId,
    // 'exactSearchOr[source]': 'admin',
    // 'exactSearchOr[source]': 'database',
    // 'exactSearchOr[source]': 'database+(edited)',
    'exactSearchOr[source]': ['admin', 'database', 'database (edited)'] //gak tau kenapa, di pwa, generated url ny jadi ga sama dg di mobile klo pakai sintax ini
  };
  // console.log('PARAMSSSS 02:', params);
  const res = yield services.find(params);

  if (res.error) {
    yield put(actions.fetchFoodsFail(res.error));
    return;
  } else {
    if (params.$skip === 0) {
      yield put(
        actions.fetchFoodsSuccess({
          lists: res.data || [],
          message: res.message
        })
      );
    } else {
      yield put(
        actions.fetchFoodsConcat({
          lists: res.data || [],
          message: res.message
        })
      );
    }
  }
}

/**
 * FETCH FOODS DETAIL
 */
export function* fetchFoodsDetail({ id }) {
  console.log('fetchFoodsDetail', id);
  yield put(actions.fetchFoodsDetailStart());
  const services = yield new foodsServices();
  const res = yield services.get(id);

  if (res.error) {
    yield put(actions.fetchFoodsDetailFail(res.error));
    return;
  }

  yield put(
    actions.fetchFoodsDetailSuccess({
      details: res,
      message: res.message
    })
  );
}

/**
 * CREATE FOODS
 */
export function* createFoods({ data, date, userId }) {
  yield put(actions.createFoods());
  const services = yield new foodsServices();
  const res = yield services.create({ ...data, userId });

  if (res.error) {
    yield put(actions.createFoodsFail(res.error));
    return;
  }

  yield put(
    actions.createFoodsSuccess({
      message: res.message
    })
  );
}

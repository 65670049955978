import { put, apply, select, call, all, delay } from 'redux-saga/effects';
import moment from 'moment';
import _ from 'lodash';
import { setGreetingLastAction } from '../../reducers/greeting/greeting.actions';
import * as medicalActions from '../../reducers/medical-reminder/medical-reminder.actions';
import * as authSelector from '../../reducers/medical-reminder/medical-reminder.selectors';
import MedicalReminderService from '../../../services/MedicalReminder';
import MedicalTakenService from '../../../services/MedicalTaken';

export function* createMedicalReminder({ data }) {
  yield put(medicalActions.createMedicalReminder.start());
  const services = yield new MedicalReminderService();
  const userId = yield select(authSelector.idUser);
  const res = yield apply(services, services.postData, [{ ...data, userId }]);
  if (res.data) {
    yield put(medicalActions.createMedicalReminder.success());
    yield put(
      medicalActions.fetchByFilterMedicalReminder.call({
        date: data.activeBy
      })
    );
    yield put(
      setGreetingLastAction({
        type: 'Tambah Pengingat Obat'
      })
    );
    window.location.replace('/pengingat-obat');
  } else {
    yield put(medicalActions.createMedicalReminder.fail(res.error));
  }
}

// export function* updateMedicalReminder({ id, data, initialSchedules }) {
//   yield put(medicalActions.updateMedicalReminder.start());
//   const userId = yield select(authSelector.idUser);
//   const medicalService = yield new MedicalReminderService();
//   const alarmService = yield new AlarmService();
//   const res = yield apply(medicalService, medicalService.patchData, [
//     id,
//     { ...data, userId },
//   ]);
//   if (res.success) {
//     console.log('res update', { res }, { data });
//     const schedules = data.schedules;
//     const len = schedules.length > initialSchedules.length ? schedules.length : initialSchedules.length;
//     yield apply(alarmService, alarmService.deleteMedicineAlarm, [res.data.medicineId,]);
//     if (data.notificationStatus) {
//       for (let i = 0; i < len; i++) {
//         yield put(
//           alarmActions.updateAlarm.call({
//             reminderIdSource: id,
//             reminderId: res.data.id,
//             medicineId: res.data.medicineId,
//             timeSource: initialSchedules[i],
//             time: schedules[i],
//             end: data.endDate,
//           }),
//         );
//         yield delay(1);
//       }
//     } else {
//       yield put(alarmActions.deleteMedicineAlarm.call(res.data.medicineId));
//     }
//     yield apply(medicalService, medicalService.upsertData, [res.data]);
//     yield put(
//       medicalActions.fetchByFilterMedicalReminder.call({
//         date: data.activeBy,
//       }),
//     );
//     yield put(medicalActions.updateMedicalReminder.success());
//     yield call(showToast, {
//       type: 'success',
//       text1: 'Berhasil',
//       text2: 'Pengingat sukses diperbaharui',
//     });
//     yield call(goBack);
//   } else {
//     yield put(medicalActions.updateMedicalReminder.fail(res));
//     yield call(showToast, {
//       type: 'error',
//       text1: 'Terjadi Kesalahan',
//       text2: res.data?.message || res.error,
//     });
//   }
// }

// export function* removeMedicalReminder({ id, params }) {
//   yield put(medicalActions.removeMedicalReminder.start());
//   const userId = yield select(authSelector.idUser);
//   const medicalService = yield new MedicalReminderService();
//   const res = yield apply(medicalService, medicalService.deleteData, [
//     id,
//     { ...params, userId },
//   ]);
//   if (res.success) {
//     yield apply(medicalService, medicalService.upsertData, [res.data]);
//     yield put(medicalActions.removeMedicalReminder.success());
//     yield put(
//       medicalActions.fetchByFilterMedicalReminder.call({
//         date: params.activeBy,
//       }),
//     );
//     yield call(showToast, {
//       type: 'success',
//       text1: 'Berhasil',
//       text2: 'Pengingat sukses dihapus',
//     });
//     yield call(goBack);
//   } else {
//     yield put(medicalActions.removeMedicalReminder.fail(res));
//     yield call(showToast, {
//       type: 'error',
//       text1: 'Terjadi Kesalahan',
//       text2: res.data?.message || res.error,
//     });
//     yield call(showToast, {
//       type: 'error',
//       text1: 'Terjadi Kesalahan',
//       text2: res.data?.message || res.error,
//     });
//   }
// }

export function* fetchByFilterMedicalReminder({ params }) {
  yield put(medicalActions.fetchByFilterMedicalReminder.start());
  const services = yield new MedicalReminderService();
  const res = yield apply(services, services.fetchAll, [
    { ...params, isMobile: 1 }
  ]);
  if (res.data) {
    const datas = res?.data || [];
    yield put(
      medicalActions.fetchByFilterMedicalReminder.success(
        params.date,
        _.sortBy(datas, item => item.time, 'desc')
      )
    );
  } else {
    yield put(medicalActions.fetchByFilterMedicalReminder.fail(res.error));
  }
}

// export function* findByFilterMedicalReminder({ date }) {
//   yield put(medicalActions.findByFilterMedicalReminder.start());
//   const medicalService = yield new MedicalReminderService();
//   const res = yield apply(medicalService, medicalService.findAll, [date]);
//   if (res.success) {
//     yield put(
//       medicalActions.findByFilterMedicalReminder.success(
//         date,
//         _.sortBy(res.data, (item) => item.time, 'desc'),
//       ),
//     );
//   } else {
//     yield put(medicalActions.findByFilterMedicalReminder.fail(res));
//     yield call(showToast, {
//       type: 'error',
//       text1: 'Terjadi Kesalahan',
//       text2: res.data?.message || res.error,
//     });
//   }
// }

// export function* fetchDetailMedicalReminder({ id }) {
//   yield put(medicalActions.fetchDetailMedicalReminder.start());
//   const medicalService = yield new MedicalReminderService();
//   const res = yield apply(medicalService, medicalService.fetchDetail, [id]);
//   if (res.success) {
//     yield put(medicalActions.fetchDetailMedicalReminder.success(id, res.data));
//   } else {
//     yield put(medicalActions.findDetailMedicalReminder.call(id));
//   }
// }

// export function* findDetailMedicalReminder({ id }) {
//   yield put(medicalActions.findDetailMedicalReminder.start());
//   const medicalService = yield new MedicalReminderService();
//   const res = yield apply(medicalService, medicalService.findOne, [id]);
//   if (res.success) {
//     yield put(medicalActions.findDetailMedicalReminder.success(id, res.data));
//   } else {
//     yield put(medicalActions.findDetailMedicalReminder.fail(res));
//     yield call(showToast, {
//       type: 'error',
//       text1: 'Terjadi Kesalahan',
//       text2: res.data?.message || res.error,
//     });
//   }
// }

export function* fetchChartDetailMedicalReminder({ params }) {
  yield put(medicalActions.fetchChartDetailMedicalReminder.start());
  const takenService = yield new MedicalTakenService();
  const res = yield apply(takenService, takenService.fetchAll, [params]);
  if (res.data) {
    yield put(medicalActions.fetchChartDetailMedicalReminder.success(res.data));
  } else {
    yield put(medicalActions.fetchChartDetailMedicalReminder.fail(res));
    // yield call(showToast, {
    //   type: 'error',
    //   text1: 'Terjadi Kesalahan',
    //   text2: res.data?.message || res.error,
    // });
  }
}

export function* confirmMedicalReminder({ id, data }) {
  yield put(
    medicalActions.confirmMedicalReminder.start(data.medicineScheduleId)
  );
  const takenService = yield new MedicalTakenService();
  let res;
  if (id && !data.isTaken) {
    res = yield apply(takenService, takenService.patchData, [id, data]);
  } else {
    res = yield apply(takenService, takenService.postData, [data]);
  }
  if (res.success) {
    yield apply(takenService, takenService.upsertData, [res.data]);
    yield put(medicalActions.confirmMedicalReminder.success());
    yield put(
      setGreetingLastAction({
        type: 'Konfirmasi Pengingat Obat',
        condition: data?.isTaken ? 'Sudah Minum Obat' : 'Lewati Minum Obat'
      })
    );
    yield put(
      medicalActions.fetchByFilterMedicalReminder.call({ date: data.date })
    );
  } else {
    yield put(medicalActions.confirmMedicalReminder.fail(res));
    // yield call(showToast, {
    //   type: 'error',
    //   text1: 'Terjadi Kesalahan',
    //   text2:
    //     res.data?.message || res.error == 'no connection'
    //       ? 'Anda tidak terhubung dengan jaringan internet'
    //       : res.error,
    // });
  }
}

// export function* syncMedicalReminderTaken() {
//   yield put(medicalActions.syncMedicalReminderTaken.start());
//   const userId = yield select(authSelector.idUser);
//   const medicalService = yield new MedicalReminderService();
//   const takenService = yield new MedicalTakenService();

//   // bring date for one month back
//   const dateFrom = moment().subtract(1, 'M').format('YYYY-MM-DD');
//   const dateUntil = moment().format('YYYY-MM-DD');
//   const { reminderResponse, takenResponse } = yield all({
//     reminderResponse: apply(medicalService, medicalService.fetchAll, [
//       { $limit: -1, userId },
//     ]),
//     takenResponse: apply(takenService, takenService.fetchAll, [
//       {
//         dateFrom,
//         dateUntil,
//         $limit: -1,
//         userId,
//       },
//     ]),
//   });
//   const { todaySchedule, tomorrowSchedule } = yield all({
//     todaySchedule: yield apply(medicalService, medicalService.fetchAll, [
//       { date: moment().format('YYYY-MM-DD'), userId, isMobile: 1 },
//     ]),
//     tomorrowSchedule: yield apply(medicalService, medicalService.fetchAll, [
//       {
//         date: moment().add(1, 'days').format('YYYY-MM-DD'),
//         userId,
//         isMobile: 1,
//       },
//     ]),
//   });
//   yield put(
//     alarmActions.syncAlarm.call(
//       reminderResponse.data,
//       tomorrowSchedule.data,
//       todaySchedule.data,
//     ),
//   );
//   if (reminderResponse.success && takenResponse.success) {
//     yield apply(medicalService, medicalService.bulkInsertData, [
//       reminderResponse.data || [],
//     ]);
//     yield apply(takenService, takenService.bulkInsertData, [
//       takenResponse.data || [],
//     ]);
//     yield put(
//       medicalActions.syncMedicalReminderTaken.reminderSuccess(
//         reminderResponse.data || [],
//       ),
//     );
//     yield put(
//       medicalActions.syncMedicalReminderTaken.takenSuccess(
//         takenResponse.data || [],
//       ),
//     );
//   } else if (reminderResponse.success) {
//     // yield put(
//     //   alarmActions.syncAlarm.call(
//     //     reminderResponse.data,
//     //     tomorrowSchedule.data,
//     //     todaySchedule.data,
//     //   ),
//     // );
//     yield apply(medicalService, medicalService.bulkInsertData, [
//       reminderResponse.data || [],
//     ]);
//     yield put(
//       medicalActions.syncMedicalReminderTaken.reminderSuccess(
//         reminderResponse.data || [],
//       ),
//     );
//     // bulkCreateAlarm(userId, reminderResponse.data);
//     yield put(medicalActions.syncMedicalReminderTaken.takenFail(takenResponse));
//   } else if (takenResponse.success) {
//     yield apply(takenService, takenService.bulkInsertData, [
//       takenResponse.data || [],
//     ]);
//     yield put(
//       medicalActions.syncMedicalReminderTaken.takenSuccess(
//         takenResponse.data || [],
//       ),
//     );
//     yield put(
//       medicalActions.syncMedicalReminderTaken.reminderFail(reminderResponse),
//     );
//   } else {
//     yield put(
//       medicalActions.syncMedicalReminderTaken.reminderFail(reminderResponse),
//     );
//     yield put(medicalActions.syncMedicalReminderTaken.takenFail(takenResponse));
//   }
// }

export const generateLastUpdateText = (
  dayDiff,
  hourDiff,
  minDiff,
  noData = false
) => {
  if (dayDiff > 0) {
    return `Diubah ${dayDiff} hari lalu`;
  } else if (hourDiff > 0) {
    return `Diubah ${hourDiff} jam lalu`;
  } else if (minDiff > 0) {
    return `Diubah ${minDiff} menit lalu`;
  } else if (noData) {
    return `Belum ada data`;
  } else {
    return 'Diubah beberapa detik lalu';
  }
};

import { all } from 'redux-saga/effects';
import { authManagementSagas } from './auth-management';
import { authSagas } from './auth';
import { usersSagas } from './users';
import { transisiSagas } from './transisi';
import { elvasenseSagas } from './elvasense';
import { bannerSagas } from './banner';
import { articleSagas } from './article';
import { articleCategorySagas } from './article-category';
import { foodSagas } from './food';
import { foodRecipeSagas } from './food-recipe';
import { medicalRecordSagas } from './medical-record';
import { medicalReminderSagas } from './medical-reminder';
import { goalsSagas } from './goals';
import { foodHistorySagas } from './food-history';
import { greetingSaga } from './greeting';
import { pinnedSagas } from './pinned-content';
import { klikDokterSagas } from './klik-dokter';
import { infoSagas } from './page-information';
import { usersCategorySagas } from './user-category';
import { notificationSagas } from './notification';

export default function* rootSaga() {
  yield all({
    ...authManagementSagas,
    ...authSagas,
    ...usersSagas,
    ...transisiSagas,
    ...greetingSaga,
    ...elvasenseSagas,
    ...bannerSagas,
    ...articleSagas,
    ...foodSagas,
    ...foodRecipeSagas,
    ...medicalRecordSagas,
    ...medicalReminderSagas,
    ...goalsSagas,
    ...foodHistorySagas,
    ...pinnedSagas,
    ...articleCategorySagas,
    ...klikDokterSagas,
    ...infoSagas,
    ...usersCategorySagas,
    ...notificationSagas
  });
}

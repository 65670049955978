import * as bannerTypes from './banner.types';

/**
 * FETCH BANNERS
 */
export const fetchBanners = params => ({
  type: bannerTypes.FETCH_BANNERS,
  params
});
export const fetchBannersStart = () => ({
  type: bannerTypes.FETCH_BANNERS_START
});
export const fetchBannersSuccess = banners => ({
  type: bannerTypes.FETCH_BANNERS_SUCCESS,
  banners
});
export const fetchBannersFail = error => ({
  type: bannerTypes.FETCH_BANNERS_FAIL,
  error
});

/**
 * GET BANNER
 */
export const getBanner = id => ({
  type: bannerTypes.GET_BANNER,
  id
});
export const getBannerStart = () => ({
  type: bannerTypes.GET_BANNER_START
});
export const getBannerSuccess = banner => ({
  type: bannerTypes.GET_BANNER_SUCCESS,
  banner
});
export const getBannerFail = error => ({
  type: bannerTypes.GET_BANNER_FAIL,
  error
});

// ====== UTILITY  ======= //
export const bannerDismissError = () => {
  return {
    type: bannerTypes.BANNER_DISMISS_ERROR
  };
};

export const bannerAddFilter = (filter, filterObj) => {
  return {
    type: bannerTypes.BANNER_ADD_FILTER,
    filter: filter,
    filterObj: filterObj
  };
};

export const bannerClearFilter = () => {
  return {
    type: bannerTypes.BANNER_CLEAR_FILTER
  };
};

export const bannerAddPagination = pagination => {
  return {
    type: bannerTypes.BANNER_ADD_PAGINATION,
    pagination: pagination
  };
};

export const bannerClearPagination = () => {
  return {
    type: bannerTypes.BANNER_CLEAR_PAGINATION
  };
};

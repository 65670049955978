export const GET_LATEST_DATA_MEDICAL_RECORD_REQUEST =
  'GET_LATEST_DATA_MEDICAL_RECORD_REQUEST';
// glucose
export const GET_LATEST_GLUCOSE_REQUEST = 'GET_LATEST_GLUCOSE_REQUEST';
export const GET_LATEST_GLUCOSE_REQUEST_START =
  'GET_LATEST_GLUCOSE_REQUEST_START';
export const GET_LATEST_GLUCOSE_REQUEST_SUCCESS =
  'GET_LATEST_GLUCOSE_REQUEST_SUCCESS';
export const GET_LATEST_GLUCOSE_REQUEST_FAIL =
  'GET_LATEST_GLUCOSE_REQUEST_FAIL';
// hba1c
export const GET_LATEST_HBA1C_REQUEST = 'GET_LATEST_HBA1C_REQUEST';
export const GET_LATEST_HBA1C_REQUEST_START = 'GET_LATEST_HBA1C_REQUEST_START';
export const GET_LATEST_HBA1C_REQUEST_SUCCESS =
  'GET_LATEST_HBA1C_REQUEST_SUCCESS';
export const GET_LATEST_HBA1C_REQUEST_FAIL = 'GET_LATEST_HBA1C_REQUEST_FAIL';
// water intake
export const GET_LATEST_WATER_INTAKE_REQUEST =
  'GET_LATEST_WATER_INTAKE_REQUEST';
export const GET_LATEST_WATER_INTAKE_REQUEST_START =
  'GET_LATEST_WATER_INTAKE_REQUEST_START';
export const GET_LATEST_WATER_INTAKE_REQUEST_SUCCESS =
  'GET_LATEST_WATER_INTAKE_REQUEST_SUCCESS';
export const GET_LATEST_WATER_INTAKE_REQUEST_FAIL =
  'GET_LATEST_WATER_INTAKE_REQUEST_FAIL';
// activity
export const GET_LATEST_ACTIVITY_REQUEST = 'GET_LATEST_ACTIVITY_REQUEST';
export const GET_LATEST_ACTIVITY_REQUEST_START =
  'GET_LATEST_ACTIVITY_REQUEST_START';
export const GET_LATEST_ACTIVITY_REQUEST_SUCCESS =
  'GET_LATEST_ACTIVITY_REQUEST_SUCCESS';
export const GET_LATEST_ACTIVITY_REQUEST_FAIL =
  'GET_LATEST_ACTIVITY_REQUEST_FAIL';
export const UPDATE_LATEST_ACTIVITY_REQUEST = 'UPDATE_LATEST_ACTIVITY_REQUEST';
export const UPDATE_LATEST_ACTIVITY_REQUEST_START =
  'UPDATE_LATEST_ACTIVITY_REQUEST_START';
export const UPDATE_LATEST_ACTIVITY_REQUEST_SUCCESS =
  'UPDATE_LATEST_ACTIVITY_REQUEST_SUCCESS';
export const UPDATE_LATEST_ACTIVITY_REQUEST_FAIL =
  'UPDATE_LATEST_ACTIVITY_REQUEST_FAIL';
export const SET_LAST_ACTION = 'SET_LAST_ACTION';

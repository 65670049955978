import * as actionTypes from './goals.types';

export const initialState = {
  id: null,
  calories: 203,
  steps: 6000,
  distance: 4,
  water: 8,
  caloriesDaily: null,
  bloodPressureMin: null,
  bloodPressureMax: null,
  hba1cMin: null,
  hba1cMax: null,
  beforeEatGlucoseMin: null,
  beforeEatGlucoseMax: null,
  afterEatGlucoseMin: null,
  afterEatGlucoseMax: null,
  fastingGlucoseMin: null,
  fastingGlucoseMax: null,
  isLoading: false,

  defaultTarget: {
    caloriesDaily: 3000,
    steps: 6000,
    calories: 203,
    distance: 4,
    water: 8,
    hba1c: 15,
    glucose: 250
  }
};

export default function goalsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_GOALS_REDUCER:
      return initialState;
    case actionTypes.FETCH_GOALS_MEDICAL_RECORD_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.FETCH_GOALS_MEDICAL_RECORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    case actionTypes.FETCH_GOALS_MEDICAL_RECORD_FAIL:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}

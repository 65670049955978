import * as transisiTypes from './transisi.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  loading: false
};

const updateTransisiStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const updateTransisiSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  });
};

const updateTransisiFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const transisiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case transisiTypes.TRANSISI_USER_START:
      return updateTransisiStart(state, action);
    case transisiTypes.TRANSISI_USER_SUCCESS:
      return updateTransisiSuccess(state, action);
    case transisiTypes.TRANSISI_USER_FAIL:
      return updateTransisiFail(state, action);
    default:
      return state;
  }
};

export default transisiReducer;

import { put, call } from 'redux-saga/effects';
import { jwtDecode } from 'jwt-decode';
import { ACTION_CLIENT, AUTH_BY } from '../../../variables/auth';
import * as actions from '../../reducers/auth/auth.actions';
import * as actionsAuthManagement from '../../reducers/authManagement/authManagement.actions';
import AuthService from '../../../services/Auth';
import UsersService from '../../../services/Users';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function* logoutSaga(action) {
  yield put(actions.authStart());
  const service = yield new AuthService(true);
  const res = yield service.delete();

  // if (res.error || res.message) {
  //   let errorMessage = res.error || res.message;
  //   yield put(actions.authFail(errorMessage));
  // } else {
  // }

  yield call([localStorage, 'removeItem'], 'token');
  yield call([localStorage, 'removeItem'], 'refreshToken');
  yield call([localStorage, 'removeItem'], 'expirationDate');
  yield call([localStorage, 'removeItem'], 'userId');
  yield call([localStorage, 'removeItem'], 'email');
  yield call([localStorage, 'removeItem'], 'phone');
  yield call([localStorage, 'removeItem'], 'userName');
  yield call([localStorage, 'removeItem'], 'userType');
  yield put(actions.logoutSucceed());
  action.history.replace('/home');
}

// export function* checkAuthTimeoutSaga(action) {
//   yield delay(action.expirationTime * 1000);
//   yield put(actions.logout());
// }

export function* authUserSaga(action) {
  yield put(actions.authStart());
  const service = yield new AuthService();

  const authData = {
    strategy: 'local',
    userType: 'user',
    email: action.email,
    password: action.password
  };

  let deviceId = yield localStorage.getItem('deviceIdKalbe');
  if (!deviceId) {
    deviceId = uuidv4();
    yield localStorage.setItem('deviceIdKalbe', deviceId);
  }

  authData.deviceId = 'user-' + deviceId;
  const res = yield service.create(authData);

  if (res.error) {
    yield put(actions.authFail(res.error));
  } else {
    // console.log(res);
    const tokenDecode = jwtDecode(res.data.accessToken);
    const expirationDate = yield new Date(tokenDecode.exp * 1000);
    const _data = {
      token: res.data.accessToken,
      refreshToken: res.data.refreshToken,
      expirationDate: expirationDate,
      userId: res.data.user.id,
      email: res.data.user.email,
      phone: res.data.user.phone,
      userName: res.data.user.fullName,
      isVerified: res.data.user.isVerified,
      userType: res.data.user.userType,
      hasPassword: res.data.user.hasPassword,
      user: res.data.user
    };
    if (_data.isVerified) {
      yield localStorage.setItem('token', _data.token);
      yield localStorage.setItem('refreshToken', _data.refreshToken);
      yield localStorage.setItem('expirationDate', _data.expirationDate);
      yield localStorage.setItem('userId', _data.userId);
      yield localStorage.setItem('email', _data.email);
      yield localStorage.setItem('phone', _data.phone);
      yield localStorage.setItem('userName', _data.userName);
      yield localStorage.setItem('userType', _data.userType);
      yield localStorage.setItem('hasPassword', _data.hasPassword);
      yield put(
        actions.authSuccess({
          token: _data.token,
          refreshToken: _data.refreshToken,
          expirationDate: _data.expirationDate,
          userId: _data.userId,
          email: _data.email,
          phone: _data.phone,
          userName: _data.userName,
          isVerified: _data.isVerified,
          userType: _data.userType,
          hasPassword: _data.hasPassword,
          user: _data.user
        })
      );
    } else {
      window.location.assign(
        `/auth/success-send-email?email=${_data.email}&action=konfirmasi_pendaftaran`
      );
    }
  }
}

export function* authUserSocMedSaga(params) {
  yield put(actions.authStart());
  const service = yield new AuthService();
  const strategy = AUTH_BY.socmed;
  const action_client = params?.action_client;

  const authData = {
    ...params,
    strategy: strategy,
    fullName: params?.fullName,
    email: params?.email,
    password: params?.password,
    image: params?.image,
    socMed: params?.socMed,
    access_token: params?.access_token,
    action_client: action_client
  };

  let deviceId = yield localStorage.getItem('deviceIdKalbe');
  if (!deviceId) {
    deviceId = uuidv4();
    yield localStorage.setItem('deviceIdKalbe', deviceId);
  }

  authData.deviceId = 'user-' + deviceId;
  const res = yield service.create(authData);

  if (res.error) {
    yield put(actions.authFail(res.error));
  } else {
    const tokenDecode = jwtDecode(res.data.accessToken);
    const expirationDate = yield new Date(tokenDecode.exp * 1000);
    const _data = {
      token: res.data.accessToken,
      refreshToken: res.data.refreshToken,
      expirationDate: expirationDate,
      userId: res.data.user.id,
      email: res.data.user.email,
      phone: res.data.user.phone,
      userName: res.data.user.fullName,
      isVerified: res.data.user.isVerified,
      userType: res.data.user.userType,
      hasPassword: res.data.user.hasPassword,
      user: res.data.user
    };
    if (_data.isVerified) {
      yield localStorage.setItem('token', _data.token);
      yield localStorage.setItem('strategy', strategy);
      yield localStorage.setItem('action_client', action_client);
      yield localStorage.setItem('refreshToken', _data.refreshToken);
      yield localStorage.setItem('expirationDate', _data.expirationDate);
      yield localStorage.setItem('userId', _data.userId);
      yield localStorage.setItem('email', _data.email);
      yield localStorage.setItem('phone', _data.phone);
      yield localStorage.setItem('userName', _data.userName);
      yield localStorage.setItem('userType', _data.userType);
      yield localStorage.setItem('hasPassword', _data.hasPassword);
      yield put(
        actions.authSuccess({
          token: _data.token,
          refreshToken: _data.refreshToken,
          expirationDate: _data.expirationDate,
          userId: _data.userId,
          email: _data.email,
          phone: _data.phone,
          userName: _data.userName,
          isVerified: _data.isVerified,
          userType: _data.userType,
          hasPassword: _data.hasPassword,
          user: _data.user
        })
      );
      if (strategy && !res.data.user.hasPassword) {
        window.location.assign(`/profile/make-new-password`);
      } else {
        window.location.assign(`/transisi`);
      }
    } else {
      window.location.assign(
        `/auth/success-send-email?email=${_data.email}&action=konfirmasi_pendaftaran`
      );
    }
  }
}

export function* authCheckStateSaga(action) {
  // console.log(action);
  const token = yield localStorage.getItem('token');
  const strategy = yield localStorage.getItem('strategy');
  if (!token && strategy !== 'socmed') {
    yield call([localStorage, 'removeItem'], 'token');
    yield call([localStorage, 'removeItem'], 'refreshToken');
    yield call([localStorage, 'removeItem'], 'expirationDate');
    yield call([localStorage, 'removeItem'], 'userId');
    yield call([localStorage, 'removeItem'], 'email');
    yield call([localStorage, 'removeItem'], 'phone');
    yield call([localStorage, 'removeItem'], 'userName');
    yield call([localStorage, 'removeItem'], 'userType');
    yield call([localStorage, 'removeItem'], 'strategy');
    yield call([localStorage, 'removeItem'], 'action_client');
  } else {
    const refreshToken = yield localStorage.getItem('refreshToken');
    const expirationDate = yield localStorage.getItem('expirationDate');
    const userId = yield localStorage.getItem('userId');
    const email = yield localStorage.getItem('email');
    const phone = yield localStorage.getItem('phone');
    const userName = yield localStorage.getItem('userName');
    const userType = yield localStorage.getItem('userType');

    const userService = yield new UsersService();
    let user = null;
    if (userId) {
      user = yield userService.get(userId);
    }

    yield put(
      actions.authSuccess({
        token,
        refreshToken,
        expirationDate,
        userId,
        email,
        phone,
        userName,
        isVerified: true,
        userType,
        user
      })
    );
  }
}

export function* registerUser(action) {
  yield put(actions.registerUserStart());
  const service = yield new UsersService();
  const user = yield service.create(action.userData);
  if (user.error) {
    yield put(actions.registerUserFail(user.error));
    return;
  }

  actionsAuthManagement.verificationEmail({
    action: 'resendVerifySignup',
    value: { email: user.email }
  });

  yield put(actions.registerUserSuccess(user));
  window.location.assign(
    `/auth/success-send-email?email=${user.email}&action=konfirmasi_pendaftaran`
  );
}

export function* removeAuthLocalStorageSaga() {
  yield call([localStorage, 'removeItem'], 'token');
  yield call([localStorage, 'removeItem'], 'strategy');
  yield call([localStorage, 'removeItem'], 'action_client');
  yield call([localStorage, 'removeItem'], 'refreshToken');
  yield call([localStorage, 'removeItem'], 'expirationDate');
  yield call([localStorage, 'removeItem'], 'userId');
  yield call([localStorage, 'removeItem'], 'email');
  yield call([localStorage, 'removeItem'], 'phone');
  yield call([localStorage, 'removeItem'], 'userName');
  yield call([localStorage, 'removeItem'], 'userType');
  yield call([localStorage, 'removeItem'], 'hasPassword');
  window.location.assign(`/auth/login`);
}

import { put, apply, select, call, delay } from 'redux-saga/effects';
import * as syncElvasenseActions from '../../reducers/elvasense/elvasense.actions';
import * as medicalRecordActions from '../../reducers/medical-record/medical-record.actions';
import SyncElvasenseService from '../../../services/Elvasense';
import UserService from '../../../services/Users';
// import { showToast } from '../../../utils/toastUtils';

export function* getSyncElvasense({ id }) {
  yield put(syncElvasenseActions.getSyncElvasense.start());
  const service = yield new UserService();
  const res = yield apply(service, service.get, [id]);
  if (res.error) {
    yield put(syncElvasenseActions.getSyncElvasense.fail(res.error));
  } else {
    yield put(syncElvasenseActions.getSyncElvasense.success(id, res));
  }
}

/**
 * untuk update email dan password elvasense
 */
export function* updateSyncElvasenseEmailPassword({ id, data }) {
  yield put(syncElvasenseActions.updateSyncElvasenseEmailPassword.start());
  const service = yield new SyncElvasenseService();
  const res = yield apply(service, service.updateSyncElvasense, [id, data]);
  console.log('RES---->', res);
  // if (res?.success) {
  //   yield put(syncElvasenseActions.updateSyncElvasenseEmailPassword.success(res.data));
  //   yield call(showToast, {
  //     type: 'success',
  //     text1: 'Berhasil Terhubung',
  //     text2: 'Anda sedang terhubung ke Elvasense',
  //   })
  // } else {
  //   yield put(syncElvasenseActions.updateSyncElvasenseEmailPassword.fail(res.data));
  //   yield call(showToast, {
  //     type: 'error',
  //     text1: 'Terjadi kesalahan',
  //     text2: res.data?.message || res.error,
  //   });
  // }

  yield put(
    syncElvasenseActions.updateSyncElvasenseEmailPassword.success({
      id: id,
      payload: res?.data,
      message: res?.message
    })
  );
  if (res.error) {
    yield put(
      syncElvasenseActions.updateSyncElvasenseEmailPassword.fail({
        error: res.error
      })
    );
    return;
  }
}

/**
 * untuk update elvasenseSynchronize = true || false
 */
export function* updateSyncElvasense({ id, data }) {
  yield put(syncElvasenseActions.updateSyncElvasense.start());
  const service = yield new SyncElvasenseService();
  const res = yield apply(service, service.updateSyncElvasense, [id, data]);
  yield put(
    syncElvasenseActions.updateSyncElvasense.success({
      id: id,
      data: res.data
    })
  );
  if (res.error) {
    yield put(
      syncElvasenseActions.updateSyncElvasense.fail({
        error: res.error
      })
    );
    return;
  }
  // if (res?.success) {
  //   yield put(syncElvasenseActions.updateSyncElvasense.success(res.data));
  //   res?.data?.elvasenseSynchronize == true ?
  //     yield call(showToast, {
  //       type: 'success',
  //       text1: 'Berhasil Terhubung',
  //       text2: 'Anda sedang terhubung ke Elvasense',
  //     }) : yield call(showToast, {
  //       type: 'success',
  //       text1: 'Berhasil Terputus',
  //       text2: 'Anda sudah tidak terhubung ke Elvasense',
  //     });
  // } else {
  //   yield put(syncElvasenseActions.updateSyncElvasense.fail(res.data));
  //   yield call(showToast, {
  //     type: 'error',
  //     text1: 'Terjadi kesalahan',
  //     text2: res.data?.message || res.error,
  //   });
  // }
}

export function* postSyncElvasense({ data }) {
  console.log('postSyncElvasense', data);
  yield put(syncElvasenseActions.postSyncElvasense.start());
  const service = yield new SyncElvasenseService();
  const res = yield apply(service, service.postSyncElvasense, [data]);
  if (!res.error) {
    yield put(
      syncElvasenseActions.postSyncElvasense.success({
        message: 'Berhasil SYNC updateSyncElvasense'
      })
    );
    yield put(medicalRecordActions.getLatestRequest(data.id));
  } else {
    yield put(
      syncElvasenseActions.postSyncElvasense.fail({
        error: res.error
      })
    );
    return;
  }
}

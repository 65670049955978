import { put, apply, select, call, delay } from 'redux-saga/effects';
import * as foodHistoryActions from '../../reducers/food-history/food-history.actions';
import FoodHistoryService from '../../../services/FoodHistory';
import FoodHistoryChartService from '../../../services/FoodHistoryChart';

export function* fetchHistory(action) {
  yield put(foodHistoryActions.fetchHistory.start());
  const service = yield new FoodHistoryService();
  const res = yield apply(service, service.fetch, [
    {
      userId: action.userId,
      date: action.date,
      $limit: -1
    }
  ]);
  // console.log('RES FETCH', res.data);
  if (res.data) {
    yield put(foodHistoryActions.fetchHistory.success(res.data));
  } else {
    yield put(foodHistoryActions.fetchHistory.fail(res.data));
  }
}

export function* fetchHistoryDetail({ id }) {
  yield put(foodHistoryActions.fetchHistoryDetail.start());
  const service = yield new FoodHistoryService();
  const res = yield apply(service, service.fetchOne, [id]);
  if (res.error) {
    yield put(
      foodHistoryActions.fetchHistoryDetail.fail({
        error: res.error
      })
    );
    return;
  }

  yield put(foodHistoryActions.fetchHistoryDetail.success(id, res?.data));
}

export function* fetchHistoryChart({ date, typeFilter, userId }) {
  yield put(foodHistoryActions.fetchHistoryChart.start());
  const service = yield new FoodHistoryChartService();
  const res = yield apply(service, service.fetchChart, [
    { userId, date, typeFilter }
  ]);
  // console.log('RES FETCH CHART', res.data);
  if (res.data) {
    const result = yield apply(service, service.getDataFilter, [
      res.data,
      typeFilter
    ]);
    yield put(foodHistoryActions.fetchHistoryChart.success(result.dataChart));
    if (result.data) {
      console.log('dataChartSagas', result.dataChart);
    }
  } else {
    yield put(foodHistoryActions.fetchHistoryChart.fail(res.data));
  }
}

export function* createHistory({ data, userId }) {
  yield put(foodHistoryActions.createHistory.start());
  const services = yield new FoodHistoryService();
  const res = yield services.create({ ...data, userId });

  if (res.error) {
    yield put(
      foodHistoryActions.createHistory.fail({
        error: res.error
      })
    );
    return;
  }

  yield put(
    foodHistoryActions.createHistory.success({
      message: 'Tambah makanan berhasil'
    })
  );
  // window.location.replace('/diet-dan-nutrisi');
}

export function* updateHistory({ id, data, userId }) {
  yield put(foodHistoryActions.updateHistory.start());
  const service = yield new FoodHistoryService();
  const res = yield apply(service, service.patchData, [
    id,
    { ...data, userId }
  ]);
  yield put(
    foodHistoryActions.updateHistory.success({
      message: 'Update makanan berhasil'
    })
  );
  if (res.error) {
    yield put(
      foodHistoryActions.updateHistory.fail({
        error: res.error
      })
    );
    return;
  }
}

export function* removeHistory({ id, userId }) {
  yield put(foodHistoryActions.removeHistory.start());
  const service = yield new FoodHistoryService();
  const res = yield apply(service, service.deleteData, [id, { userId }]);
  yield put(
    foodHistoryActions.removeHistory.success({
      message: 'Makanan berhasil dihapus'
    })
  );
  if (res.error) {
    yield put(
      foodHistoryActions.removeHistory.fail({
        error: res.error
      })
    );
    return;
  }
}

// export function* setGreetingHistory({date}) {
//   yield put(foodHistoryActions.fetchHistory.start());
//   const userId = yield select(authSelector.idUser);
//   const service = yield new FoodHistoryService();
//   const caloriesDaily = yield select(goalsSelector.caloriesDaily);
//   const res = yield apply(service, service.fetch, [{userId, date, $limit: -1}]);
//   if (res.success) {
//     yield put(foodHistoryActions.fetchHistory.success(res.data));
//     const totalCalories = res.data.data?.totalCalories || 0;
//     if (caloriesDaily) {
//       const type = 'Sudah Atur Target';
//       let condition = null;
//       const lowerTarget = Math.round(caloriesDaily / 2);
//       if (totalCalories > caloriesDaily) {
//         condition = 'Lebih dari Target';
//       } else if (totalCalories === caloriesDaily) {
//         condition = 'Sesuai Target';
//       } else if (totalCalories > lowerTarget) {
//         condition = 'Tengah Target';
//       } else {
//         condition = 'Kurang dari Target';
//       }
//       yield put(
//         setGreetingLastAction({
//           type,
//           condition,
//         }),
//       );
//     } else {
//       const type = 'Belum Atur Target';
//       const conditions = ['Kalimat Pertama', 'Kalimat Kedua', 'Kalimat Ketiga'];
//       const lastActionIndex = yield select(greetingSelector.lastActionIndex);
//       const condition = conditions[lastActionIndex];
//       yield put(
//         setGreetingLastAction({
//           type,
//           condition,
//           index: lastActionIndex === 2 ? 0 : lastActionIndex + 1,
//         }),
//       );
//     }
//   } else {
//     yield put(foodHistoryActions.fetchHistory.fail(res.data));
//   }
// }

import DateUTCString_ from './DateUTCString';
import DateFromUTCString_, {
  getDate as getDate_,
  getHour as getHour_
} from './DateFromUTCString';
import updateObject_ from './updateObject';

export const DateUTCString = DateUTCString_;
export const DateFromUTCString = DateFromUTCString_;
export const getDate = getDate_;
export const getHour = getHour_;
export const updateObject = updateObject_;

import { takeEvery } from 'redux-saga/effects';
import {
  createNotification,
  updateOpenNotification,
  syncNotification,
  syncUnreadNotification
} from './notification.sagas';
import * as types from './../../reducers/notification/notification.types';

export const notificationSagas = {
  createNotification: takeEvery(types.CREATE_NOTIFICATION, createNotification),
  updateOpenNotification: takeEvery(
    types.UPDATE_NOTIFICATION,
    updateOpenNotification
  ),
  syncNotification: takeEvery(types.SYNC_NOTIFICATION, syncNotification),
  syncUnreadNotification: takeEvery(
    types.SYNC_UNREAD_NOTIFICATION,
    syncUnreadNotification
  )
};

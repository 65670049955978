import { put, apply, select, takeEvery } from 'redux-saga/effects';
import { getDiffDays } from '../../../utils/DifTime';
import formatDate from '../../../utils/formatDate';
import compareDate from '../../../utils/compareDate';
import { generateLastUpdateText } from '../../../utils/generateLastUpdateText';
import * as medicalRecordAction from '../../reducers/medical-record/medical-record.actions';
import * as medicalRecordActionTypes from '../../reducers/medical-record/medical-record.types';

import GlucoseService from '../../../services/Glucose';
import HBA1CService from '../../../services/HBA1C';
import WaterConsumptionService from '../../../services/WaterConsumption';
import ActivityService from '../../../services/Activity';

export function* getGlucoseLatestData(action) {
  yield put(medicalRecordAction.getLatestGlucose().start());
  const glucose = yield new GlucoseService();
  const resultGlucose = yield apply(glucose, glucose.fetchLatest, [
    action.userId
  ]);
  const dateDiffGlucose = resultGlucose.updated
    ? getDiffDays(new Date(resultGlucose.updated), new Date())
    : null;
  const payloadGlucose = {
    ...resultGlucose,
    dayDiff: dateDiffGlucose?.diffDays,
    hourDiff: dateDiffGlucose?.diffHrs,
    minDiff: dateDiffGlucose?.diffMins,
    lastUpdateText: generateLastUpdateText(
      dateDiffGlucose?.diffDays,
      dateDiffGlucose?.diffHrs,
      dateDiffGlucose?.diffMins,
      dateDiffGlucose === null
    )
  };
  yield put(medicalRecordAction.getLatestGlucose().success(payloadGlucose));
}

export function* getHBA1CLatestData(action) {
  yield put(medicalRecordAction.getLatestHBA1C().start());
  const hba1c = yield new HBA1CService();
  /* HBA1C */
  const resultHba1c = yield apply(hba1c, hba1c.fetchLatest, [action.userId]);
  const dateDiffHba1c = resultHba1c.updated
    ? getDiffDays(new Date(resultHba1c.updated), new Date())
    : null;
  const payloadHba1c = {
    ...resultHba1c,
    dayDiff: dateDiffHba1c?.diffDays,
    hourDiff: dateDiffHba1c?.diffHrs,
    minDiff: dateDiffHba1c?.diffMins,
    lastUpdateText: generateLastUpdateText(
      dateDiffHba1c?.diffDays,
      dateDiffHba1c?.diffHrs,
      dateDiffHba1c?.diffMins,
      dateDiffHba1c === null
    )
  };
  yield put(medicalRecordAction.getLatestHBA1C().success(payloadHba1c));
}

export function* getWaterIntakeLatestData(action) {
  yield put(medicalRecordAction.getLatestWaterIntake().start());
  /* WATER INTAKE */
  const waterIntake = yield new WaterConsumptionService();
  const resultWaterIntake = yield apply(waterIntake, waterIntake.fetchLatest, [
    action.userId
  ]);
  const dateDiffWaterIntake = resultWaterIntake.updated
    ? getDiffDays(new Date(resultWaterIntake.updated), new Date())
    : null;
  let payloadWaterIntake = {
    ...resultWaterIntake,
    dayDiff: dateDiffWaterIntake?.diffDays,
    hourDiff: dateDiffWaterIntake?.diffHrs,
    minDiff: dateDiffWaterIntake?.diffMins,
    lastUpdateText: generateLastUpdateText(
      dateDiffWaterIntake?.diffDays,
      dateDiffWaterIntake?.diffHrs,
      dateDiffWaterIntake?.diffMins,
      dateDiffWaterIntake === null
    )
  };
  yield put(
    medicalRecordAction.getLatestWaterIntake().success(payloadWaterIntake)
  );
}

export function* getActivityLatestData(action) {
  yield put(medicalRecordAction.getLatestActivity().start());

  const checkResponse = res => {
    if (res && res.length === 0) {
      return false;
    }
    return true;
  };

  const activity = yield new ActivityService();
  const date = formatDate(new Date());

  const historyManual = yield apply(activity, activity.getDataHistory, [
    action.userId,
    date,
    'manual',
    false
  ]);
  const historyRNFitness = yield apply(activity, activity.getDataHistory, [
    action.userId,
    date,
    'rnfitness',
    false
  ]);

  let caloriesBurnTotal = 0;
  let distanceTotal = 0;
  let stepsTotal = 0;
  let updatedManual = null;
  let updatedRNFitness = null;
  let dateDiffManual = null;
  let dateDiffRNFitness = null;

  if (checkResponse(historyManual)) {
    caloriesBurnTotal += historyManual.caloriesBurn;
    distanceTotal += historyManual.distance;
    stepsTotal += historyManual.steps;
    updatedManual = historyManual.updated;
    dateDiffManual = historyManual.updated
      ? getDiffDays(new Date(historyManual.updated), new Date())
      : null;
  }
  if (checkResponse(historyRNFitness)) {
    caloriesBurnTotal += historyRNFitness.caloriesBurn;
    distanceTotal += historyRNFitness.distance;
    stepsTotal += historyRNFitness.steps;
    updatedRNFitness = historyRNFitness.updated;
    dateDiffRNFitness = historyRNFitness.updated
      ? getDiffDays(new Date(historyRNFitness.updated), new Date())
      : null;
  }

  let dateDiff = null;
  if (dateDiffManual && dateDiffRNFitness) {
    const compareDateResult = compareDate.compare(
      new Date(historyManual.updated),
      new Date(historyRNFitness.updated)
    );
    switch (compareDateResult) {
      case -1:
        dateDiff = dateDiffRNFitness;
        break;
      case 0:
        dateDiff = dateDiffManual;
        break;
      case 1:
        dateDiff = dateDiffManual;
        break;
    }
  } else if (dateDiffManual && !dateDiffRNFitness) {
    dateDiff = dateDiffManual;
  } else if (!dateDiffManual && dateDiffRNFitness) {
    dateDiff = dateDiffRNFitness;
  } else {
    console.log('dateDiff null');
  }

  const payloadDataActivity = {
    steps: stepsTotal,
    distance: distanceTotal,
    caloriesBurn: caloriesBurnTotal,
    lastUpdateText: generateLastUpdateText(
      dateDiff?.diffDays,
      dateDiff?.diffHrs,
      dateDiff?.diffMins,
      dateDiff === null
    )
  };

  yield put(
    medicalRecordAction.getLatestActivity().success(payloadDataActivity)
  );
}
export function* medicalRecordLatestData(action) {
  console.log('medicalRecordLatestData', action);
  yield put(medicalRecordAction.getLatestGlucose().call(action.userId));
  yield put(medicalRecordAction.getLatestHBA1C().call(action.userId));
  yield put(medicalRecordAction.getLatestWaterIntake().call(action.userId));
  yield put(medicalRecordAction.getLatestActivity().call(action.userId));
}

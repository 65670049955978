import * as foodHistoryTypes from './food-history.types';

const initialState = {
  isLoading: false,
  isLoadingChart: false,
  isLoadingPost: false,
  isLoadingDelete: false,
  total: 0,
  limit: 0,
  skip: 0,
  data: {},
  details: {},
  chart: {},
  message: null,
  error: null
};

export default function foodHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case foodHistoryTypes.RESET_FOOD_HISTORY:
      return initialState;
    case foodHistoryTypes.FETCH_FOOD_HISTORY_START:
      return {
        ...state,
        isLoading: true
      };
    case foodHistoryTypes.FETCH_FOOD_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    case foodHistoryTypes.FETCH_FOOD_HISTORY_FAIL:
      return {
        ...action.payload,
        ...state,
        isLoading: false
      };
    case foodHistoryTypes.FETCH_FOOD_HISTORY_DETAIL_START:
      return {
        ...state,
        isLoading: true
      };
    case foodHistoryTypes.FETCH_FOOD_HISTORY_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        details: {
          ...state.details,
          [action.id]: action.payload
        }
      };
    case foodHistoryTypes.FETCH_FOOD_HISTORY_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case foodHistoryTypes.FETCH_FOOD_HISTORY_CHART_START:
      return {
        ...state,
        isLoadingChart: true
      };
    case foodHistoryTypes.FETCH_FOOD_HISTORY_CHART_SUCCESS:
      return {
        ...state,
        isLoadingChart: false,
        chart: action.payload
      };
    case foodHistoryTypes.FETCH_FOOD_HISTORY_CHART_FAIL:
      return {
        ...state,
        isLoadingChart: false
      };
    case foodHistoryTypes.CREATE_FOOD_HISTORY_START:
      return {
        ...state,
        isLoadingPost: true
      };
    case foodHistoryTypes.CREATE_FOOD_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingPost: false,
        message: action.message
      };
    case foodHistoryTypes.CREATE_FOOD_HISTORY_FAIL:
      return {
        ...state,
        isLoadingPost: false,
        error: action.error
      };
    case foodHistoryTypes.UPDATE_FOOD_HISTORY_START:
      return {
        ...state,
        isLoadingPost: true
      };
    case foodHistoryTypes.UPDATE_FOOD_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingPost: false,
        message: action.message
      };
    case foodHistoryTypes.UPDATE_FOOD_HISTORY_FAIL:
      return {
        ...state,
        isLoadingPost: false
      };
    case foodHistoryTypes.REMOVE_FOOD_HISTORY_START:
      return {
        ...state,
        isLoadingDelete: true
      };
    case foodHistoryTypes.REMOVE_FOOD_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
        message: action.message
      };
    case foodHistoryTypes.REMOVE_FOOD_HISTORY_FAIL:
      return {
        ...state,
        isLoadingDelete: false
      };
    default:
      return state;
  }
}

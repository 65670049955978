import { takeEvery } from 'redux-saga/effects';
import {
  getSyncElvasense,
  updateSyncElvasense,
  updateSyncElvasenseEmailPassword,
  postSyncElvasense
} from './elvasense';
import * as types from '../../reducers/elvasense/elvasense.types';

export const elvasenseSagas = {
  getSyncElvasense: takeEvery(types.GET_SYNC_ELVASENSE, getSyncElvasense),
  updateSyncElvasense: takeEvery(
    types.UPDATE_SYNC_ELVASENSE,
    updateSyncElvasense
  ),
  updateSyncElvasenseEmailPassword: takeEvery(
    types.UPDATE_SYNC_ELVASENSE_EMAIL_PASSWORD,
    updateSyncElvasenseEmailPassword
  ),
  postSyncElvasense: takeEvery(types.POST_SYNC_ELVASENSE, postSyncElvasense)
};

import * as actionTypes from './elvasense.types';

const initialState = {
  isLoading: false,
  getSyncElvasense: {}
};

export default function getSyncElvasenseReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_SYNC_ELVASENSE_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.GET_SYNC_ELVASENSE_SUCCESS:
      return {
        ...state,
        getSyncElvasense: action.payload,
        isLoading: false
      };
    case actionTypes.GET_SYNC_ELVASENSE_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.UPDATE_SYNC_ELVASENSE_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.UPDATE_SYNC_ELVASENSE_SUCCESS:
      console.log('UPDATE SUKSES!');
      return {
        ...state,
        getSyncElvasense: action.payload,
        isLoading: false
      };
    case actionTypes.UPDATE_SYNC_ELVASENSE_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.UPDATE_SYNC_ELVASENSE_START_EMAIL_PASSWORD:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.UPDATE_SYNC_ELVASENSE_SUCCESS_EMAIL_PASSWORD:
      console.log('UPDATE SUKSES PASSWORD EMAIL!');
      return {
        ...state,
        getSyncElvasense: action.payload,
        isLoading: false,
        message: action.message
      };
    case actionTypes.UPDATE_SYNC_ELVASENSE_FAIL_EMAIL_PASSWORD:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case actionTypes.POST_SYNC_ELVASENSE_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.POST_SYNC_ELVASENSE_SUCCESS:
      console.log('POST SUKSES!');
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.POST_SYNC_ELVASENSE_FAIL:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}

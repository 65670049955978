import * as questionTypes from './question.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  loading: false
};

const updateQuestionStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const updateQuestionSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  });
};

const updateQuestionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const questionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case questionTypes.QUESTION_USER_START:
      return updateQuestionStart(state, action);
    case questionTypes.QUESTION_USER_SUCCESS:
      return updateQuestionSuccess(state, action);
    case questionTypes.QUESTION_USER_FAIL:
      return updateQuestionFail(state, action);
    default:
      return state;
  }
};

export default questionReducer;

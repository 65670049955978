import moment from 'moment';

export const dateUTC = date => {
  let tempDateUtc = moment.utc(date).format();
  let dateUtcString = tempDateUtc.split('T').join(' ');
  let dateUtc = dateUtcString.substring(0, dateUtcString.length - 1);
  return dateUtc;
};

const DateUTCString = (year, month, date) => {
  return new Date(Date.UTC(year, month - 1, date - 1, 17, 0, 0)).toISOString();
};

export const NowDateUTCString = () => {
  const date = new Date();
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() - 1, 17, 0, 0)
  ).toISOString();
};

export default DateUTCString;

export const convertToUTC = dateValue => {
  return moment(new Date(dateValue))
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .utc()
    .format();
};

import * as pinnedTypes from './pinned.types';

export const fetch = {
  call: params => ({
    type: pinnedTypes.FETCH_PINNED,
    params
  }),
  start: () => ({ type: pinnedTypes.FETCH_PINNED_START }),
  success: payload => ({
    type: pinnedTypes.FETCH_PINNED_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: pinnedTypes.FETCH_PINNED_FAIL,
    payload
  })
};

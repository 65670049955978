export const USER_TYPES_REQUEST = 'USER_TYPES_REQUEST';
export const USER_TYPES_REQUEST_START = 'USER_TYPES_REQUEST_START';
export const USER_TYPES_REQUEST_SUCCESS = 'USER_TYPES_REQUEST_SUCCESS';
export const USER_TYPES_REQUEST_FAIL = 'USER_TYPES_REQUEST_FAIL';
export const PHYSICAL_ACTIVITIES_REQUEST = 'PHYSICAL_ACTIVITIES_REQUEST';
export const PHYSICAL_ACTIVITIES_REQUEST_START =
  'PHYSICAL_ACTIVITIES_REQUEST_START';
export const PHYSICAL_ACTIVITIES_REQUEST_SUCCESS =
  'PHYSICAL_ACTIVITIES_REQUEST_SUCCESS';
export const PHYSICAL_ACTIVITIES_REQUEST_FAIL =
  'PHYSICAL_ACTIVITIES_REQUEST_FAIL';

import * as authTypes from 'store/reducers/auth/auth.types';
import { takeEvery } from 'redux-saga/effects';
import {
  logoutSaga,
  authUserSaga,
  authUserSocMedSaga,
  authCheckStateSaga,
  registerUser,
  removeAuthLocalStorageSaga
} from './auth';

export const authSagas = {
  watchAuthInitiateLogout: takeEvery(
    authTypes.AUTH_INITIATE_LOGOUT,
    logoutSaga
  ),
  watchAuthUser: takeEvery(authTypes.AUTH_USER, authUserSaga),
  watchAuthUserSocMed: takeEvery(authTypes.AUTH_SOCMED, authUserSocMedSaga),
  wathAuthCheckState: takeEvery(authTypes.AUTH_CHECK_STATE, authCheckStateSaga),
  wathAuthRegister: takeEvery(authTypes.AUTH_REGISTER, registerUser),
  wathAuthRemoveLocalStorage: takeEvery(
    authTypes.AUTH_REMOVE_LOCAL_STORAGE,
    removeAuthLocalStorageSaga
  )
};

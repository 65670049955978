import { pathOr } from 'ramda';

export const idUser = state => state.auth && state.auth.userId;
export const isLoading = state => state.medicalReminder.isLoading;
export const isLoadingDetail = state => state.medicalReminder.isLoadingDetail;
export const isLoadingRemove = state => state.medicalReminder.isLoadingRemove;
export const isLoadingConfirm = state => state.medicalReminder.isLoadingConfirm;
export const lists = state => state.medicalReminder.lists;
export const all = state => state.medicalReminder;
export const listsOfTaken = state => state.medicalReminder.listsOfTaken;
export const listsByFilter = (state, date) => {
  const data = pathOr([], ['medicalReminder', 'listsByFilter', date], state);
  return data;
};
export const detail = (state, id) =>
  pathOr(null, ['medicalReminder', 'details', id], state);
export const detailChart = state =>
  state.medicalReminder.detailChart.reduce(
    (acc, item) => ({
      taken: acc.taken + parseInt(item.taken, 10),
      skipped: acc.skipped + parseInt(item.skipped, 10),
      noAction:
        acc.totalRecord -
        acc.taken -
        acc.skipped +
        (parseInt(item.total, 10) -
          (parseInt(item.taken, 10) + parseInt(item.skipped, 10))),
      totalRecord: acc.totalRecord + parseInt(item.total, 10)
    }),
    {
      taken: 0,
      skipped: 0,
      noAction: 0,
      totalRecord: 0
    }
  );
export const selectedScheduleId = state =>
  pathOr(null, ['medicalReminder', 'selectedMedicineScheduleId'], state);

import Service from './Service';

export default class MedicalTakenService extends Service {
  constructor() {
    super('medicine-taken', true);
  }

  /* === CRUD to API === */
  async postData(data) {
    const response = await this.create(data);
    return response;
  }

  async patchData(id, data) {
    console.log('patchData', id, data);
    const response = await this.patch({
      id,
      ...data
    });
    return response;
  }

  async fetchAll(params) {
    const response = await this.find(params);
    return response;
  }
}

export const GET_ARTICLE_CATEGORY = 'GET_ARTICLE_CATEGORY';
export const GET_ARTICLE_CATEGORY_START = 'GET_ARTICLE_CATEGORY_START';
export const GET_ARTICLE_CATEGORY_SUCCESS = 'GET_ARTICLE_CATEGORY_SUCCESS';
export const GET_ARTICLE_CATEGORY_FAIL = 'GET_ARTICLE_CATEGORY_FAIL';

export const FETCH_ARTICLE_CATEGORY = 'FETCH_ARTICLE_CATEGORY';
export const FETCH_ARTICLE_CATEGORY_START = 'FETCH_ARTICLE_CATEGORY_START';
export const FETCH_ARTICLE_CATEGORY_SUCCESS = 'FETCH_ARTICLE_CATEGORY_SUCCESS';
export const FETCH_ARTICLE_CATEGORY_FAIL = 'FETCH_ARTICLE_CATEGORY_FAIL';

export const ARTICLE_CATEGORY_DISMISS_ERROR = 'ARTICLE_CATEGORY_DISMISS_ERROR';

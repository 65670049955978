export const GET_ARTICLE = 'GET_ARTICLE';
export const GET_ARTICLE_START = 'GET_ARTICLE_START';
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_FAIL = 'GET_ARTICLE_FAIL';

export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const FETCH_ARTICLES_START = 'FETCH_ARTICLES_START';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_CONCAT_SUCCESS = 'FETCH_ARTICLES_CONCAT_SUCCESS';
export const FETCH_ARTICLES_FAIL = 'FETCH_ARTICLES_FAIL';

export const ARTICLE_DISMISS_ERROR = 'ARTICLE_DISMISS_ERROR';

export const VIEW_ARTICLE = 'VIEW_ARTICLE';
export const VIEW_ARTICLE_START = 'VIEW_ARTICLE_START';
export const VIEW_ARTICLE_SUCCESS = 'VIEW_ARTICLE_SUCCESS';
export const VIEW_ARTICLE_FAIL = 'VIEW_ARTICLE_FAIL';

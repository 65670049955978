import * as articleCategoryTypes from './article-category.types';
import { updateObject } from '../../../utils';

const INITIAL_STATE = {
  articleCategories: {},
  articleCategory: {},
  error: null,
  loading: false
};

const fetchArticleCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchArticleCategorySuccess = (state, action) => {
  return updateObject(state, {
    articleCategories: action.articleCategories,
    articleCategory: {},
    loading: false
  });
};

const fetchArticleCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getArticleCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getArticleCategorySuccess = (state, action) => {
  return updateObject(state, {
    articleCategory: action.articleCategory,
    loading: false
  });
};

const getArticleCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const articleCategoryDismissError = state => {
  return updateObject(state, {
    error: null
  });
};

const articleCategoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case articleCategoryTypes.FETCH_ARTICLE_CATEGORY_START:
      return fetchArticleCategoryStart(state, action);
    case articleCategoryTypes.FETCH_ARTICLE_CATEGORY_SUCCESS:
      return fetchArticleCategorySuccess(state, action);
    case articleCategoryTypes.FETCH_ARTICLE_CATEGORY_FAIL:
      return fetchArticleCategoryFail(state, action);
    case articleCategoryTypes.GET_ARTICLE_CATEGORY_START:
      return getArticleCategoryStart(state, action);
    case articleCategoryTypes.GET_ARTICLE_CATEGORY_SUCCESS:
      return getArticleCategorySuccess(state, action);
    case articleCategoryTypes.GET_ARTICLE_CATEGORY_FAIL:
      return getArticleCategoryFail(state, action);
    case articleCategoryTypes.ARTICLE_CATEGORY_DISMISS_ERROR:
      return articleCategoryDismissError(state);

    default:
      return state;
  }
};

export default articleCategoryReducer;

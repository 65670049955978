export const CREATE_MEDICAL_REMINDER = 'CREATE_MEDICAL_REMINDER';
export const CREATE_MEDICAL_REMINDER_START = 'CREATE_MEDICAL_REMINDER_START';
export const CREATE_MEDICAL_REMINDER_SUCCESS =
  'CREATE_MEDICAL_REMINDER_SUCCESS';
export const CREATE_MEDICAL_REMINDER_FAIL = 'CREATE_MEDICAL_REMINDER_FAIL';

export const UPDATE_MEDICAL_REMINDER = 'UPDATE_MEDICAL_REMINDER';
export const UPDATE_MEDICAL_REMINDER_START = 'UPDATE_MEDICAL_REMINDER_START';
export const UPDATE_MEDICAL_REMINDER_SUCCESS =
  'UPDATE_MEDICAL_REMINDER_SUCCESS';
export const UPDATE_MEDICAL_REMINDER_FAIL = 'UPDATE_MEDICAL_REMINDER_FAIL';

export const REMOVE_MEDICAL_REMINDER = 'REMOVE_MEDICAL_REMINDER';
export const REMOVE_MEDICAL_REMINDER_START = 'REMOVE_MEDICAL_REMINDER_START';
export const REMOVE_MEDICAL_REMINDER_SUCCESS =
  'REMOVE_MEDICAL_REMINDER_SUCCESS';
export const REMOVE_MEDICAL_REMINDER_FAIL = 'REMOVE_MEDICAL_REMINDER_FAIL';

export const FETCH_BY_FILTER_MEDICAL_REMINDER =
  'FETCH_BY_FILTER_MEDICAL_REMINDER';
export const FETCH_BY_FILTER_MEDICAL_REMINDER_START =
  'FETCH_BY_FILTER_MEDICAL_REMINDER_START';
export const FETCH_BY_FILTER_MEDICAL_REMINDER_SUCCESS =
  'FETCH_BY_FILTER_MEDICAL_REMINDER_SUCCESS';
export const FETCH_BY_FILTER_MEDICAL_REMINDER_FAIL =
  'FETCH_BY_FILTER_MEDICAL_REMINDER_FAIL';

export const FETCH_DETAIL_MEDICAL_REMINDER = 'FETCH_DETAIL_MEDICAL_REMINDER';
export const FETCH_DETAIL_MEDICAL_REMINDER_START =
  'FETCH_DETAIL_MEDICAL_REMINDER_START';
export const FETCH_DETAIL_MEDICAL_REMINDER_SUCCESS =
  'FETCH_DETAIL_MEDICAL_REMINDER_SUCCESS';
export const FETCH_DETAIL_MEDICAL_REMINDER_FAIL =
  'FETCH_DETAIL_MEDICAL_REMINDER_FAIL';

export const FIND_DETAIL_MEDICAL_REMINDER = 'FIND_DETAIL_MEDICAL_REMINDER';
export const FIND_DETAIL_MEDICAL_REMINDER_START =
  'FIND_DETAIL_MEDICAL_REMINDER_START';
export const FIND_DETAIL_MEDICAL_REMINDER_SUCCESS =
  'FIND_DETAIL_MEDICAL_REMINDER_SUCCESS';
export const FIND_DETAIL_MEDICAL_REMINDER_FAIL =
  'FIND_DETAIL_MEDICAL_REMINDER_FAIL';

export const FETCH_CHART_DETAIL_MEDICAL_REMINDER =
  'FETCH_CHART_DETAIL_MEDICAL_REMINDER';
export const FETCH_CHART_DETAIL_MEDICAL_REMINDER_START =
  'FETCH_CHART_DETAIL_MEDICAL_REMINDER_START';
export const FETCH_CHART_DETAIL_MEDICAL_REMINDER_SUCCESS =
  'FETCH_CHART_DETAIL_MEDICAL_REMINDER_SUCCESS';
export const FETCH_CHART_DETAIL_MEDICAL_REMINDER_FAIL =
  'FETCH_CHART_DETAIL_MEDICAL_REMINDER_FAIL';

export const CONFIRM_MEDICAL_REMINDER = 'CONFIRM_MEDICAL_REMINDER';
export const CONFIRM_MEDICAL_REMINDER_START = 'CONFIRM_MEDICAL_REMINDER_START';
export const CONFIRM_MEDICAL_REMINDER_SUCCESS =
  'CONFIRM_MEDICAL_REMINDER_SUCCESS';
export const CONFIRM_MEDICAL_REMINDER_FAIL = 'CONFIRM_MEDICAL_REMINDER_FAIL';

export const SYNC_MEDICAL_REMINDER_TAKEN = 'SYNC_MEDICAL_REMINDER_TAKEN';
export const SYNC_MEDICAL_REMINDER_TAKEN_START =
  'SYNC_MEDICAL_REMINDER_TAKEN_START';
export const SYNC_MEDICAL_REMINDER_SUCCESS = 'SYNC_MEDICAL_REMINDER_SUCCESS';
export const SYNC_MEDICAL_REMINDER_FAIL = 'SYNC_MEDICAL_REMINDER_FAIL';
export const SYNC_MEDICAL_TAKEN_SUCCESS = 'SYNC_MEDICAL_TAKEN_SUCCESS';
export const SYNC_MEDICAL_TAKEN_FAIL = 'SYNC_MEDICAL_TAKEN_FAIL';

export const FIND_BY_FILTER_MEDICAL_REMINDER =
  'FIND_BY_FILTER_MEDICAL_REMINDER';
export const FIND_BY_FILTER_MEDICAL_REMINDER_START =
  'FIND_BY_FILTER_MEDICAL_REMINDER_START';
export const FIND_BY_FILTER_MEDICAL_REMINDER_SUCCESS =
  'FIND_BY_FILTER_MEDICAL_REMINDER_SUCCESS';
export const FIND_BY_FILTER_MEDICAL_REMINDER_FAIL =
  'FIND_BY_FILTER_MEDICAL_REMINDER_FAIL';

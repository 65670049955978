import { put } from 'redux-saga/effects';
import * as actions from '../../reducers/article-category/article-category.actions';
import articleCategoryService from '../../../services/ArticleCategory';
export function* fetchArticleCategorySaga(action) {
  yield put(actions.fetchArticleCategoryStart());
  const service = yield new articleCategoryService();
  const articleCategory = yield service.find(action.params);
  if (articleCategory.data) {
    yield put(actions.fetchArticleCategorySuccess(articleCategory.data));
  } else {
    yield put(actions.fetchArticleCategoryFail(articleCategory.error));
  }
}

export function* getArticleCategorySaga(action) {
  yield put(actions.getArticleCategoryStart());
  const service = yield new articleCategoryService();
  const articleCategory = yield service.get(action.id);
  if (articleCategory.data) {
    yield put(actions.getArticleCategorySuccess(articleCategory.data));
  } else {
    yield put(actions.getArticleCategoryFail(articleCategory.error));
  }
}

import * as articleTypes from './article.types';
import { updateObject } from '../../../utils';

const INITIAL_STATE = {
  articles: [],
  article: {},
  error: null,
  loading: false,
  loadingDetail: false
};

/**
 * FETCH ARTICLES
 */
const fetchArticlesStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchArticlesSuccess = (state, action) =>
  updateObject(state, {
    articles: action.articles.data,
    loading: false
  });
const fetchArticlesConcatSuccess = (state, action) =>
  updateObject(state, {
    articles: state.articles.concat(action.articles.data),
    loading: false
  });
const fetchArticlesFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * GET ARTICLE
 */
const getArticleStart = (state, action) =>
  updateObject(state, { loadingDetail: true });
const getArticleSuccess = (state, action) =>
  updateObject(state, {
    article: {
      ...state.article,
      ...action.article,
      [action.id]: action.article
    },
    loadingDetail: false
  });
const getArticleFail = (state, action) =>
  updateObject(state, { loadingDetail: false, error: action.error });

/**
 * UTILS
 */
const articleDismissError = state =>
  updateObject(state, {
    error: null
  });

const articleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case articleTypes.FETCH_ARTICLES_START:
      return fetchArticlesStart(state, action);
    case articleTypes.FETCH_ARTICLES_SUCCESS:
      return fetchArticlesSuccess(state, action);
    case articleTypes.FETCH_ARTICLES_CONCAT_SUCCESS:
      return fetchArticlesConcatSuccess(state, action);
    case articleTypes.FETCH_ARTICLES_FAIL:
      return fetchArticlesFail(state, action);

    case articleTypes.GET_ARTICLE_START:
      return getArticleStart(state, action);
    case articleTypes.GET_ARTICLE_SUCCESS:
      return getArticleSuccess(state, action);
    case articleTypes.GET_ARTICLE_FAIL:
      return getArticleFail(state, action);

    case articleTypes.ARTICLE_DISMISS_ERROR:
      return articleDismissError(state);
    default:
      return state;
  }
};

export default articleReducer;

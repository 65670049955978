import * as userCategory from './user-category.types';

export const userTypes = () => ({
  call: () => ({ type: userCategory.USER_TYPES_REQUEST }),
  start: () => ({
    type: userCategory.USER_TYPES_REQUEST_START
  }),
  success: payload => ({
    type: userCategory.USER_TYPES_REQUEST_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: userCategory.USER_TYPES_REQUEST_FAIL,
    payload
  })
});

export const physicalActivities = () => ({
  call: () => ({ type: userCategory.PHYSICAL_ACTIVITIES_REQUEST }),
  start: () => ({
    type: userCategory.PHYSICAL_ACTIVITIES_REQUEST_START
  }),
  success: payload => ({
    type: userCategory.PHYSICAL_ACTIVITIES_REQUEST_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: userCategory.PHYSICAL_ACTIVITIES_REQUEST_FAIL,
    payload
  })
});

export const GET_USER = 'GET_USERS';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPLOAD_USER_PICTURE = 'UPLOAD_USER_PICTURE';
export const UPLOAD_USER_PICTURE_START = 'UPLOAD_USER_PICTURE_START';
export const UPLOAD_USER_PICTURE_SUCCESS = 'UPLOAD_USER_PICTURE_SUCCESS';
export const UPLOAD_USER_PICTURE_FAIL = 'UPLOAD_USER_PICTURE_FAIL';

export const USER_DISMISS_ERROR = 'USER_DISMISS_ERROR';

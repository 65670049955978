import * as actionTypes from './food.types';

/**
 * FETCH FOODS
 */
export const fetchFoods = (params, userId) => ({
  type: actionTypes.FETCH_FOODS,
  params,
  userId
});
export const fetchFoodsStart = () => ({
  type: actionTypes.FETCH_FOODS_START
});
export const fetchFoodsSuccess = ({ lists, message }) => ({
  type: actionTypes.FETCH_FOODS_SUCCESS,
  lists,
  message
});
export const fetchFoodsConcat = ({ lists, message }) => ({
  type: actionTypes.FETCH_FOODS_SUCCESS,
  lists,
  message
});
export const fetchFoodsFail = error => ({
  type: actionTypes.FETCH_FOODS_FAIL,
  error
});

/**
 * FETCH FOODS DETAILS
 */
export const fetchFoodsDetail = id => ({
  type: actionTypes.FETCH_FOODS_DETAIL,
  id
});
export const fetchFoodsDetailStart = () => ({
  type: actionTypes.FETCH_FOODS_DETAIL_START
});
export const fetchFoodsDetailSuccess = ({ details, message }) => ({
  type: actionTypes.FETCH_FOODS_DETAIL_SUCCESS,
  details,
  message
});
export const fetchFoodsDetailFail = error => ({
  type: actionTypes.FETCH_FOODS_DETAIL_FAIL,
  error
});

/**
 * CREATE FOODS
 */
export const createFoods = (body, date) => ({
  type: actionTypes.CREATE_FOODS,
  body,
  date
});
export const createFoodsStart = () => ({
  type: actionTypes.CREATE_FOODS_START
});
export const createFoodsSuccess = ({ message }) => ({
  type: actionTypes.CREATE_FOODS_SUCCESS,
  message
});
export const createFoodsFail = error => ({
  type: actionTypes.CREATE_FOODS_FAIL,
  error
});

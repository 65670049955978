import * as actionTypes from './goals.types';
export const fetchGoals = {
  call: userId => ({ type: actionTypes.FETCH_GOALS_MEDICAL_RECORD, userId }),
  start: () => ({ type: actionTypes.FETCH_GOALS_MEDICAL_RECORD_START }),
  success: payload => ({
    type: actionTypes.FETCH_GOALS_MEDICAL_RECORD_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: actionTypes.FETCH_GOALS_MEDICAL_RECORD_FAIL,
    payload
  })
};

export const goalTypes = {
  water: 'water',
  steps: 'steps',
  caloriesDaily: 'caloriesDaily'
};

export const setUserTarget = {
  call: (target, goalType, otherType) => ({
    type: actionTypes.SET_USER_TARGET,
    target,
    goalType,
    otherType
  }),
  start: () => ({ type: actionTypes.SET_USER_TARGET_START }),
  success: payload => ({ type: actionTypes.SET_USER_TARGET_SUCCESS, payload }),
  fail: payload => ({ type: actionTypes.SET_USER_TARGET_FAIL, payload })
};

export const resetGoalsForLogout = () => ({
  type: actionTypes.RESET_GOALS_REDUCER
});

import Service from './Service';
import formatDate from '../utils/formatDate';
import { utcOffset } from '../utils/dateUtils';
import {
  getIndex,
  parseToChartObject,
  parseToChartSeriesObject,
  toFilteredData
} from '../utils/chartFilterUtils';

export default class ActivityService extends Service {
  constructor() {
    super('activity', true);
  }

  // mengikuti mobile cuman untuk SQLLitenya dihilangkan
  // Fetch data detail
  async fetchSingleData(userId, date, source) {
    let response = await this.find({
      userId,
      date: date,
      source: source
    });
    return response.data;
  }

  // Get data detail
  async getSingleData(id) {
    let response = await this.get(id);
    return response.data;
  }

  // Fetch last data
  async fetchLatestData(userId, date, source) {
    let response = await this.find({
      'date[$lte]': date,
      '$sort[date]': -1,
      userId,
      source: source,
      $limit: -1
    });
    // console.log('URL', this.url);
    let latestData = [];
    if (response && response.data && response.data.length > 0) {
      latestData.push(response.data[0]);
    }
    // console.log('FETCH SINGLE RESPONSE', latestData);
    return response.data;
  }

  // Fetch data detail
  async fetchSingleData(userId, date, source) {
    let response = await this.find({
      date: date,
      userId: userId,
      source: source,
      $limit: -1
    });
    return response.data;
    return response.data;
  }

  // Fetch last data
  async fetchLatestData(userId, date, source) {
    const params = {
      userId,
      'date[$lte]': date,
      '$sort[date]': -1,
      source: source,
      $limit: -1
    };
    let response = await this.find(params);
    // console.log('URL', this.url);
    let latestData = [];
    if (response && response.data && response.data.length > 0) {
      latestData.push(response.data[0]);
    }
    // console.log('FETCH SINGLE RESPONSE', latestData);
    return response.data;
  }

  // Fetch filter data
  async fetchFilterData(userId, date, filterTime) {
    const params = {
      userId,
      date,
      typeFilter: filterTime,
      offsetDate: utcOffset,
      $limit: -1
    };
    let response = await this.find(params);
    return response.data;
  }

  // // Post data to api
  async postData(data) {
    let res = await this.create(data);
    return res;
  }

  // Patch data to api
  async updateData(id, params) {
    // console.log(params);
    delete params?.isVerified;
    const res = await this.patch({ id, ...params });
    return res;
  }

  // Delete data to api
  async deleteData({ id, customUrl }) {
    let res = await this.delete(id, customUrl);
    return res;
  }

  // get data history
  async getDataHistory(userId, date, source, forHistory = true) {
    let res = [];
    if (forHistory) {
      res = await this.fetchSingleData(userId, date, source);
    } else {
      res = await this.fetchLatestData(userId, date, source);
    }
    if (res) {
      if (res.length) {
        return res[0];
      } else {
        return res;
      }
    } else {
      return {};
    }
  }

  // Get steps filterd
  async getFilter(userId, date, filter, type) {
    let fields;
    let datas = [];
    switch (filter) {
      case 'daily':
        fields = 'date, totalDays, totalRecord, totalValue';
        break;
      case 'weekly':
        fields =
          'startOfWeek, endOfWeek, week, year, totalDays, totalRecord, totalValue';
        break;
      case 'monthly':
        fields = 'date, month, year, totalDays, totalRecord, totalValue';
        break;
      default:
        fields = 'date, totalDays, totalRecord, totalValue';
        break;
    }
    const res = await this.fetchFilterData(userId, date, filter);
    if (res && res.length) {
      const { currentIndex, zeroIndex } = getIndex(res, filter);
      datas = toFilteredData(res, filter).map(
        ({ totalSteps, totalDistance, totalCaloriesBurn, ...restItems }) => ({
          ...restItems,
          totalValue:
            type === 'steps'
              ? totalSteps
              : type === 'distance'
              ? totalDistance
              : totalCaloriesBurn
        })
      );
      return {
        result: parseToChartObject(datas, filter, 'activity'),
        currentIndex,
        zeroIndex
      };
    } else {
      const { currentIndex, zeroIndex } = getIndex([], filter);
      return {
        result: parseToChartObject([], filter, 'activity'),
        currentIndex,
        zeroIndex
      };
    }
  }

  // Patch data
  async editNote(id, note) {
    let data = {
      note
    };
    return await this.updateData(id, data).then(async result => {
      if (result.success) {
        return result;
      } else {
        return result;
      }
    });
  }
}

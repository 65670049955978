import Service from './Service';

export default class Auth extends Service {
  constructor(usingToken = false) {
    super('authentication', usingToken);
  }

  async create(data) {
    return await super.create(data);
  }

  async delete() {
    console.log('AuthServicdeletee');
    let refreshToken = localStorage.getItem('refreshToken');
    let url =
      process.env.REACT_APP_HOST +
      '/refresh-tokens?refreshToken=' +
      refreshToken;
    return await super.delete(null, url);
  }
}

import {
  changePasswordSaga,
  resetPasswordSaga,
  verificationEmailSaga
} from './auth-management';
import * as authManagementTypes from '../../reducers/authManagement/authManagement.types';
import { takeEvery } from 'redux-saga/effects';

export const authManagementSagas = {
  watchResetPassword: takeEvery(
    authManagementTypes.RESET_PASSWORD,
    resetPasswordSaga
  ),
  watchChangePassword: takeEvery(
    authManagementTypes.CHANGE_PASSWORD,
    changePasswordSaga
  ),
  watchVerificationEmail: takeEvery(
    authManagementTypes.VERIFICATION_EMAIL,
    verificationEmailSaga
  )
};

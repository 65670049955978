import Service from './Service';

export default class Banner extends Service {
  constructor() {
    super('banner', true);
  }

  async find(params) {
    const response = await super.find(params);
    return super.generateDefaultResponse(response);
  }

  async get(id) {
    const response = await super.get(id);
    return super.generateDefaultResponse(response);
  }
}

export const isLoadingLogin = state => state.auth.isLoadingLogin;
export const isLoadingLogout = state => state.auth.isLoadingLogout;
export const errorLogin = state => state.auth.errorLogin;
export const errorLogout = state => state.auth.errorLogout;
export const accessToken = state => state.auth.accessToken;
export const user = state => state.auth.user;
export const isVerified = state =>
  state.auth.user && state.auth.user.isVerified;
export const idUser = state => state.auth.user && state.auth.user.id;
export const height = state => state.auth.user && state.auth.user.height;
export const weight = state => state.auth.user && state.auth.user.weight;
export const gender = state => state.auth.user && state.auth.user.gender;
export const refreshToken = state => state.auth.refreshToken;
export const isLoggedIn = state => state.auth.isLoggedIn;

/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import './assets/fonts/poppins-regular.ttf';
import './index.css';

import App from './App';
import store from './store/Store.js';
// import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';

// ReactDOM.render(
//   <Provider store={store}>
//     <GoogleOAuthProvider
//       clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
//     >
//       <App />
//     </GoogleOAuthProvider>
//   </Provider>,
//   document.getElementById('root')
// );
// serviceWorker.register();

// kodingan diatas berubah menjadi dibawah,
// karena pada Rabu, 3 April 2024,
// versi react dinaikkan dari "16.12.0" menjadi "^18.2.0"
// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
    >
      <App />
    </GoogleOAuthProvider>
  </Provider>
);

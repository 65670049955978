import * as medicalRecordActionTypes from './medical-record.types';

const initialState = {
  isLoadingGlucose: false,
  dataGlucose: {
    value: null,
    date: null,
    dayDiff: 0,
    hourDiff: 0,
    minDiff: 0,
    lastUpdateText: ''
  },
  isLoadingHBA1C: false,
  dataHBA1C: {
    value: null,
    date: null,
    dayDiff: 0,
    hourDiff: 0,
    minDiff: 0,
    lastUpdateText: ''
  },
  isLoadingWaterIntake: false,
  dataWaterIntake: {
    value: null,
    date: null,
    dayDiff: 0,
    hourDiff: 0,
    minDiff: 0,
    lastUpdateText: ''
  },
  isLoadingActivity: false,
  isLoadingActivityUpdate: false,
  dataActivity: {
    steps: 0,
    distance: 0,
    caloriesBurn: 0,
    lastUpdateText: ''
  },
  activityManual: {
    steps: 0,
    distance: 0,
    caloriesBurn: 0
  },
  activityRNFitness: {
    steps: 0,
    distance: 0,
    caloriesBurn: 0
  },
  lastAction: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case medicalRecordActionTypes.SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.payload
      };
    case medicalRecordActionTypes.GET_LATEST_GLUCOSE_REQUEST_START:
      return {
        ...state,
        isLoadingGlucose: true
      };
    case medicalRecordActionTypes.GET_LATEST_GLUCOSE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingGlucose: false,
        dataGlucose: action.payload
      };
    case medicalRecordActionTypes.GET_LATEST_HBA1C_REQUEST_START:
      return {
        ...state,
        isLoadingHBA1C: true
      };
    case medicalRecordActionTypes.GET_LATEST_HBA1C_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingHBA1C: false,
        dataHBA1C: action.payload
      };
    case medicalRecordActionTypes.GET_LATEST_WATER_INTAKE_REQUEST_START:
      return {
        ...state,
        isLoadingWaterIntake: true
      };
    case medicalRecordActionTypes.GET_LATEST_WATER_INTAKE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingWaterIntake: false,
        dataWaterIntake: action.payload
      };
    case medicalRecordActionTypes.GET_LATEST_ACTIVITY_REQUEST_START:
      return {
        ...state,
        isLoadingActivity: true
      };
    case medicalRecordActionTypes.GET_LATEST_ACTIVITY_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingActivity: false,
        isLoadingActivityUpdate: false,
        dataActivity: action.payload
      };
    case medicalRecordActionTypes.UPDATE_LATEST_ACTIVITY_REQUEST_START:
      return {
        ...state,
        isLoadingActivityUpdate: true
      };
    case medicalRecordActionTypes.UPDATE_LATEST_ACTIVITY_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingActivityUpdate: false
        // dataActivity: action.payload,
      };
    default:
      return state;
  }
}

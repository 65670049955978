export const RESET_GOALS_REDUCER = 'RESET_GOALS_REDUCER';

export const FETCH_GOALS_MEDICAL_RECORD = 'FETCH_GOALS_MEDICAL_RECORD';
export const FETCH_GOALS_MEDICAL_RECORD_START =
  'FETCH_GOALS_MEDICAL_RECORD_START';
export const FETCH_GOALS_MEDICAL_RECORD_SUCCESS =
  'FETCH_GOALS_MEDICAL_RECORD_SUCCESS';
export const FETCH_GOALS_MEDICAL_RECORD_FAIL =
  'FETCH_GOALS_MEDICAL_RECORD_FAIL';

export const SET_USER_TARGET = 'SET_USER_TARGET';
export const SET_USER_TARGET_START = 'SET_USER_TARGET_START';
export const SET_USER_TARGET_SUCCESS = 'SET_USER_TARGET_SUCCESS';
export const SET_USER_TARGET_FAIL = 'SET_WATER_TARGET_FAIL';

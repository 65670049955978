import * as actionTypes from './food.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  loading: false,
  loadingDetail: false,
  lists: [],
  details: {},
  error: null,
  message: null
};

/**
 * FETCH FOODS
 */
const fetchFoodsStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchFoodsSuccess = (state, action) =>
  updateObject(state, { loading: false, error: null, ...action });
const fetchFoodsConcat = (state, action) =>
  updateObject(state, {
    loading: false,
    error: null,
    ...action,
    lists: state.lists.concat(action.lists)
  });
const fetchFoodsFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * FETCH FOODS DETAILS
 */
const fetchFoodsDetailStart = (state, action) =>
  updateObject(state, { loadingDetail: true });
const fetchFoodsDetailSuccess = (state, action) =>
  updateObject(state, {
    loadingDetail: false,
    error: null,
    ...action
  });
const fetchFoodsDetailFail = (state, action) =>
  updateObject(state, { loadingDetail: false, error: action.error });

/**
 * CREATE FOODS
 */
const createFoodsStart = (state, action) =>
  updateObject(state, { loading: true });
const createFoodsSuccess = (state, action) =>
  updateObject(state, { loading: false, error: null });
const createFoodsFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UTILITY
 */
const foodsReset = state => {
  return updateObject(state, {
    lists: [],
    error: null,
    message: null
  });
};
const foodsDismissError = state => {
  return updateObject(state, {
    error: null,
    message: null
  });
};

export default function foodsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.FETCH_FOODS_START:
      return fetchFoodsStart(state, action);
    case actionTypes.FETCH_FOODS_SUCCESS:
      return fetchFoodsSuccess(state, action);
    case actionTypes.FETCH_FOODS_SUCCESS:
      return fetchFoodsConcat(state, action);
    case actionTypes.FETCH_FOODS_FAIL:
      return fetchFoodsFail(state, action);

    case actionTypes.FETCH_FOODS_DETAIL_START:
      return fetchFoodsDetailStart(state, action);
    case actionTypes.FETCH_FOODS_DETAIL_SUCCESS:
      return fetchFoodsDetailSuccess(state, action);
    case actionTypes.FETCH_FOODS_DETAIL_FAIL:
      return fetchFoodsDetailFail(state, action);

    case actionTypes.CREATE_FOODS_START:
      return createFoodsStart(state, action);
    case actionTypes.CREATE_FOODS_SUCCESS:
      return createFoodsSuccess(state, action);
    case actionTypes.CREATE_FOODS_FAIL:
      return createFoodsFail(state, action);

    case actionTypes.RESET_FOODS:
      return foodsReset(state, action);
    case actionTypes.FOODS_DISMISS_ERROR:
      return foodsDismissError(state, action);

    default:
      return state;
  }
}

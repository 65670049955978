import * as actionTypes from './medical-reminder.types';

export const createMedicalReminder = {
  call: data => ({ type: actionTypes.CREATE_MEDICAL_REMINDER, data }),
  start: () => ({ type: actionTypes.CREATE_MEDICAL_REMINDER_START }),
  success: () => ({
    type: actionTypes.CREATE_MEDICAL_REMINDER_SUCCESS
  }),
  fail: payload => ({
    type: actionTypes.CREATE_MEDICAL_REMINDER_FAIL,
    payload
  })
};

export const updateMedicalReminder = {
  call: (id, data, initialSchedules) => ({
    type: actionTypes.UPDATE_MEDICAL_REMINDER,
    id,
    data,
    initialSchedules
  }),
  start: () => ({ type: actionTypes.UPDATE_MEDICAL_REMINDER_START }),
  success: () => ({
    type: actionTypes.UPDATE_MEDICAL_REMINDER_SUCCESS
  }),
  fail: payload => ({
    type: actionTypes.UPDATE_MEDICAL_REMINDER_FAIL,
    payload
  })
};

export const removeMedicalReminder = {
  call: (id, params) => ({
    type: actionTypes.REMOVE_MEDICAL_REMINDER,
    id,
    params
  }),
  start: () => ({ type: actionTypes.REMOVE_MEDICAL_REMINDER_START }),
  success: () => ({
    type: actionTypes.REMOVE_MEDICAL_REMINDER_SUCCESS
  }),
  fail: payload => ({
    type: actionTypes.REMOVE_MEDICAL_REMINDER_FAIL,
    payload
  })
};

export const fetchByFilterMedicalReminder = {
  // call: ({ params, userId }) => ({
  call: params => ({
    type: actionTypes.FETCH_BY_FILTER_MEDICAL_REMINDER,
    params
  }),
  start: () => ({ type: actionTypes.FETCH_BY_FILTER_MEDICAL_REMINDER_START }),
  success: (date, data) => ({
    type: actionTypes.FETCH_BY_FILTER_MEDICAL_REMINDER_SUCCESS,
    date,
    data
  }),
  fail: payload => ({
    type: actionTypes.FETCH_BY_FILTER_MEDICAL_REMINDER_FAIL,
    payload
  })
};

export const findByFilterMedicalReminder = {
  call: date => ({
    type: actionTypes.FIND_BY_FILTER_MEDICAL_REMINDER,
    date
  }),
  start: () => ({ type: actionTypes.FIND_BY_FILTER_MEDICAL_REMINDER_START }),
  success: (date, data) => ({
    type: actionTypes.FIND_BY_FILTER_MEDICAL_REMINDER_SUCCESS,
    date,
    data
  }),
  fail: payload => ({
    type: actionTypes.FIND_BY_FILTER_MEDICAL_REMINDER_FAIL,
    payload
  })
};

export const fetchDetailMedicalReminder = {
  call: id => ({
    type: actionTypes.FETCH_DETAIL_MEDICAL_REMINDER,
    id
  }),
  start: () => ({ type: actionTypes.FETCH_DETAIL_MEDICAL_REMINDER_START }),
  success: (id, data) => ({
    type: actionTypes.FETCH_DETAIL_MEDICAL_REMINDER_SUCCESS,
    id,
    data
  }),
  fail: payload => ({
    type: actionTypes.FETCH_DETAIL_MEDICAL_REMINDER_FAIL,
    payload
  })
};

export const findDetailMedicalReminder = {
  call: id => ({
    type: actionTypes.FIND_DETAIL_MEDICAL_REMINDER,
    id
  }),
  start: () => ({ type: actionTypes.FIND_DETAIL_MEDICAL_REMINDER_START }),
  success: (id, data) => ({
    type: actionTypes.FIND_DETAIL_MEDICAL_REMINDER_SUCCESS,
    id,
    data
  }),
  fail: payload => ({
    type: actionTypes.FIND_DETAIL_MEDICAL_REMINDER_FAIL,
    payload
  })
};

export const confirmMedicalReminder = {
  call: (id, data) => ({
    type: actionTypes.CONFIRM_MEDICAL_REMINDER,
    id,
    data
  }),
  start: payload => ({
    type: actionTypes.CONFIRM_MEDICAL_REMINDER_START,
    payload
  }),
  success: () => ({
    type: actionTypes.CONFIRM_MEDICAL_REMINDER_SUCCESS
  }),
  fail: payload => ({
    type: actionTypes.CONFIRM_MEDICAL_REMINDER_FAIL,
    payload
  })
};

export const fetchChartDetailMedicalReminder = {
  call: params => ({
    type: actionTypes.FETCH_CHART_DETAIL_MEDICAL_REMINDER,
    params
  }),
  start: () => ({
    type: actionTypes.FETCH_CHART_DETAIL_MEDICAL_REMINDER_START
  }),
  success: data => ({
    type: actionTypes.FETCH_CHART_DETAIL_MEDICAL_REMINDER_SUCCESS,
    data
  }),
  fail: payload => ({
    type: actionTypes.FETCH_CHART_DETAIL_MEDICAL_REMINDER_FAIL,
    payload
  })
};

export const syncMedicalReminderTaken = {
  call: () => ({
    type: actionTypes.SYNC_MEDICAL_REMINDER_TAKEN
  }),
  start: () => ({ type: actionTypes.SYNC_MEDICAL_REMINDER_TAKEN_START }),
  reminderSuccess: payload => ({
    type: actionTypes.SYNC_MEDICAL_REMINDER_SUCCESS,
    payload
  }),
  reminderFail: payload => ({
    type: actionTypes.SYNC_MEDICAL_REMINDER_FAIL,
    payload
  }),
  takenSuccess: payload => ({
    type: actionTypes.SYNC_MEDICAL_TAKEN_SUCCESS,
    payload
  }),
  takenFail: payload => ({
    type: actionTypes.SYNC_MEDICAL_TAKEN_FAIL,
    payload
  })
};

export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_SOCMED = 'AUTH_SOCMED';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_DISMISS_ERROR = 'AUTH_DISMISS_ERROR';

export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_REGISTER_START = 'AUTH_REGISTER_START';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_REGISTER_FAIL = 'AUTH_REGISTER_FAIL';

export const AUTH_REMOVE_LOCAL_STORAGE = 'AUTH_REMOVE_LOCAL_STORAGE';
export const AUTH_REMOVE_LOCAL_STORAGE_START =
  'AUTH_REMOVE_LOCAL_STORAGE_START';
export const AUTH_REMOVE_LOCAL_STORAGE_SUCCESS =
  'AUTH_REMOVE_LOCAL_STORAGE_SUCCESS';
export const AUTH_REMOVE_LOCAL_STORAGE_FAIL = 'AUTH_REMOVE_LOCAL_STORAGE_FAIL';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

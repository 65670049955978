import Service from './Service';
import { NowDateUTCString } from '../utils/DateUTCString';
import formatDate from '../utils/formatDate';
import { utcOffset } from '../utils/dateUtils';
import {
  getIndex,
  parseToChartSeriesObject,
  toFilteredData
} from '../utils/chartFilterUtils';

export default class HBA1CService extends Service {
  constructor() {
    super('hba1c', true);
  }

  // mengikuti mobile cuman untuk SQLLitenya dihilangkan
  // Fetch Latest HBA1C
  async fetchLatest(userId) {
    const utcString = NowDateUTCString();
    const result = await this.find({
      userId,
      'date[$lte]': utcString,
      '$sort[date]': -1
    });
    console.log('result fetch latest hba1c', result);
    if (result.data && result.data.data.length > 0) {
      const data = result.data.data[0];
      console.log('hasil fetch api', data);
      return { value: data.value, date: data.date, updated: data.updated };
    } else {
      return { value: null, date: null, updated: null };
    }
  }

  // Fetch data detail
  async fetchSingleData(userId, date) {
    let response = await this.find({
      date: date,
      userId: userId,
      $limit: -1
    });
    return response.data;
  }

  // Get data detail
  async getSingleData(id) {
    let response = await this.get(id);
    return response.data;
  }

  // Fetch filter data
  async fetchFilterData(userId, date, filterType) {
    const params = {
      userId: userId,
      date: date,
      typeFilter: filterType,
      offsetDate: utcOffset,
      $limit: -1
    };
    let response = await this.find(params);
    return response.data;
  }

  // // Post data to api
  async postData(data) {
    let res = await this.create(data);
    return res;
  }

  // Patch data to api
  async updateData(id, params) {
    // console.log(params);
    delete params?.isVerified;
    const res = await this.patch({ id, ...params });
    return res;
  }

  // Delete data to api
  async deleteData({ id, customUrl }) {
    let res = await this.delete(id, customUrl);
    return res;
  }

  // get data history
  async getDataHistory(userId, date) {
    let res = await this.fetchSingleData(userId, date);
    if (res) {
      return res;
    } else {
      return { value: null, date: null, updated: null, type: null };
    }
  }

  // Get filterd
  async getFilter(userId, date, filter) {
    let datas = [];
    const res = await this.fetchFilterData(userId, date, filter);
    if (res && res.length) {
      datas = toFilteredData(res, filter);
      return parseToChartSeriesObject(datas, filter);
    } else {
      return parseToChartSeriesObject([], filter);
    }
  }

  // Patch data
  async editNote(id, note) {
    let data = {
      note
    };
    return await this.updateData(id, data).then(async result => {
      if (result.success) {
        return result;
      } else {
        return result;
      }
    });
  }
}

import * as actionTypes from './elvasense.types';

export const getSyncElvasense = {
  call: id => ({ type: actionTypes.GET_SYNC_ELVASENSE, id }),
  start: () => ({ type: actionTypes.GET_SYNC_ELVASENSE_START }),
  success: (id, payload) => ({
    type: actionTypes.GET_SYNC_ELVASENSE_SUCCESS,
    id,
    payload
  }),
  fail: ({ error }) => ({
    type: actionTypes.GET_SYNC_ELVASENSE_FAIL,
    error
  })
};

export const updateSyncElvasense = {
  call: (id, data) => {
    return { type: actionTypes.UPDATE_SYNC_ELVASENSE, id, data };
  },
  start: () => ({ type: actionTypes.UPDATE_SYNC_ELVASENSE_START }),
  success: payload => ({
    type: actionTypes.UPDATE_SYNC_ELVASENSE_SUCCESS,
    payload
  }),
  fail: ({ error }) => ({
    type: actionTypes.UPDATE_SYNC_ELVASENSE_FAIL,
    error
  })
};

export const updateSyncElvasenseEmailPassword = {
  call: (id, data) => {
    return { type: actionTypes.UPDATE_SYNC_ELVASENSE_EMAIL_PASSWORD, id, data };
  },
  start: () => ({
    type: actionTypes.UPDATE_SYNC_ELVASENSE_START_EMAIL_PASSWORD
  }),
  success: ({ payload, message }) => ({
    type: actionTypes.UPDATE_SYNC_ELVASENSE_SUCCESS_EMAIL_PASSWORD,
    payload,
    message
  }),
  fail: ({ error }) => ({
    type: actionTypes.UPDATE_SYNC_ELVASENSE_FAIL_EMAIL_PASSWORD,
    error
  })
};

export const postSyncElvasense = {
  call: (id, data) => ({ type: actionTypes.POST_SYNC_ELVASENSE, id, data }),
  start: () => ({ type: actionTypes.POST_SYNC_ELVASENSE_START }),
  success: () => ({
    type: actionTypes.POST_SYNC_ELVASENSE_SUCCESS
  }),
  fail: ({ error }) => ({
    type: actionTypes.POST_SYNC_ELVASENSE_FAIL,
    error
  })
};

/**
 * UTILITY
 */
export const elvasenseDismissError = () => {
  return {
    type: actionTypes.ELVASENSE_DISMISS_ERROR
  };
};

import * as actionTypes from './notification.types';

const initialState = {
  isLoading: false,
  isLoadingUnread: false,
  error: null,
  unread: 0,
  data: null
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_NOTIFICATION_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.CREATE_NOTIFICATION_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.UPDATE_NOTIFICATION_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.SYNC_NOTIFICATION_START:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SYNC_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case actionTypes.SYNC_NOTIFICATION_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.UNREARD_NOTIFICATION_START:
      return {
        ...state,
        isLoadingUnread: true
      };
    case actionTypes.UNREARD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        unread: action.payload,
        isLoadingUnread: false
      };
    case actionTypes.UNREARD_NOTIFICATION_FAIL:
      return {
        ...state,
        unread: action.payload,
        isLoadingUnread: false
      };
    default:
      return state;
  }
}

import * as actionTypes from './food-recipe.types';

/**
 * FETCH FOODS
 */
export const fetchFoodsRecipe = (params, userId) => ({
  type: actionTypes.FETCH_FOODS_RECIPE,
  params,
  userId
});
export const fetchFoodsRecipeStart = () => ({
  type: actionTypes.FETCH_FOODS_RECIPE_START
});
export const fetchFoodsRecipeSuccess = ({ lists, message }) => ({
  type: actionTypes.FETCH_FOODS_RECIPE_SUCCESS,
  lists,
  message
});
export const fetchFoodsRecipeConcat = ({ lists, message }) => ({
  type: actionTypes.FETCH_FOODS_RECIPE_SUCCESS,
  lists,
  message
});
export const fetchFoodsRecipeFail = error => ({
  type: actionTypes.FETCH_FOODS_RECIPE_FAIL,
  error
});

/**
 * FETCH FOODS DETAILS
 */
export const fetchFoodsRecipeDetail = id => ({
  type: actionTypes.FETCH_FOODS_RECIPE_DETAIL,
  id
});
export const fetchFoodsRecipeDetailStart = () => ({
  type: actionTypes.FETCH_FOODS_RECIPE_DETAIL_START
});
export const fetchFoodsRecipeDetailSuccess = ({ details, message }) => ({
  type: actionTypes.FETCH_FOODS_RECIPE_DETAIL_SUCCESS,
  details,
  message
});
export const fetchFoodsRecipeDetailFail = error => ({
  type: actionTypes.FETCH_FOODS_RECIPE_DETAIL_FAIL,
  error
});

/**
 * CREATE FOODS
 */
export const createFoodsRecipe = (body, date) => ({
  type: actionTypes.CREATE_FOODS_RECIPE,
  body,
  date
});
export const createFoodsRecipeStart = () => ({
  type: actionTypes.CREATE_FOODS_RECIPE_START
});
export const createFoodsRecipeSuccess = ({ message }) => ({
  type: actionTypes.CREATE_FOODS_RECIPE_SUCCESS,
  message
});
export const createFoodsRecipeFail = error => ({
  type: actionTypes.CREATE_FOODS_RECIPE_FAIL,
  error
});

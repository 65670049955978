export const ACTION_CLIENT = {
  sign_up: 'sign_up',
  sign_in: 'sign_in'
};

export const AUTH_BY = {
  register: 'register',
  socmed: 'socmed'
};

export const TYPE_SOCMED = {
  google: 'google',
  facebook: 'facebook',
  apple: 'apple'
};

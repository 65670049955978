import Service from './Service';

export default class GoalsService extends Service {
  constructor() {
    super('goals', true);
  }

  // get data from api
  async fetchData(userId) {
    const response = await this.find({
      userId,
      $limit: -1
    });
    return response.data;
  }

  // Post data to api
  async postData(data) {
    let res = await this.create(data);
    return res;
  }

  // Patch data to api
  async updateData(id, params) {
    // console.log(params);
    delete params?.isVerified;
    const res = await this.patch({ id, ...params });
    return res;
  }

  // Delete data to api
  async deleteData({ id, customUrl }) {
    const res = await this.delete(id, customUrl);
    return res;
  }

  // mengikuti mobile cuman untuk SQLLitenya dihilangkan
  // Fetch data from api
  async getData(userId) {
    const result = this.fetchData(userId)
      .then(async response => {
        console.log('response resultGetDataGoals', response);
        console.log('response.length', response.length);

        if (response && response.length) {
          const data = response[0];
          return data;
        }
        return [];
      })
      .catch(error => {
        console.log('ERROR FETCH IN GOALS', error);
        return [];
      });

    return result;
  }

  // Patch data
  async editValue(userId, field, newValue) {
    let data = {
      userId
    };
    data[field] = newValue;
    return await this.fetchData(userId).then(async response => {
      if (response.length) {
        // If data exist use patch
        console.log('USE PATCH');
        let id = await response[0].id;
        return await this.updateData(id, data).then(async result => {
          console.log('RESULT UPDATE VALUE', result);
          if (result) {
            return result;
          }
        });
      } else {
        // If didn't exit exist use post
        console.log('USE POST');
        return await this.postData(data).then(async result => {
          if (result) {
            return result;
          }
        });
      }
    });
  }
}

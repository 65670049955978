import { put, apply } from 'redux-saga/effects';
import * as pinnedActions from '../../reducers/pinned-content/pinned.actions';
import PinnedContentService from '../../../services/PinnedContent';

export function* fetch({ params }) {
  yield put(pinnedActions.fetch.start());
  const service = yield new PinnedContentService();
  const res = yield apply(service, service.fetch, [params]);
  if (res.data) {
    let pinned = [];
    for (let i = 0; i < res.data?.data.length; i++) {
      if (res.data.data[i].title) {
        pinned.push(res.data.data[i]);
      }
    }
    yield put(pinnedActions.fetch.success(pinned));
  } else {
    yield put(pinnedActions.fetch.fail(res));
  }
}

import * as sharedTypes from './responsive.types';
import { updateObject } from '../../../shared/utility';

const initialState = {
  isDesktopOrLaptop: false,
  isMobile: false,
  isTablet: false
};

const updateResponsive = (state, action) => {
  return updateObject(state, { ...action.data });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case sharedTypes.SET_RESPONSIVE:
      return updateResponsive(state, action);
    default:
      return state;
  }
};

export default reducer;

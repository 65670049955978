import { takeEvery } from 'redux-saga/effects';
import * as transisiTypes from 'store/reducers/transisi/transisi.types';
import { updateTransisiSaga } from './transisi';

export const transisiSagas = {
  watchUpdateTransisi: takeEvery(
    transisiTypes.TRANSISI_USER,
    updateTransisiSaga
  )
};

import { takeEvery } from 'redux-saga/effects';
import {
  fetchHistory,
  fetchHistoryDetail,
  fetchHistoryChart,
  createHistory,
  updateHistory,
  removeHistory
  // setGreetingHistory,
} from './food-history';
import * as types from '././../../reducers/food-history/food-history.types';

export const foodHistorySagas = {
  fetchHistory: takeEvery(types.FETCH_FOOD_HISTORY, fetchHistory),
  fetchHistoryDetail: takeEvery(
    types.FETCH_FOOD_HISTORY_DETAIL,
    fetchHistoryDetail
  ),
  fetchHistoryChart: takeEvery(
    types.FETCH_FOOD_HISTORY_CHART,
    fetchHistoryChart
  ),
  createHistory: takeEvery(types.CREATE_FOOD_HISTORY, createHistory),
  updateHistory: takeEvery(types.UPDATE_FOOD_HISTORY, updateHistory),
  removeHistory: takeEvery(types.REMOVE_FOOD_HISTORY, removeHistory)
  // setGreetingHistory: takeEvery(types.SET_GREETING_FOOD_HISTORY,setGreetingHistory,),
};

import { put, apply } from 'redux-saga/effects';
import * as userCategoryAction from '../../reducers/user-category/user-category.actions';

import UserCategoryServiceClass from '../../../services/UserCategory';

export function* userTypes() {
  yield put(userCategoryAction.userTypes().start());

  const UserCategory = yield new UserCategoryServiceClass();

  const res = yield apply(UserCategory, UserCategory.getUserTypes, []);

  if (res.data) {
    yield put(userCategoryAction.userTypes().success(res.data));
    // console.log('USER TYPES', res.data);
  } else {
    yield put(userCategoryAction.userTypes().fail(res));
    console.log('USER TYPES ERROR', res);
  }
}

export function* physicalActivities() {
  yield put(userCategoryAction.physicalActivities().start());

  const UserCategory = yield new UserCategoryServiceClass();

  const res = yield apply(UserCategory, UserCategory.getPhysicalActivities, []);
  console.log('resresresresres', res);

  if (res.data) {
    yield put(userCategoryAction.physicalActivities().success(res.data.data));
    // console.log('USER TYPES', res.data);
  } else {
    yield put(userCategoryAction.physicalActivities().fail(res));
    console.log('USER TYPES ERROR', res);
  }
}

import { put, call, select, apply } from 'redux-saga/effects';
import * as goalsActions from '../../reducers/goals/goals.actions';
import GoalsService from '../../../services/Goals';

export function* getGoals(action) {
  yield put(goalsActions.fetchGoals.start());

  const goalsService = yield new GoalsService();
  const resultGetDataGoals = yield apply(goalsService, goalsService.getData, [
    action.userId
  ]);

  console.log('resultGetDataGoals', resultGetDataGoals);

  if (resultGetDataGoals && resultGetDataGoals.length !== 0) {
    yield put(goalsActions.fetchGoals.success(resultGetDataGoals));
  }
}

import { put, apply } from 'redux-saga/effects';
import * as actiions from '../../reducers/klik-dokter/klik-dokter.actions';
import KlikDokter from '../../../services/KlikDokter';

export function* getToken() {
  yield put(actiions.getToken.start());

  const service = yield new KlikDokter();
  const res = yield service.find({});

  if (res.error) {
    console.log(`------ MASUK RESEEROR : `, res);
    yield put(actiions.getToken.fail(res));
    return;
  }
  console.log(`------ LANJUT : `, res);
  yield put(actiions.getToken.success(res));
}

import * as klikDokterType from './klik-dokter.types';

const initialState = {
  isLoading: false,
  data: null
};

export default function klikDokterReducer(state = initialState, action) {
  switch (action.type) {
    case klikDokterType.FETCH_KLIK_DOKTER_START:
      return {
        ...state,
        isLoading: true
      };
    case klikDokterType.FETCH_KLIK_DOKTER_SUCCESS:
      console.log(`------------->> ACTION SUCCESS! : `, action?.res, action);
      return {
        ...state,
        data: action?.res,
        isLoading: false
      };
    case klikDokterType.FETCH_KLIK_DOKTER_FAIL:
      console.log(`------------->> ACTION FAIL : `, action?.res, action);
      return {
        ...state,
        data: action?.res,
        isLoading: false
      };
    case klikDokterType.FETCH_KLIK_DOKTER_RESET:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}

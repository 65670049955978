import * as infoTypes from './info.types';

export const fetch = {
  call: page => ({
    type: infoTypes.FETCH_INFORMATION,
    page
  }),
  start: () => ({ type: infoTypes.FETCH_INFORMATION_START }),
  success: payload => ({
    type: infoTypes.FETCH_INFORMATION_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: infoTypes.FETCH_INFORMATION_FAIL,
    payload
  })
};

import Service from './Service';

export default class Users extends Service {
  constructor() {
    super('users', true);
  }

  async find(params) {
    const response = await super.find(params);
    return super.generateDefaultResponse(response);
  }

  async get(id) {
    const response = await super.get(id);
    return super.generateDefaultResponse(response);
  }

  async create(data) {
    const response = await super.create(data);
    return super.generateDefaultResponse(response);
  }

  async patch(data) {
    // console.log(data);
    delete data?.isVerified;
    const response = await super.patch(data);
    return super.generateDefaultResponse(response);
  }

  // Coba convert dari mobule ke web
  async getSyncElvasense(id) {
    const response = await super.get(id);
    return response;
  }

  async updateSyncElvasense(id, data) {
    data.id = id;
    const response = await super.patch(data);
    return response;
  }

  async postSyncElvasense(data) {
    this.url = process.env.REACT_APP_HOST + '/elvasense';
    const response = await super.create(data);
    console.log('responseresponseresponse', response);
    return response;
  }
}

import moment from 'moment';

type Flag =
  | 'dddd'
  | 'DD'
  | 'DD MMM'
  | 'MMM'
  | 'MMM YYYY'
  | 'DD MMM YYYY'
  | 'M'
  | 'HH:mm'
  | 'YYYY'
  | 'YYYY-MM-DD';

const utcOffset = moment().utcOffset();

function toCustomDate(date, type: Flag, defaultValue = '') {
  const selectedLocale = require('moment/locale/id');
  moment.updateLocale('id', selectedLocale);
  const formatted = moment(date);
  return formatted.isValid ? moment(date).format(type) : defaultValue;
}

function checkIfAWeek(date) {
  const targetDate = moment(date);
  const substractedDate = moment().subtract(7, 'days');
  return substractedDate.isSame(targetDate, 'date');
}

function checkIfACurrentWeek(startDate, endDate) {
  const range = moment().isBetween(startDate, endDate);
  return range;
}

function checkIfACurrentQuarter(quarter = 0, year = null) {
  return moment().year() === year && moment().quarter() === quarter;
}

function checkIfACurrentYear(year = null) {
  return moment().year() === year;
}

function checkIfDateIsTodaysDate(date) {
  const targetDate = moment(date);
  const currentDate = moment(new Date());
  return targetDate.isSame(currentDate, 'date');
}

function isBetweenRangeOfTime(startTime, endTime) {
  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  return moment(new Date(), 'HH:mm').isBetween(start, end);
}

function isAfterOfDateTime(targetDateTime) {
  const date = moment(targetDateTime, 'YYYY-MM-DD HH:mm:ss');
  const current = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
  return date.isAfter(current);
}

export {
  utcOffset,
  toCustomDate,
  checkIfAWeek,
  checkIfACurrentWeek,
  checkIfACurrentQuarter,
  checkIfACurrentYear,
  checkIfDateIsTodaysDate,
  isBetweenRangeOfTime,
  isAfterOfDateTime
};

import { put } from 'redux-saga/effects';
import * as actions from '../../reducers/authManagement/authManagement.actions';
import authManagementService from 'services/authManagement';

export function* resetPasswordSaga(action) {
  yield put(actions.resetPasswordStart());

  const service = yield new authManagementService();
  const response = yield service.create(action.params);

  if (response.error) {
    yield put(actions.resetPasswordFail(response.error));
    return;
  }
  yield put(actions.resetPasswordSuccess());

  switch (action.params.action) {
    case 'sendResetPwd':
      yield action.history.replace(
        `/auth/success-send-email?email=${action.params.value.email}&action=ubah_password`
      );
      break;

    case 'resetPwdLong':
      yield action.history.replace(`/auth/login?status=success`);
      break;

    case 'passwordChange':
      yield action.history.replace(`/admin/profile`);
      break;

    default:
      break;
  }
}

export function* changePasswordSaga(params) {
  yield put(actions.changePasswordStart());

  const action = params.action;
  const email = params.email;
  const oldPassword = params.oldPassword;
  const password = params.password;

  const body = {
    action,
    value: {
      user: {
        email
      },
      oldPassword,
      password
    }
  };

  const service = yield new authManagementService();
  const response = yield service.create(body);

  if (response.error) {
    yield put(actions.changePasswordFail(response.error));
    return;
  }
  yield put(actions.changePasswordSuccess(response.data));
}

export function* verificationEmailSaga(action) {
  yield put(actions.verificationEmailStart());

  const service = yield new authManagementService();
  const response = yield service.create(action.params);

  if (response.error) {
    yield put(actions.verificationEmailFail(response.error));
    return;
  }
  yield put(actions.verificationEmailSuccess());
}

import { checkIfAWeek, toCustomDate } from './dateUtils';
import moment from 'moment';
import { indexOf } from 'lodash';

export const toFilteredData = (
  datas = [],
  filterType = null,
  filtered = false,
  doRemovePivot = true
) => {
  let data = [];
  if (filterType == 'daily') {
    const dataPivot = indexOf(
      datas,
      datas.find(item => {
        return item.isPivot === true;
      })
    );
    const min = dataPivot - 14;
    const max = dataPivot + 15;
    // data = datas.slice(min, max);
    data = datas.slice(1, 29);
  } else if (filterType == 'weekly') {
    const dataPivot = indexOf(
      datas,
      datas.find(item => {
        return item.isPivot === true;
      })
    );
    const min = dataPivot - 8;
    const max = dataPivot + 9;
    data = datas.slice(min, max);
  } else {
    data = datas;
  }
  const { currentIndex, zeroIndex } = getIndex(data, filterType);

  if (filtered) {
    data =
      currentIndex !== -1 ? data?.slice(zeroIndex, currentIndex + 1) : data;
  }

  // need to remove isPivot before storing to SQLITE
  if (doRemovePivot) {
    return removePivot(data);
  } else {
    return data;
  }
};

export const getIndex = (datas = [], filterType = null) => {
  let zeroIndex = 0,
    currentIndex;
  switch (filterType) {
    // to check range of days in a week
    case 'daily':
      zeroIndex = datas?.findIndex(item => checkIfAWeek(item.date));
      currentIndex = datas?.findIndex(item => item.isPivot);
      zeroIndex = zeroIndex !== -1 ? zeroIndex + 1 : 0;
      break;
    // to check range weeks in a month
    case 'weekly':
      currentIndex = datas?.findIndex(item => item.isPivot);
      zeroIndex =
        currentIndex !== -1
          ? datas?.findIndex(item => {
              const currMonth = toCustomDate(
                datas[currentIndex].startOfWeek,
                'M'
              );
              return toCustomDate(item.endOfWeek, 'M') == currMonth;
            })
          : 0;
      break;
    // to check range months in a year
    case 'monthly':
      zeroIndex = datas?.findIndex(item => {
        const currYear = toCustomDate(new Date(), 'YYYY');
        return item.month == 0 && item.year == currYear;
      });
      currentIndex = datas?.findIndex(item => item.isPivot);
      break;
    // to check range quarters in a year
    case 'quarter':
      zeroIndex = datas?.findIndex(item => {
        const currYear = toCustomDate(new Date(), 'YYYY');
        return item.quarter == 1 && item.year == currYear;
      });
      currentIndex = datas?.findIndex(item => item.isPivot);
      break;
    // display years until current year
    case 'yearly':
      currentIndex = datas?.findIndex(item => item.isPivot);
      break;
    default:
      currentIndex = -1;
      break;
  }
  return { currentIndex, zeroIndex };
};

function isInt(n) {
  if (n === null) {
    return null;
  } else {
    return n % 1 === 0;
  }
}

function isNextDataExisted(i = 0, datas = []) {
  const getAverage = ({ totalValue, totalRecord }) => {
    if (totalValue !== null && totalRecord !== null) {
      const result = totalValue / totalRecord;
      return toFixed(result);
    } else {
      return null;
    }
  };
  let result = false;
  for (i; i < datas.length; i++) {
    const y = getAverage(datas[i]);
    if (typeof y === 'number') {
      result = true;
      break;
    }
  }
  return result;
}

export function toFixed(result) {
  if (isInt(result) === null) {
    return null;
  } else if (isInt(result)) {
    return parseInt(result);
  } else {
    return parseFloat(result.toFixed(2));
  }
}

// export const parseToLineChartObject = (datas = [], type = null) => {
//   const getAverage = ({totalValue, totalRecord}) => {
//     if (totalValue !== null && totalRecord !== null) {
//       const result = totalValue / totalRecord;
//       return toFixed(result);
//     } else {
//       return null;
//     }
//   };
//   let results = [];
//   switch (type) {
//     case 'daily':
//       for (let i = 0; i < datas.length; i++) {
//         const y = getAverage(datas[i]);
//         const x =
//           toCustomDate(datas[i].date, 'ddd') +
//           '\n' +
//           moment(datas[i].date).format('D/M');
//         if (i > 0 && y === null && isNextDataExisted(i, datas)) {
//           const yBefore = pathOr(null, [i - 1, 'y'], results);
//           if (yBefore === null) {
//             results.push({
//               x,
//               y,
//               label: y && `${toCustomDate(datas[i].date, 'DD MMM YY')} | ${y}`,
//             });
//           } else {
//             results.push({
//               x,
//               y: yBefore,
//               label: 'generated',
//             });
//           }
//         } else {
//           results.push({
//             x,
//             y,
//             label: y && `${toCustomDate(datas[i].date, 'DD MMM YY')} | ${y}`,
//           });
//         }
//       }
//       return results;
//     case 'weekly':
//       for (let i = 0; i < datas.length; i++) {
//         const y = getAverage(datas[i]);
//         const startWeek = toCustomDate(datas[i].startOfWeek, 'DD');
//         const endWeek = toCustomDate(datas[i].endOfWeek, 'DD');
//         const month = toCustomDate(datas[i].endOfWeek, 'MMM');
//         const label = `${startWeek}-${endWeek}`;
//         const x = `${label}\n${month}`;
//         if (i > 0 && y === null && isNextDataExisted(i, datas)) {
//           const yBefore = pathOr(null, [i - 1, 'y'], results);
//           if (yBefore === null) {
//             results.push({
//               x,
//               y,
//               label: y && `${label} ${month} | ${y}`,
//             });
//           } else {
//             results.push({
//               x,
//               y: yBefore,
//               label: 'generated',
//             });
//           }
//         } else {
//           results.push({
//             x,
//             y,
//             label: y && `${label} ${month} | ${y}`,
//           });
//         }
//       }
//       return results;
//     case 'monthly':
//       for (let i = 0; i < datas.length; i++) {
//         const y = getAverage(datas[i]);
//         const x = toCustomDate(datas[i].date, 'MMM YYYY')
//           .toString()
//           .replace(' ', '\n');
//         if (i > 0 && y === null && isNextDataExisted(i, datas)) {
//           const yBefore = pathOr(null, [i - 1, 'y'], results);
//           if (yBefore === null) {
//             results.push({
//               x,
//               y,
//               label: y && `${toCustomDate(datas[i].date, 'MMM YYYY')} | ${y}`,
//             });
//           } else {
//             results.push({
//               x,
//               y: yBefore,
//               label: 'generated',
//             });
//           }
//         } else {
//           results.push({
//             x,
//             y,
//             label: y && `${toCustomDate(datas[i].date, 'MMM YYYY')} | ${y}`,
//           });
//         }
//       }
//       return results;
//     case 'quarter':
//       for (let i = 0; i < datas.length; i++) {
//         const x = `${datas[i].label}\n${year}`;
//         const y = getAverage(datas[i]);
//         const year = toCustomDate(datas[i].date, 'YYYY');
//         if (i > 0 && y === null && isNextDataExisted(i, datas)) {
//           const yBefore = pathOr(null, [i - 1, 'y'], results);
//           if (yBefore === null) {
//             results.push({
//               x,
//               y,
//               label: y && `${datas[i].label} ${year} | ${y || 0}`,
//             });
//           } else {
//             results.push({
//               x,
//               y: yBefore,
//               label: 'generated',
//             });
//           }
//         } else {
//           results.push({
//             x,
//             y,
//             label: y && `${datas[i].label} ${year} | ${y || 0}`,
//           });
//         }
//       }
//       return results;
//     case 'yearly':
//       for (let i = 0; i < datas.length; i++) {
//         const x = `${datas[i].year}`;
//         const y = getAverage(datas[i]);
//         if (i > 0 && y === null && isNextDataExisted(i, datas)) {
//           const yBefore = pathOr(null, [i - 1, 'y'], results);
//           if (yBefore === null) {
//             results.push({
//               x,
//               y,
//               label: y && `${datas[i].year} | ${y || 0}`,
//             });
//           } else {
//             results.push({
//               x,
//               y: yBefore,
//               label: 'generated',
//             });
//           }
//         } else {
//           results.push({
//             x,
//             y,
//             label: y && `${datas[i].year} | ${y || 0}`,
//           });
//         }
//       }
//       return results;
//     default:
//       return results?.map((item) => {
//         const y = toFixed(item.totalValue);
//         return {
//           x: toCustomDate(item.date, 'MMM YYYY'),
//           y,
//           label: y && `${toCustomDate(item.date, 'MMM YYYY')} | ${y || 0}`,
//         };
//       });
//   }
// };

export const parseToChartSeriesObject = (
  results = [],
  type = null,
  operation = 'non-activity'
) => {
  const getAverage = ({ totalValue, totalRecord, totalDays }) => {
    if (operation === 'activity') {
      if (totalValue !== null && totalDays !== null) {
        const result = totalValue / totalDays;
        return toFixed(result);
      } else {
        return null;
      }
    } else {
      if (totalValue !== null && totalRecord !== null) {
        const result = totalValue / totalRecord;
        return toFixed(result);
      } else {
        return null;
      }
    }
  };
  switch (type) {
    case 'daily':
      return results?.map(item => {
        // jika tidak ada data, biarkan tetap null
        const y = getAverage(item);
        const x = moment(item.date).unix();
        return {
          x,
          y,
          label: y && `${toCustomDate(item.date, 'DD MMM YY')} | ${y}`
        };
      });
    case 'weekly':
      return results?.map(item => {
        const y = getAverage(item);
        const startWeek = toCustomDate(item.startOfWeek, 'DD');
        const endWeek = toCustomDate(item.endOfWeek, 'DD');
        const month = toCustomDate(item.endOfWeek, 'MMM');
        const label = `${startWeek}-${endWeek}`;
        const x = moment(item.startOfWeek)
          .add(3, 'days')
          .unix();
        return {
          x,
          y,
          label: y && `${label} ${month} | ${y}`
        };
      });
    case 'monthly':
      return results?.map(item => {
        const x = moment(item.date).unix();
        const y = getAverage(item);
        return {
          x,
          y,
          label: y && `${toCustomDate(item.date, 'MMM YYYY')} | ${y}`
        };
      });
    case 'quarter':
      return results?.map(item => {
        const x = moment(item.date)
          .add(1, 'month')
          .unix();
        const y = getAverage(item);
        const year = toCustomDate(item.date, 'YYYY');
        return {
          x,
          y,
          label: y && `${item.label} ${year} | ${y || 0}`
        };
      });
    case 'yearly':
      return results?.map(item => {
        const x = moment(item.date).unix();
        const y = getAverage(item);
        return {
          x,
          y,
          label: y && `${item.year} | ${y || 0}`
        };
      });
    default:
      return results?.map(item => {
        // jika tidak ada data, biarkan tetap null
        const y = getAverage(item);
        const x = moment(item.date).unix();
        return {
          x,
          y,
          label: y && `${toCustomDate(item.date, 'DD MMM YY')} | ${y}`
        };
      });
  }
};

export const parseToChartObject = (
  results = [],
  type = null,
  operation = 'non-activity'
) => {
  const getAverage = ({ totalValue, totalRecord, totalDays }) => {
    if (operation === 'activity') {
      if (totalValue !== null && totalDays !== null) {
        const result = totalValue / totalDays;
        return toFixed(result);
      } else {
        return null;
      }
    } else {
      if (totalValue !== null && totalRecord !== null) {
        const result = totalValue / totalRecord;
        return toFixed(result);
      } else {
        return null;
      }
    }
  };
  switch (type) {
    case 'daily':
      return results?.map(item => {
        // jika tidak ada data, biarkan tetap null
        const y = getAverage(item);
        const x =
          toCustomDate(item.date, 'ddd') +
          '\n' +
          moment(item.date).format('D/M');
        return {
          x,
          y,
          label: y && `${toCustomDate(item.date, 'DD MMM YY')} | ${y}`
        };
      });
    case 'weekly':
      return results?.map(item => {
        const y = getAverage(item);
        const startWeek = toCustomDate(item.startOfWeek, 'DD');
        const endWeek = toCustomDate(item.endOfWeek, 'DD');
        const month = toCustomDate(item.endOfWeek, 'MMM');
        const label = `${startWeek}-${endWeek}`;
        return {
          x: `${label}\n${month}`,
          y,
          label: y && `${label} ${month} | ${y}`
        };
      });
    case 'monthly':
      return results?.map(item => {
        const y = getAverage(item);
        return {
          x: toCustomDate(item.date, 'MMM YYYY')
            .toString()
            .replace(' ', '\n'),
          y,
          label: y && `${toCustomDate(item.date, 'MMM YYYY')} | ${y}`
        };
      });
    case 'quarter':
      return results?.map(item => {
        const y = getAverage(item);
        const year = toCustomDate(item.date, 'YYYY');
        return {
          x: `${item.label}\n${year}`,
          y,
          label: y && `${item.label} ${year} | ${y || 0}`
        };
      });
    case 'yearly':
      return results?.map(item => {
        const y = getAverage(item);
        return {
          x: `${item.year}`,
          y,
          label: y && `${item.year} | ${y || 0}`
        };
      });
    default:
      return results?.map(item => {
        const y = toFixed(item.totalValue);
        return {
          x: toCustomDate(item.date, 'MMM YYYY'),
          y,
          label: y && `${toCustomDate(item.date, 'MMM YYYY')} | ${y || 0}`
        };
      });
  }
};

export const removePivot = (datas = []) =>
  datas.map(({ isPivot, ...restItems }) => ({ ...restItems }));

export const parseToChartCalories = (results = [], type = null) => {
  const getAverage = ({ totalCalories, totalDays }) => {
    if (totalCalories !== null && totalDays !== null) {
      const result = totalCalories / totalDays;

      return toFixed(result);
    } else {
      return null;
    }
  };
  switch (type) {
    case 'daily':
      return results?.map(item => {
        // jika tidak ada data, biarkan tetap null
        const y = item.totalCalories;
        const x =
          toCustomDate(item.date, 'ddd') +
          '\n' +
          moment(item.date).format('D/M');
        return {
          x,
          y,
          label: y && `${toCustomDate(item.date, 'DD MMM YY')} | ${y}`
        };
      });
    case 'weekly':
      return results?.map(item => {
        // const y = item.totalCalories;
        const y = getAverage(item);
        const startWeek = toCustomDate(item.startOfWeek, 'DD');
        const endWeek = toCustomDate(item.endOfWeek, 'DD');
        const month = toCustomDate(item.endOfWeek, 'MMM');
        const label = `${startWeek}-${endWeek}`;
        return {
          x: `${label}\n${month}`,
          y,
          label: y && `${label} ${month} | ${y}`
        };
      });
    case 'monthly':
      return results?.map(item => {
        // const y = item.totalCalories;
        const y = getAverage(item);
        return {
          x: toCustomDate(item.date, 'MMM YYYY')
            .toString()
            .replace(' ', '\n'),
          y,
          label: y && `${toCustomDate(item.date, 'MMM YYYY')} | ${y}`
        };
      });
    case 'quarter':
      return results?.map(item => {
        // const y = item.totalCalories;
        const y = getAverage(item);
        const year = toCustomDate(item.date, 'YYYY');
        return {
          x: `${item.label}\n${year}`,
          y,
          label: y && `${item.label} ${year} | ${y || 0}`
        };
      });
    case 'yearly':
      return results?.map(item => {
        // const y = item.totalCalories;
        const y = getAverage(item);
        const year = toCustomDate(item.date, 'YYYY');
        return {
          x: year,
          y,
          label: y && `${year} | ${y || 0}`
        };
      });
    default:
      return results?.map(item => {
        const y = toFixed(item.totalValue);
        return {
          x: toCustomDate(item.date, 'MMM YYYY'),
          y,
          label: y && `${toCustomDate(item.date, 'MMM YYYY')} | ${y || 0}`
        };
      });
  }
};

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const DISMISS_ERROR = 'DISMISS_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_REQUEST_FAIL';

export const VERIFICATION_EMAIL = 'VERIFICATION_EMAIL';
export const VERIFICATION_EMAIL_START = 'VERIFICATION_EMAIL_START';
export const VERIFICATION_EMAIL_SUCCESS = 'VERIFICATION_EMAIL_SUCCESS';
export const VERIFICATION_EMAIL_FAIL = 'VERIFICATION_EMAIL_FAIL';

import * as actionTypes from './notification.types';

export const createNotification = {
  call: data => ({ type: actionTypes.CREATE_NOTIFICATION, data }),
  start: () => ({ type: actionTypes.CREATE_NOTIFICATION_START }),
  success: () => ({
    type: actionTypes.CREATE_NOTIFICATION_SUCCESS
  }),
  fail: payload => ({
    type: actionTypes.CREATE_NOTIFICATION_FAIL,
    payload
  })
};

export const updateOpenNotification = {
  call: id => ({ type: actionTypes.UPDATE_NOTIFICATION, id }),
  start: () => ({ type: actionTypes.UPDATE_NOTIFICATION_START }),
  success: () => ({
    type: actionTypes.UPDATE_NOTIFICATION_SUCCESS
  }),
  fail: payload => ({
    type: actionTypes.UPDATE_NOTIFICATION_FAIL,
    payload
  })
};

export const removeNotification = {
  call: (id, params) => ({
    type: actionTypes.REMOVE_NOTIFICATION,
    id,
    params
  }),
  start: () => ({ type: actionTypes.REMOVE_NOTIFICATION_START }),
  success: () => ({
    type: actionTypes.REMOVE_NOTIFICATION_SUCCESS
  }),
  fail: payload => ({
    type: actionTypes.REMOVE_NOTIFICATION_FAIL,
    payload
  })
};

export const syncNotification = {
  call: userId => ({
    type: actionTypes.SYNC_NOTIFICATION,
    userId: userId
  }),
  start: () => ({ type: actionTypes.SYNC_NOTIFICATION_START }),
  success: payload => ({
    type: actionTypes.SYNC_NOTIFICATION_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: actionTypes.SYNC_NOTIFICATION_FAIL,
    payload
  })
};

export const syncNotificationUnread = {
  call: userId => ({
    type: actionTypes.SYNC_UNREAD_NOTIFICATION,
    userId: userId
  }),
  start: () => ({ type: actionTypes.SYNC_UNREAD_NOTIFICATION_START }),
  success: payload => ({
    type: actionTypes.SYNC_UNREAD_NOTIFICATION_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: actionTypes.SYNC_UNREAD_NOTIFICATION_FAIL,
    payload
  })
};

export const fetchUnread = {
  call: () => ({
    type: actionTypes.UNREARD_NOTIFICATION
  }),
  start: () => ({ type: actionTypes.UNREARD_NOTIFICATION_START }),
  success: payload => ({
    type: actionTypes.UNREARD_NOTIFICATION_SUCCESS,
    payload
  }),
  fail: payload => ({
    type: actionTypes.UNREARD_NOTIFICATION_FAIL,
    payload
  })
};

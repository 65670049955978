import * as articleTypes from '../../reducers/article/article.types';
import { takeEvery } from 'redux-saga/effects';
import {
  fetchArticlesSaga,
  getArticleSaga,
  addViewArticleSaga
} from './article';

export const articleSagas = {
  watchFetchArticles: takeEvery(articleTypes.FETCH_ARTICLES, fetchArticlesSaga),
  watchGetArticle: takeEvery(articleTypes.GET_ARTICLE, getArticleSaga),
  addViewArticle: takeEvery(articleTypes.VIEW_ARTICLE, addViewArticleSaga)
};

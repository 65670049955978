import * as authTypes from './auth.types';
import { updateObject } from '../../../shared/utility';

const initialState = {
  token: null,
  refreshToken: null,
  expirationDate: null,
  userId: null,
  email: null,
  phone: null,
  userName: null,
  error: null,
  loading: false,
  isVerified: false,
  user: null
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    refreshToken: action.refreshToken,
    expirationDate: action.expirationDate,
    userId: action.userId,
    email: action.email,
    phone: action.phone,
    userName: action.userName,
    error: null,
    loading: false,
    isVerified: action.isVerified,
    user: action.user
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authDismissError = (state, action) => {
  return updateObject(state, {
    error: null
  });
};

const authLogout = (state, action) => {
  return updateObject(state, initialState);
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const registerUserStart = (state, action) =>
  updateObject(state, { loading: true });
const registerUserSuccess = (state, action) =>
  updateObject(state, { loading: false, user: action.user, error: null });
const registerUserFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

const removeLocalStorageStart = (state, action) =>
  updateObject(state, { loading: true });
const removeLocalStorageSuccess = (state, action) =>
  updateObject(state, { loading: false, user: action.user, error: null });
const removeLocalStorageFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.AUTH_START:
      return authStart(state, action);
    case authTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case authTypes.AUTH_FAIL:
      return authFail(state, action);
    case authTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case authTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case authTypes.AUTH_DISMISS_ERROR:
      return authDismissError(state, action);

    case authTypes.AUTH_REGISTER_START:
      return registerUserStart(state, action);
    case authTypes.AUTH_REGISTER_SUCCESS:
      return registerUserSuccess(state, action);
    case authTypes.AUTH_REGISTER_FAIL:
      return registerUserFail(state, action);

    case authTypes.AUTH_REMOVE_LOCAL_STORAGE_START:
      return removeLocalStorageStart(state, action);
    case authTypes.AUTH_REMOVE_LOCAL_STORAGE_SUCCESS:
      return removeLocalStorageSuccess(state, action);
    case authTypes.AUTH_REMOVE_LOCAL_STORAGE_FAIL:
      return removeLocalStorageFail(state, action);

    default:
      return state;
  }
};

export default reducer;

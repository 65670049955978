import Service from './Service';

export default class PageInformationService extends Service {
  constructor() {
    super('page-information', true);
  }

  async fetch(page) {
    const params = {
      $limit: -1,
      platform: 'mobile',
      page
    };
    const response = await this.find(params);
    return response;
  }
}

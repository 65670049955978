import * as userCategory from './user-category.types';

const initialState = {
  userTypes: [],
  isLoadingUserTypes: false,
  errorUserTypes: null,

  physicalActivities: [],
  isLoadingPhysicalActivities: false,
  errorPhysicalActivities: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case userCategory.USER_TYPES_REQUEST_START:
      return {
        ...state,
        isLoadingUserTypes: true,
        errorUserTypes: null
      };
    case userCategory.USER_TYPES_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingUserTypes: false,
        userTypes: action.payload
      };
    case userCategory.USER_TYPES_REQUEST_FAIL:
      return {
        ...state,
        isLoadingUserTypes: false,
        errorUserTypes: action.payload
      };
    case userCategory.PHYSICAL_ACTIVITIES_REQUEST_START:
      return {
        ...state,
        isLoadingPhysicalActivities: true,
        errorPhysicalActivities: null
      };
    case userCategory.PHYSICAL_ACTIVITIES_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingPhysicalActivities: false,
        physicalActivities: action.payload
      };
    case userCategory.PHYSICAL_ACTIVITIES_REQUEST_FAIL:
      return {
        ...state,
        isLoadingPhysicalActivities: false,
        errorPhysicalActivities: action.payload
      };
    default:
      return state;
  }
}

import * as articleCategoryTypes from '../../reducers/article-category/article-category.types';
import { takeEvery } from 'redux-saga/effects';
import {
  fetchArticleCategorySaga,
  getArticleCategorySaga
} from './article-category';

export const articleCategorySagas = {
  watchFetchArticleCategory: takeEvery(
    articleCategoryTypes.FETCH_ARTICLE_CATEGORY,
    fetchArticleCategorySaga
  ),
  watchGetArticleCategory: takeEvery(
    articleCategoryTypes.GET_ARTICLE_CATEGORY,
    getArticleCategorySaga
  )
};

import { put, apply, select, call } from 'redux-saga/effects';
import * as notificationActions from '../../reducers/notification/notification.actions';
import NotificationService from '../../../services/Notification';

export function* createNotification({ data }) {
  yield put(notificationActions.createNotification.start());
  const notifService = yield new NotificationService();
  const res = yield apply(notifService, notifService.postData, [{ ...data }]);
  if (res.data) {
    yield put(notificationActions.createNotification.success());
  } else {
    yield put(notificationActions.createNotification.fail(res));
  }
}

export function* updateOpenNotification({ id }) {
  yield put(notificationActions.updateOpenNotification.start());
  const notifService = yield new NotificationService();
  const res = yield apply(notifService, notifService.patchData, [
    id,
    { status: 'read' }
  ]);
  if (res.data) {
    yield apply(notifService, notifService.updateRead, [id]);
    yield put(notificationActions.updateOpenNotification.success());
  } else {
    yield put(notificationActions.updateOpenNotification.fail(res));
  }
}

export function* syncNotification({ userId }) {
  yield put(notificationActions.syncNotification.start());
  const notifService = yield new NotificationService();
  const res = yield apply(notifService, notifService.fetchHistory, [userId]);
  if (res) {
    yield put(notificationActions.syncNotification.success(res));
  } else {
    yield put(notificationActions.syncNotification.fail(res));
  }
}

export function* syncUnreadNotification({ userId }) {
  yield put(notificationActions.syncNotificationUnread.start());
  const notifService = yield new NotificationService();
  const res = yield apply(notifService, notifService.fetchHistoryUnread, [
    userId
  ]);
  console.log('SYN NOTIF UNREAD', res);
  if (res) {
    yield put(notificationActions.fetchUnread.success(res));
  } else {
    yield put(notificationActions.fetchUnread.fail(res));
  }
}

import * as klik_dokter from './klik-dokter.types';

export const getToken = {
  call: params => ({
    type: klik_dokter.FETCH_KLIK_DOKTER,
    params
  }),
  start: () => ({ type: klik_dokter.FETCH_KLIK_DOKTER_START }),
  success: res => ({
    type: klik_dokter.FETCH_KLIK_DOKTER_SUCCESS,
    res
  }),
  fail: res => ({
    type: klik_dokter.FETCH_KLIK_DOKTER_FAIL,
    res
  }),
  reset: () => ({ type: klik_dokter.FETCH_KLIK_DOKTER_RESET })
};

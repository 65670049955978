import { put } from 'redux-saga/effects';
import * as actions from '../../reducers/food-recipe/food-recipe.actions';
import foodsRecipeServices from '../../../services/FoodRecipe';

/**
 * FETCH FOODS
 */
export function* fetchFoodsRecipe({ params, userId }) {
  yield put(actions.fetchFoodsRecipeStart());
  // console.log(userId, '====<<');
  const services = yield new foodsRecipeServices();
  // params = {
  //   ...params,
  //   'exactSearchOr[userId]': userId,
  //   // 'exactSearchOr[source]': 'admin',
  //   // 'exactSearchOr[source]': 'database',
  //   // 'exactSearchOr[source]': 'database+(edited)',
  //   'exactSearchOr[source]': ['admin', 'database', 'database (edited)'], //gak tau kenapa, di pwa, generated url ny jadi ga sama dg di mobile klo pakai sintax ini
  // };
  // console.log('PARAMSSSS 02:', params);
  const res = yield services.find({
    ...params,
    $limit: -1,
    sortBy: 'publishDate',
    sort: 'desc'
  });

  if (res.error) {
    yield put(actions.fetchFoodsRecipeFail(res.error));
    return;
  } else {
    if (params.$skip === 0) {
      yield put(
        actions.fetchFoodsRecipeSuccess({
          lists: res.data || [],
          message: res.message
        })
      );
    } else {
      yield put(
        actions.fetchFoodsRecipeConcat({
          lists: res.data || [],
          message: res.message
        })
      );
    }
  }
}

/**
 * FETCH FOODS DETAIL
 */
export function* fetchFoodsRecipeDetail({ id }) {
  yield put(actions.fetchFoodsRecipeDetailStart());
  const services = yield new foodsRecipeServices();
  const res = yield services.get(id);

  if (res.error) {
    yield put(actions.fetchFoodsRecipeDetailFail(res.error));
    return;
  }

  yield put(
    actions.fetchFoodsRecipeDetailSuccess({
      details: res,
      message: res.message
    })
  );
}

/**
 * CREATE FOODS
 */
export function* createFoodsRecipe({ data, date, userId }) {
  yield put(actions.createFoodsRecipe());
  const services = yield new foodsRecipeServices();
  const res = yield services.create({ ...data, userId });

  if (res.error) {
    yield put(actions.createFoodsRecipeFail(res.error));
    return;
  }

  yield put(
    actions.createFoodsRecipeSuccess({
      message: res.message
    })
  );
}

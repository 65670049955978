import * as authManagementTypes from './authManagement.types';

/**
 * RESET PASSWORD
 */
export const resetPassword = (params, history) => ({
  type: authManagementTypes.RESET_PASSWORD,
  params,
  history
});
export const resetPasswordStart = () => ({
  type: authManagementTypes.RESET_PASSWORD_START
});

export const resetPasswordSuccess = users => ({
  type: authManagementTypes.RESET_PASSWORD_SUCCESS
});
export const resetPasswordFail = error => ({
  type: authManagementTypes.RESET_PASSWORD_FAIL,
  error
});

/**
 * CHANGE PASSWORD
 */
export const changePassword = (email, oldPassword, password) => {
  return {
    type: authManagementTypes.CHANGE_PASSWORD,
    action: 'passwordChange', //SESUAI DENGAN YANG ADA DI BACKEND NYA.
    email: email,
    oldPassword: oldPassword,
    password: password
  };
};
export const changePasswordStart = () => ({
  type: authManagementTypes.CHANGE_PASSWORD_START
});

export const changePasswordSuccess = user => ({
  type: authManagementTypes.CHANGE_PASSWORD_SUCCESS,
  user
});
export const changePasswordFail = error => ({
  type: authManagementTypes.CHANGE_PASSWORD_FAIL,
  error
});

/**
 * DISMISS MESSAGE
 */
export const dismissError = () => ({
  type: authManagementTypes.DISMISS_ERROR
});

export const verificationEmail = params => ({
  type: authManagementTypes.VERIFICATION_EMAIL,
  params
});
export const verificationEmailStart = () => ({
  type: authManagementTypes.VERIFICATION_EMAIL_START
});

export const verificationEmailSuccess = users => ({
  type: authManagementTypes.VERIFICATION_EMAIL_SUCCESS
});
export const verificationEmailFail = error => ({
  type: authManagementTypes.VERIFICATION_EMAIL_FAIL,
  error
});

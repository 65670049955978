export const FETCH_FOODS = 'FETCH_FOODS';
export const FETCH_FOODS_START = 'FETCH_FOODS_START';
export const FETCH_FOODS_SUCCESS = 'FETCH_FOODS_SUCCESS';
export const FETCH_FOODS_CONCAT_SUCCESS = 'FETCH_FOODS_CONCAT_SUCCESS';
export const FETCH_FOODS_FAIL = 'FETCH_FOODS_FAIL';

export const FETCH_FOODS_DETAIL = 'FETCH_FOODS_DETAIL';
export const FETCH_FOODS_DETAIL_START = 'FETCH_FOODS_DETAIL_START';
export const FETCH_FOODS_DETAIL_SUCCESS = 'FETCH_FOODS_DETAIL_SUCCESS';
export const FETCH_FOODS_DETAIL_FAIL = 'FETCH_FOODS_DETAIL_FAIL';

export const CREATE_FOODS = 'CREATE_FOODS';
export const CREATE_FOODS_START = 'CREATE_FOODS_START';
export const CREATE_FOODS_SUCCESS = 'CREATE_FOODS_SUCCESS';
export const CREATE_FOODS_FAIL = 'CREATE_FOODS_FAIL';

export const RESET_FOODS = 'RESET_FOODS';
export const FOODS_DISMISS_ERROR = 'FOODS_DISMISS_ERROR';

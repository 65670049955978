import * as articleCategoryTypes from './article-category.types';

export const articleCategoryDismissError = () => {
  return {
    type: articleCategoryTypes.ARTICLE_CATEGORY_DISMISS_ERROR
  };
};

export const getArticleCategory = id => {
  return {
    type: articleCategoryTypes.GET_ARTICLE_CATEGORY,
    id: id
  };
};

export const getArticleCategorySuccess = articleCategory => {
  return {
    type: articleCategoryTypes.GET_ARTICLE_CATEGORY_SUCCESS,
    articleCategory: articleCategory
  };
};

export const getArticleCategoryFail = error => {
  return {
    type: articleCategoryTypes.GET_ARTICLE_CATEGORY_FAIL,
    error: error
  };
};

export const getArticleCategoryStart = () => {
  return {
    type: articleCategoryTypes.GET_ARTICLE_CATEGORY_START
  };
};

export const fetchArticleCategorySuccess = articleCategories => {
  return {
    type: articleCategoryTypes.FETCH_ARTICLE_CATEGORY_SUCCESS,
    articleCategories: articleCategories
  };
};

export const fetchArticleCategoryFail = error => {
  return {
    type: articleCategoryTypes.FETCH_ARTICLE_CATEGORY_FAIL,
    error: error
  };
};

export const fetchArticleCategoryStart = () => {
  return {
    type: articleCategoryTypes.FETCH_ARTICLE_CATEGORY_START
  };
};

export const fetchArticleCategory = params => {
  return {
    type: articleCategoryTypes.FETCH_ARTICLE_CATEGORY,
    params: params
  };
};

import * as userTypes from './user.types';

/**
 * GET USER
 */
export const getUser = id => ({
  type: userTypes.GET_USER,
  id
});
export const getUserStart = () => ({
  type: userTypes.GET_USER_START
});
export const getUserSuccess = user => ({
  type: userTypes.GET_USER_SUCCESS,
  user
});
export const getUserFail = error => ({
  type: userTypes.GET_USER_FAIL,
  error
});

/**
 * UPDATE USER
 */
export const updateUser = (history, imgUpload, userData) => {
  return {
    type: userTypes.UPDATE_USER,
    history,
    imgUpload,
    userData
  };
};
export const updateUserStart = () => ({
  type: userTypes.UPDATE_USER_START
});
export const updateUserSuccess = user => ({
  type: userTypes.UPDATE_USER_SUCCESS,
  user
});
export const updateUserFail = error => ({
  type: userTypes.UPDATE_USER_FAIL,
  error
});

/**
 * UPLOAD USER PICTURE
 */
export const upload = () => ({
  type: userTypes.UPLOAD_USER_PICTURE
});
export const uploadStart = () => ({
  type: userTypes.UPLOAD_USER_PICTURE_START
});
export const uploadSuccess = url => ({
  type: userTypes.UPLOAD_USER_PICTURE_SUCCESS,
  url
});
export const uploadFail = () => ({
  type: userTypes.UPLOAD_USER_PICTURE_FAIL
});

/**
 * UTILITY
 */
export const userDismissError = () => {
  return {
    type: userTypes.USER_DISMISS_ERROR
  };
};

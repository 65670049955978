import { put, apply } from 'redux-saga/effects';
import * as greetingAction from '../../reducers/greeting/greeting.actions';

import GreetingServiceClass from '../../../services/Greeting';

export function* greetingContents() {
  yield put(greetingAction.getGreetingContents().start());
  const GreetingService = yield new GreetingServiceClass();
  const res = yield apply(GreetingService, GreetingService.fetchAll, []);
  if (res.data) {
    yield put(greetingAction.getGreetingContents().success(res.data));
  } else {
    yield put(greetingAction.getGreetingContents().fail(res));
  }
}

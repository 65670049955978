import Service from './Service';

export default class PinnedContentService extends Service {
  constructor() {
    super('pinned-content', true);
  }

  async fetch(params) {
    const response = await super.find(params);
    return response;
  }
}

export const FETCH_FOODS_RECIPE = 'FETCH_FOODS_RECIPE';
export const FETCH_FOODS_RECIPE_START = 'FETCH_FOODS_RECIPE_START';
export const FETCH_FOODS_RECIPE_SUCCESS = 'FETCH_FOODS_RECIPE_SUCCESS';
export const FETCH_FOODS_RECIPE_CONCAT_SUCCESS =
  'FETCH_FOODS_RECIPE_CONCAT_SUCCESS';
export const FETCH_FOODS_RECIPE_FAIL = 'FETCH_FOODS_RECIPE_FAIL';

export const FETCH_FOODS_RECIPE_DETAIL = 'FETCH_FOODS_RECIPE_DETAIL';
export const FETCH_FOODS_RECIPE_DETAIL_START =
  'FETCH_FOODS_RECIPE_DETAIL_START';
export const FETCH_FOODS_RECIPE_DETAIL_SUCCESS =
  'FETCH_FOODS_RECIPE_DETAIL_SUCCESS';
export const FETCH_FOODS_RECIPE_DETAIL_FAIL = 'FETCH_FOODS_RECIPE_DETAIL_FAIL';

export const CREATE_FOODS_RECIPE = 'CREATE_FOODS_RECIPE';
export const CREATE_FOODS_RECIPE_START = 'CREATE_FOODS_RECIPE_START';
export const CREATE_FOODS_RECIPE_SUCCESS = 'CREATE_FOODS_RECIPE_SUCCESS';
export const CREATE_FOODS_RECIPE_FAIL = 'CREATE_FOODS_RECIPE_FAIL';

export const RESET_FOODS_RECIPE = 'RESET_FOODS_RECIPE';
export const FOODS_RECIPE_DISMISS_ERROR = 'FOODS_RECIPE_DISMISS_ERROR';

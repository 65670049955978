import Service from './Service';

export default class Article extends Service {
  constructor() {
    super('articles', false);
  }

  async get(id, params) {
    const response = await super.get(id, params);
    return super.generateDefaultResponse(response);
  }

  async viewArticle(id, userId) {
    this.url = `${process.env.REACT_APP_HOST}/articles/increment-total-view/${id}?userId=${userId}`;
    const response = await super.patch(null, true, null, false);
    return response;
  }
}

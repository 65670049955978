import { takeEvery } from 'redux-saga/effects';
import {
  fetchByFilterMedicalReminder,
  confirmMedicalReminder,
  fetchChartDetailMedicalReminder
} from './medical-reminder';
import * as types from '../../reducers/medical-reminder/medical-reminder.types';

export const medicalReminderSagas = {
  fetchByFilterMedicalReminder: takeEvery(
    types.FETCH_BY_FILTER_MEDICAL_REMINDER,
    fetchByFilterMedicalReminder
  ),
  confirmMedicalReminder: takeEvery(
    types.CONFIRM_MEDICAL_REMINDER,
    confirmMedicalReminder
  ),
  fetchChartDetailMedicalReminder: takeEvery(
    types.FETCH_CHART_DETAIL_MEDICAL_REMINDER,
    fetchChartDetailMedicalReminder
  )
};

import * as userTypes from './user.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  user: {},
  url: '',
  loading: false,
  message: null
};

/**
 * GET USER
 */
const getUserStart = (state, action) => updateObject(state, { loading: true });
const getUserSuccess = (state, action) =>
  updateObject(state, { loading: false, error: null, ...action.user });
const getUserFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPDATE USER
 */
const updateUserStart = (state, action) =>
  updateObject(state, { loading: true });
const updateUserSuccess = (state, action) =>
  updateObject(state, {
    loading: false,
    error: null,
    ...action.user,
    message: 'Data user berhasil diupdate'
  });
const updateUserFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPLOAD USER IMAGE
 */
const uploadStart = (state, action) => updateObject(state, { loading: true });
const uploadSuccess = (state, action) =>
  updateObject(state, { url: action.url, loading: false, error: null });
const uploadFail = (state, action) =>
  updateObject(state, { error: action.error, loading: false });

/**
 * UTILITY
 */
const userDismissError = state => {
  return updateObject(state, {
    error: null,
    message: null
  });
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.GET_USER_START:
      return getUserStart(state, action);
    case userTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case userTypes.GET_USER_FAIL:
      return getUserFail(state, action);

    case userTypes.UPDATE_USER_START:
      return updateUserStart(state, action);
    case userTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case userTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);

    case userTypes.UPLOAD_USER_PICTURE_START:
      return uploadStart(state, action);
    case userTypes.UPLOAD_USER_PICTURE_SUCCESS:
      return uploadSuccess(state, action);
    case userTypes.UPLOAD_USER_PICTURE_FAIL:
      return uploadFail(state, action);

    case userTypes.USER_DISMISS_ERROR:
      return userDismissError(state);

    default:
      return state;
  }
};

export default userReducer;
